import { useEffect, useState } from "react";
import useAuth from "../../../hooks/context/useAuth";
import { getUserServiceFavoriteList } from "../../../services/userServices";
import { ProductList } from "../../../components/AtomicDesign/Organisms";
import { Box, Pagination } from "@mui/material";
import { ContainerWithTopBorder } from "../../../components/AtomicDesign/Atoms";

export function FavoritesPage() {
  const { user } = useAuth();
  const [favoritesList, setFavoritesList] = useState(
    [] as Omit<ProductData, "Manufacturers" | "Versions" | "Origin">[]
  );
  const [records, setRecords] = useState(0);
  const [filter, setFilter] = useState({ itensPerPage: 50, offset: 0 });
  const [page, setPage] = useState(1);

  const getFavs = async () => {
    try {
      const response = await getUserServiceFavoriteList({
        userID: user?.user?.id,
        ...filter,
      });
      setFavoritesList(response.data);
      setRecords(Math.ceil(response.count / filter.itensPerPage));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (user?.user?.id) {
      getFavs();
    }
    // eslint-disable-next-line
  }, [user?.user?.id]);

  return (
    <ContainerWithTopBorder title="My favorites">
      <ProductList list={favoritesList} />

      <Box display={"flex"} justifyContent={"center"} width={"100%"} mt={2}>
        <Pagination
          count={records}
          page={page}
          defaultPage={1}
          onChange={(_e, page) => {
            setFilter((old) => ({ ...old, offset: page - 1 }));
            setPage(page);
          }}
        />
      </Box>
    </ContainerWithTopBorder>
  );
}
