import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ContactInfo } from "../../AtomicDesign/Molecules";
import { grey } from "@mui/material/colors";
import useAuth from "../../../hooks/context/useAuth";
import { useEffect } from "react";
import { HeaderAdmin } from "../HeaderAdmin";
import { GlobalContainer } from "../../AtomicDesign/Atoms";

export function AdminLayout() {
  const { getUserLocalStorage } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const data = getUserLocalStorage();
    if (data?.user && data?.token) {
      if (data?.user?.user?.role && data?.user?.user?.role === "admin") {
        const getLocalTab = localStorage.getItem("@treasure_box_admintab");
        if (getLocalTab) {
          const rout = {
            0: "/admin/users",
            1: "/admin/products",
            2: "/admin/orders",
            3: "/admin/deliveries",
            4: "/admin/messages",
            5: "/admin/config",
          };
          const routSelect = Number(JSON.parse(getLocalTab));
          navigate(rout[routSelect as keyof typeof rout]);
        }
      } else {
        navigate("/user/my-info");
      }
    } else {
      navigate("/user/login");
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Box
      minWidth={"100%"}
      minHeight={"100vh"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Box sx={{ width: "100%" }}>
        <HeaderAdmin />
      </Box>

      <Box sx={{ background: grey[900], width: "100%" }}>
        <GlobalContainer sx={{ mt: 2, mb: 2 }}>
          <ContactInfo />
          <Typography textAlign={"center"} mt={10} color={"#FFF"}>
            {String(new Date().getFullYear())} © Treasure Box
          </Typography>
        </GlobalContainer>
      </Box>
    </Box>
  );
}
