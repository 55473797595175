import { Box, Button } from "@mui/material";
import { SimpleTextBox } from "../../../../../components/AtomicDesign/Atoms";

import { grey } from "@mui/material/colors";
import { GenericInput } from "../../../../../components/AtomicDesign/Molecules";
import { useEffect, useState } from "react";
import useToast from "../../../../../hooks/context/useToast";

interface Props {
  prd: PersonalStorageToShip;
  index: number;
  onClick?: (item: PersonalStorageToShip, remove?: boolean) => void;
  hasClick?: boolean;
  hasHighlight?: boolean;
  isSelected?: boolean;
  inputDisabled?: boolean;
}

export function ProductCard({
  prd,
  index,
  onClick,
  inputDisabled = false,
  hasHighlight = false,
  isSelected,
}: Props) {
  const { setToastData, setOpen } = useToast();
  const [edited, setEdited] = useState({} as PersonalStorageToShip);

  useEffect(() => {
    setEdited(prd);
    // eslint-disable-next-line
  }, [prd]);

  const addToShip = () => {
    if (edited?.quantityToShip > 0) {
      onClick(edited);
    } else {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message:
          "You can't add 0 quantity to shipment, please select a quantity",
      });
      setOpen(true);
    }
  };
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"space-between"}
      width={150}
      height={400}
      overflow={"hidden"}
      key={index}
      sx={{
        border: hasHighlight && isSelected ? "1px solid green" : "none",
        borderRadius: 2,

        display: prd?.isPreOrder && !prd?.preOrderPaid ? "none" : "inherit",
      }}
    >
      <img
        src={prd?.Products?.productsImages[0]?.code}
        style={{
          objectFit: "contain",
          width: 150,
          height: 150,
          borderRadius: 5,
          background: grey[200],
        }}
        alt={prd?.Products?.characterName}
      />
      <Box p={1} overflow={"hidden"}>
        <SimpleTextBox
          title=""
          description={`x${prd?.quantity} - ${prd?.Products?.characterName}`}
          height={125}
        />
        <GenericInput
          hasIcon={false}
          onChange={(value, name) => {
            const regexNumber = new RegExp("^[0-9]*$");
            if (value.match(regexNumber) && Number(value) <= prd.quantity) {
              setEdited({ ...prd, quantityToShip: Number(value) });
            }
          }}
          value={edited.quantityToShip}
          defaultValue={edited.quantityToShip}
          placeholder={"Quantity"}
          inputName="quantity"
          disabled={inputDisabled}
        />
        {!inputDisabled && (
          <>
            <Button onClick={() => addToShip()}>Add</Button>
            {edited?.quantityToShip > 0 && (
              <Button
                onClick={() => {
                  onClick({ ...prd, quantityToShip: 0 }, true);
                }}
              >
                Del
              </Button>
            )}
          </>
        )}
        {/* <SimpleTextBox
          title={prd?.isPreOrder ? "Pre-order price" : "Bought price"}
          description={JPYmoney(prd?.boughtPrice)}
          titleColor="black"
        />

        {prd?.isPreOrder && (
          <SimpleTextBox
            title="Release date"
            description={formatedDate(prd?.preOrderDeadline)}
            titleColor="black"
          />
        )} */}
      </Box>
    </Box>
  );
}
