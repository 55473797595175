import { Box, Pagination, Typography } from "@mui/material";
import useOrders from "../../../../hooks/context/useOrders";
import {
  NoData,
  SimpleTextBox,
} from "../../../../components/AtomicDesign/Atoms";
import { amber, blue, grey, teal } from "@mui/material/colors";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { differenceInDays } from "date-fns";
import { useDateTime } from "../../../../hooks/useDateTime";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { useEffect, useState } from "react";
import useAuth from "../../../../hooks/context/useAuth";
import { PaymentModalPreOrder } from "../../../../components/AtomicDesign/Organisms";
import { updatePaymentPreOrder } from "../../../../services/ordersService";
import useToast from "../../../../hooks/context/useToast";

export function PersonalStorage() {
  const {
    personalStorageDataMemo,
    personalStorageRecords,
    setFilters,
    getPersonalStorage,
  } = useOrders();
  const { setToastData, setOpen } = useToast();
  const { user } = useAuth();
  const { JPYmoney } = useJPYmoney();
  const { formatedDate } = useDateTime();
  const { isSmalldesktop } = useBreakpoints();
  const [page, setPage] = useState(1);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [preOrderToPay, setPreOrderToPay] = useState({
    orderID: 0,
    productID: 0,
  });

  useEffect(() => {
    if (user?.personalStorageID) {
      getPersonalStorage();
    }
    // eslint-disable-next-line
  }, [page, user]);

  const VerifyPay = async (prd: PersonalStorage) => {
    try {
      const response = await updatePaymentPreOrder({
        orderID: prd.myOrderID,
        personalStPrd: prd.id,
        productsId: prd.productsId,
        sessionid: prd.chargeID,
      });
      if (response.payStatus === "paid") {
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "success",
          message: "Your pre-order is paid",
        });
        setOpen(true);
      }
      if (response.payStatus === "unpaid") {
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "error",
          message: "Your pre-order was not paid",
        });
        setOpen(true);
      }
      getPersonalStorage();
    } catch {}
  };

  // 24 dias nosso 7 deles 7,68 ao dia
  // 184,32 nosso

  return (
    <Box
      display="flex"
      justifyContent={isSmalldesktop ? "center" : "flex-start"}
      flexWrap={"wrap"}
      alignItems={"center"}
      width={"100%"}
      mt={2}
      gap={1}
      p={1}
    >
      {personalStorageDataMemo.length > 0 ? (
        personalStorageDataMemo?.map((prd, index) => (
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={150}
            height={400}
            overflow={"hidden"}
            key={index}
            sx={{
              border:
                prd?.isPreOrder &&
                differenceInDays(new Date(prd?.preOrderDeadline), new Date()) <=
                  0 &&
                !prd?.preOrderPaid
                  ? `1px solid ${amber[700]}`
                  : "1px solid lightgrey",
              borderRadius: 5,

              cursor:
                prd?.isPreOrder &&
                differenceInDays(new Date(prd?.preOrderDeadline), new Date()) <=
                  0 &&
                !prd?.preOrderPaid
                  ? "pointer"
                  : "default",
            }}
            onClick={() => {
              if (
                prd?.isPreOrder &&
                differenceInDays(new Date(prd?.preOrderDeadline), new Date()) <=
                  0 &&
                !prd?.preOrderPaid &&
                prd.chargeID
              ) {
                VerifyPay(prd);
              } else if (
                prd?.isPreOrder &&
                differenceInDays(new Date(prd?.preOrderDeadline), new Date()) <=
                  0 &&
                !prd?.preOrderPaid
              ) {
                setPreOrderToPay({
                  orderID: prd.myOrderID,
                  productID: prd.productsId,
                });
                setOpenPaymentModal(true);
              }
            }}
          >
            <>
              <img
                src={prd?.Products?.productsImages[0]?.code}
                style={{
                  objectFit: "contain",
                  width: 150,
                  height: 150,
                  borderRadius: 5,
                  background: grey[200],
                }}
                alt={prd?.Products?.characterName}
              />
              {!prd?.productPaid && !prd?.isPreOrder && (
                <Typography
                  style={{
                    position: "relative",
                    background: grey[500],
                    width: 150,
                    marginTop: -24,
                    borderRadius: "0 0 5px 5px",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  Unpaid
                </Typography>
              )}
              {prd?.Products?.isPreOrder && (
                <Typography
                  style={{
                    position: "relative",
                    background: "#d32f2f",
                    width: 150,
                    marginTop: -24,
                    borderRadius: "0 0 5px 5px",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  Pre-Order
                </Typography>
              )}
              {prd?.isPreOrder &&
                differenceInDays(new Date(prd?.preOrderDeadline), new Date()) <=
                  0 &&
                !prd?.preOrderPaid &&
                !prd.chargeID && (
                  <Typography
                    style={{
                      position: "relative",
                      background: amber[500],
                      width: 150,
                      marginTop: isSmalldesktop ? -78 : -60,

                      borderRadius: "0 0 5px 5px",
                      color: "#FFFFFF",
                      textAlign: "center",
                    }}
                  >
                    Click to pay
                  </Typography>
                )}

              {prd?.isPreOrder &&
                differenceInDays(new Date(prd?.preOrderDeadline), new Date()) <=
                  0 &&
                !prd?.preOrderPaid &&
                prd.chargeID && (
                  <Typography
                    style={{
                      position: "relative",
                      background: blue[500],
                      width: 150,
                      marginTop: isSmalldesktop ? -78 : -60,

                      borderRadius: "0 0 5px 5px",
                      color: "#FFFFFF",
                      textAlign: "center",
                    }}
                  >
                    Verify payment
                  </Typography>
                )}

              {prd?.isPreOrder &&
                differenceInDays(new Date(prd?.preOrderDeadline), new Date()) <=
                  0 &&
                prd?.preOrderPaid && (
                  <Typography
                    style={{
                      position: "relative",
                      background: teal[500],
                      width: 150,
                      marginTop: isSmalldesktop ? -78 : -60,

                      borderRadius: "0 0 5px 5px",
                      color: "#FFFFFF",
                      textAlign: "center",
                    }}
                  >
                    Paid
                  </Typography>
                )}
            </>
            <Box p={1}>
              <SimpleTextBox
                title=""
                description={`x${prd?.quantity} - ${prd?.Products?.characterName}`}
                height={prd?.isPreOrder ? 110 : 175}
              />
              <SimpleTextBox
                title={prd?.isPreOrder ? "Pre-order price" : "Bought price"}
                description={JPYmoney(prd?.boughtPrice)}
                titleColor="black"
              />

              {prd?.isPreOrder && (
                <SimpleTextBox
                  title="Release date"
                  description={formatedDate(prd?.preOrderDeadline)}
                  titleColor="black"
                />
              )}
            </Box>
          </Box>
        ))
      ) : (
        <NoData text="No products found" />
      )}

      <Box display={"flex"} justifyContent={"center"} width={"100%"} mt={2}>
        <Pagination
          count={personalStorageRecords}
          page={page}
          defaultPage={1}
          onChange={(_e, page) => {
            setFilters((old) => ({ ...old, offset: page - 1 }));
            setPage(page);
          }}
        />
      </Box>

      <PaymentModalPreOrder
        orderID={preOrderToPay.orderID}
        productID={preOrderToPay.productID}
        open={openPaymentModal}
        setOpen={setOpenPaymentModal}
      />
    </Box>
  );
}
