import { Box, Typography } from "@mui/material";

// import NEWTREASUREBOXLOGO from "../../../../../assets/images/NEWTREASUREBOXLOGO2.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect } from "react";
import { useTranslate } from "../../../../../hooks/useTranslate";
import useAuth from "../../../../../hooks/context/useAuth";
import { BoxContent } from "../../../Atoms";
import { CustomButton, EmailField, PasswordField } from "../../../Molecules";
import useToast from "../../../../../hooks/context/useToast";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";
import { grey } from "@mui/material/colors";

export function LoginBox() {
  const { strings } = useTranslate();
  const { authenticate, language } = useAuth();
  const { setOpen, setToastData } = useToast();
  const { forgotPassword } = strings.login;
  const { isMobile } = useBreakpoints();
  const navigate = useNavigate();

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(strings.validationFields.email[language])
      .required(strings.validationFields.email.required[language]),
    password: yup
      .string()
      .required(strings.validationFields.password[language]),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    async onSubmit(values: ILoginFormValues) {
      try {
        await authenticate(values.email, values.password);
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "success",
          message: strings.toastMessages.welcome[language],
        });
        navigate("/home");
        setOpen(true);
      } catch (e: any) {
        const message =
          e?.message === "blocked"
            ? strings.toastMessages.userBlocked[language]
            : strings.toastMessages.loginError[language];
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 10000,
          type: "error",
          message,
        });
        setOpen(true);
      }
    },
  });

  const handleLogin = () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    const getPasswordField = document.getElementById(
      "standard-adornment-password"
    );

    getPasswordField?.addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        document?.getElementById("login-btn")?.click();
      }
    });
  }, []);

  return (
    <BoxContent
      config={{
        p: 8,

        borderRadius: isMobile ? "8px 8px 0px 0px" : "8px 0px 0px 8px",
      }}
    >
      {/* <img
        style={{ marginTop: -30, marginBottom: -5 }}
        src={NEWTREASUREBOXLOGO}
        width={150}
        alt="treasurebox logo"
      /> */}

      <Typography color={grey[600]} variant="h1">
        Log in
      </Typography>

      <EmailField
        onChange={(val) => formik.setFieldValue("email", val.trim())}
        value={formik.values.email}
        error={formik.touched.email && formik.errors.email ? true : false}
        errorMessage={formik.touched.email ? formik.errors.email : ""}
        placeholder="E-mail"
      />
      <PasswordField
        onChange={(val) => formik.setFieldValue("password", val.trim())}
        value={formik.values.password}
        error={formik.touched.password && formik.errors.password ? true : false}
        errorMessage={formik.touched.password ? formik.errors.password : ""}
        placeholder="Password"
      />
      <Box mt={3} width={"100%"}>
        <CustomButton
          btnType="login"
          id="login-btn"
          onClick={handleLogin}
          text={strings.buttons.signInbtn[language]}
        />

        <CustomButton
          variant="text"
          id="login-btn"
          onClick={() => navigate("/user/forgot-password")}
          text={forgotPassword[language]}
          sx={{ mt: 2 }}
        />
      </Box>
    </BoxContent>
  );
}
