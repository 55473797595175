import { Box, Chip } from "@mui/material";
import { ContainerWithTopBorder } from "../../Atoms";
import { Link } from "react-router-dom";

interface Props {
  list: ProductsType[];
  title: string;
  linkTo: string;
}

export function PopularCategories({ list, title, linkTo }: Props) {
  return (
    <ContainerWithTopBorder title={`Popular ${title}`}>
      <Box
        display={"flex"}
        gap={1}
        flexWrap={"wrap"}
        alignItems={"flex-start"}
        maxWidth={250}
        overflow={"hidden"}
      >
        {list?.map((item, index) => (
          <Box height={"fit-content"} key={index}>
            <Link to={`/products?${linkTo}=${item?.name}&tab=1`}>
              <Chip
                label={item?.name}
                sx={{ "&: hover": { background: "lightgray" } }}
              />
            </Link>
          </Box>
        ))}
      </Box>
    </ContainerWithTopBorder>
  );
}
