import { Box, Button, FormHelperText, Typography } from "@mui/material";
import { colors } from "../../../../theme/colors";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { FadeInContainer } from "../../Atoms";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { amber, teal } from "@mui/material/colors";

interface Props {
  totalItens: number;
  fullPrice: number;
  preOrderPrice: number;
  nextStep: VoidFunction;
  backStep?: VoidFunction;
  btnText: string;
}

export function DrawerCheckout({
  totalItens,
  fullPrice,
  preOrderPrice,
  nextStep,
  backStep,
  btnText,
}: Props) {
  const { JPYmoney } = useJPYmoney();
  const { isMobile } = useBreakpoints();

  return (
    totalItens > 0 && (
      <FadeInContainer>
        <Box
          width={"100%"}
          sx={{
            border: `1px solid ${colors.blue[400]}`,
            borderRadius: "15px",
            mt: 1,
          }}
        >
          <Box
            p={isMobile ? 1 : 3}
            gap={1}
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-evenly",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "row" : "column",
                alignItems: "center",
                justifyContent: "center",
                gap: isMobile ? 1 : 0,
              }}
            >
              <Typography>Total itens:</Typography>
              <Typography color={amber[700]} fontSize={20}>
                {totalItens}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "row" : "column",
                alignItems: "center",
                justifyContent: "center",
                gap: isMobile ? 1 : 0,
              }}
            >
              <Typography>Total price:</Typography>
              <Typography color={amber[700]} fontSize={20}>
                {JPYmoney(fullPrice)}
              </Typography>
            </Box>

            {preOrderPrice > 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "row" : "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: isMobile ? 1 : 0,
                }}
              >
                <Typography>Total pre-order price:</Typography>
                <Typography color={amber[700]} fontSize={20}>
                  {JPYmoney(preOrderPrice)}
                </Typography>
              </Box>
            )}

            <Box
              display={"flex"}
              flexWrap={"wrap"}
              alignItems={"center"}
              gap={1}
            >
              <Button
                onClick={backStep}
                variant="contained"
                fullWidth={isMobile ? true : false}
                style={{ fontSize: isMobile ? 12 : 14 }}
              >
                <ArrowBackIcon sx={{ mr: 1 }} /> Back to cart
              </Button>
              <Button
                style={{ fontSize: isMobile ? 12 : 14 }}
                variant="contained"
                fullWidth={isMobile ? true : false}
                onClick={nextStep}
                sx={{
                  background: teal[500],
                  "&:hover": { background: teal[200] },
                }}
              >
                {btnText}
              </Button>
            </Box>
          </Box>
          {preOrderPrice > 0 && (
            <FormHelperText
              sx={{
                bgcolor: amber[100],
                borderRadius: "0 0 12px 12px",
                p: 1,
                textAlign: "center",
              }}
            >
              <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
              Pre-orders only will charge when the product release, we will
              generate your order and pre-order will stay in stand-by, waiting
              release.
            </FormHelperText>
          )}
        </Box>
      </FadeInContainer>
    )
  );
}
