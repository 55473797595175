import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { CustomModal } from "../Modal";

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";
import { chargePreOrder } from "../../../../services/ordersService";

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  orderID: number;
  productID: number;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export function PaymentModalPreOrder({
  open,
  setOpen,
  orderID,
  productID,
}: Props) {
  const { isSmalldesktop } = useBreakpoints();

  const fetchClientSecret = async () => {
    // await createCreditCardCharge(payload);
    const options = await chargePreOrder({ orderID, productID });

    return options;
  };

  return (
    <CustomModal
      openModal={open}
      setOpenModal={() => {
        setOpen(false);
      }}
      width={isSmalldesktop ? "90%" : "60%"}
      disableBackdropClick
      title={"Please, insert your credit card data"}
    >
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{
          fetchClientSecret,
        }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </CustomModal>
  );
}
