import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { SimpleTextBox } from "../../Atoms";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";

export function ProductComponentCancel({
  ordersXproducts,
  onClick,
  selectedPRDS,
}: {
  ordersXproducts: OrdersXproducts[];
  onClick: (prd: OrdersXproducts[]) => void;
  selectedPRDS: OrdersXproducts[];
}) {
  const { JPYmoney } = useJPYmoney();

  const verifyAlreadySelected = (prd: OrdersXproducts) => {
    const find = selectedPRDS.find(
      (item) => item.productsId === prd?.productsId
    );

    if (find) {
      const filter = selectedPRDS.filter(
        (item) => item.productsId !== prd.productsId
      );
      onClick(filter);
    } else {
      onClick([...selectedPRDS, prd]);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        minWidth: "100%",
        justifyContent: "center",
        overflowX: "auto",
        gap: 1,
      }}
    >
      {ordersXproducts?.map((item, index) => (
        <Box
          sx={{
            border: selectedPRDS.includes(item) ? "2px solid green" : "none",
            borderRadius: 2,
          }}
          maxWidth={150}
          height={300}
          overflow={"hidden"}
          key={index}
          onClick={() => verifyAlreadySelected(item)}
        >
          <img
            src={item?.Products?.productsImages[0]?.code}
            style={{
              objectFit: "contain",
              width: 150,
              height: 150,
              borderRadius: 5,
              background: grey[200],
            }}
            alt={item?.Products?.characterName}
          />
          {item?.Products?.isPreOrder && (
            <Typography
              style={{
                position: "relative",
                background: "#d32f2f",
                width: 150,
                marginTop: -25,
                borderRadius: "0 0 5px 5px",
                color: "#FFFFFF",
                textAlign: "center",
              }}
            >
              Pre-Order
            </Typography>
          )}
          <SimpleTextBox
            title=""
            description={`x${item?.quantity} - ${item?.Products?.characterName}`}
            height={90}
          />
          <SimpleTextBox
            title="Bought price"
            description={JPYmoney(item?.boughtPrice)}
            titleColor="black"
          />
        </Box>
      ))}
    </Box>
  );
}
