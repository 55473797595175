import { useFormik } from "formik";
import * as yup from "yup";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslate } from "../../../../../hooks/useTranslate";
import useAuth from "../../../../../hooks/context/useAuth";
import CountrySelect from "../../AutocompletePhone";
import {
  CustomButton,
  EmailField,
  GenericInput,
  PasswordField,
} from "../../../Molecules";
import { BoxContent } from "../../../Atoms";
import useToast from "../../../../../hooks/context/useToast";
import { createUserService } from "../../../../../services/createUserService";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";
import { grey } from "@mui/material/colors";

export function NewUserBox() {
  const { strings } = useTranslate();
  const { language } = useAuth();
  const navigate = useNavigate();
  const { setOpen, setToastData } = useToast();
  const { isMobile } = useBreakpoints();

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(strings.validationFields.name.required[language]),
    phoneCode: yup
      .string()
      .required(strings.validationFields.phoneCode.required[language]),
    phone: yup
      .number()
      .typeError(strings.validationFields.phoneCode.typeError[language])
      .required(strings.validationFields.phoneCode.required[language]),

    email: yup
      .string()
      .email(strings.validationFields.email[language])
      .required(strings.validationFields.email.required[language]),
    password: yup
      .string()
      .required(strings.validationFields.password[language]),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phoneCode: "",
      phone: "",
      ssn: "",
      email: "",
      password: "",
    },
    validationSchema,
    async onSubmit(values: any) {
      try {
        const formatPhone = `${values?.phoneCode}${values?.phone}`;
        await createUserService({ ...values, phone: formatPhone });

        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "success",
          message: strings.toastMessages.welcomeNew[language],
        });
        setOpen(true);
        navigate("/login");
      } catch (e) {
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "error",
          message: strings.toastMessages.emailAlreadyExists[language],
        });
        setOpen(true);
      }
    },
  });

  const handleCreateUser = () => {
    formik.handleSubmit();
  };

  return (
    <BoxContent
      config={{
        p: 3,

        borderRadius: isMobile ? "0px 0px 8px 8px" : "0px 8px 8px 0px",
      }}
    >
      {/* <img src={treasureBoxLogo} width={150} alt="treasurebox logo" /> */}
      <Typography variant="h1" color={grey[600]}>
        {strings.login.registerFree[language]}
      </Typography>
      <GenericInput
        hasIcon={false}
        onChange={(value, name) => formik.setFieldValue(name, value)}
        error={formik.touched.name && formik.errors.name ? true : false}
        errorMessage={formik.touched.name ? formik.errors.name : ""}
        value={formik.values.name}
        placeholder="Name"
        inputName="name"
      />

      {/* <BreakpointBox> */}
      <Box display="flex" sx={{ width: "100%" }}>
        <CountrySelect
          selectedCode={(val) => formik.setFieldValue("phoneCode", val)}
        />
        <GenericInput
          hasIcon={false}
          onChange={(value, name) => formik.setFieldValue(name, value)}
          error={
            (formik.touched.phone && formik.errors.phone) ||
            (formik.touched.phoneCode && formik.errors.phoneCode)
              ? true
              : false
          }
          errorMessage={
            formik.touched.phone || formik.touched.phoneCode
              ? formik.errors.phone
                ? formik.errors.phone
                : formik.errors.phoneCode
                ? formik.errors.phoneCode
                : ""
              : ""
          }
          value={formik.values.phone}
          placeholder="Phone"
          inputName="phone"
        />
      </Box>
      {/* <GenericInput
          hasIcon={false}
          onChange={(value, name) => formik.setFieldValue(name, value)}
          error={formik.touched.ssn && formik.errors.ssn ? true : false}
          errorMessage={formik.touched.ssn ? formik.errors.ssn : ""}
          value={formik.values.ssn}
          placeholder="CPF(exclusive to Brazil)"
          inputName="ssn"
        /> */}
      {/* </BreakpointBox> */}

      <EmailField
        hasIcon={false}
        onChange={(value) => formik.setFieldValue("email", value)}
        error={formik.touched.email && formik.errors.email ? true : false}
        errorMessage={
          formik.touched.email ? (formik.errors.email as string) : ""
        }
        value={formik.values.email}
        placeholder="E-mail"
      />
      <PasswordField
        hasIcon={false}
        onChange={(value) => formik.setFieldValue("password", value)}
        error={formik.touched.password && formik.errors.password ? true : false}
        errorMessage={
          formik.touched.password ? (formik.errors.password as string) : ""
        }
        value={formik.values.password}
        placeholder="Password"
      />

      <CustomButton
        btnType="register"
        id="login-btn"
        onClick={handleCreateUser}
        text={strings.buttons.createLogin[language]}
        style={{ marginTop: 12 }}
      />
    </BoxContent>
  );
}
