import { Box, Pagination } from "@mui/material";
import useProducts from "../../../hooks/context/useProducts";

import { useEffect, useState } from "react";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import {
  FadeInContainer,
  NoData,
} from "../../../components/AtomicDesign/Atoms";
import { ProductList } from "../../../components/AtomicDesign/Organisms";

export function Products() {
  const { productsDataMemo, records, setProductsFilters, productsfilters } =
    useProducts();
  const { isSmalldesktop } = useBreakpoints();

  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [
    productsfilters.inStock,
    productsfilters.isPreOwned,
    productsfilters.isSale,
    productsfilters.manufacturerName,
    productsfilters.name,
    productsfilters.originName,
    productsfilters.outOfStock,
    productsfilters.preOrder,
    productsfilters.productTypeName,
    productsfilters.versionName,
  ]);

  return (
    <FadeInContainer>
      <Box
        display={"flex"}
        width={"100%"}
        gap={2}
        mt={2}
        p={isSmalldesktop ? 0 : 1}
        flexWrap={"wrap"}
        justifyContent={isSmalldesktop ? "center" : "flex-start"}
      >
        <ProductList list={productsDataMemo?.data} />

        {productsDataMemo?.data?.length === 0 && <NoData text="No results" />}
        <Box display={"flex"} justifyContent={"center"} width={"100%"} mt={2}>
          <Pagination
            count={records}
            page={page}
            defaultPage={1}
            onChange={(_e, page) => {
              setProductsFilters((old) => ({ ...old, offset: page - 1 }));
              setPage(page);
            }}
          />
        </Box>
      </Box>
    </FadeInContainer>
  );
}
