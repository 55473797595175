import { api } from "./api";

export const getMyOrders = async (data: OrderParams) => {
  const response2 = await api.get("/get-my-orders", { params: { ...data } });
  return response2.data;
};

export const getMyPersonalStorage = async (data: PersonalStorageParams) => {
  const response2 = await api.get("/get-personal-storage", {
    params: { ...data },
  });
  return response2.data;
};

export const updatePaymentPreOrder = async (data: PaymentStatusPreOrder) => {
  const response = await api.post("/verify-payment-preorder", { ...data });
  return response.data;
};

export const chargePreOrder = async (data: ChargePreOrder) => {
  const response = await api.post("/charge-preorder", { ...data });
  return response.data;
};

export const chargeUnpaidSession = async (myOrderID: number) => {
  const response = await api.post("/charge-unpaid-session", { myOrderID });
  return response.data;
};

// admin

export const getMyOrdersAdmin = async (data: orderAdminFilter) => {
  const response2 = await api.get("/get-orders-admin", { params: { ...data } });
  return response2.data;
};

export const cancelOrderService = async (data: CancelPayload) => {
  const response = await api.post("/cancel-order", { ...data });
  return response.data;
};
