import { useEffect, useState } from "react";
import useAuth from "../../../hooks/context/useAuth";
import {
  createServiceMessage,
  getServiceMessagesAdmin,
  markAsRead,
} from "../../../services/userServices";
import { MessageBox } from "../../../components/AtomicDesign/Molecules";
import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { amber, grey, teal } from "@mui/material/colors";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import { NewMessageModal } from "./NewMessageModal";

export function ServiceMessagesAdmin() {
  const { user } = useAuth();
  const { isSmalldesktop } = useBreakpoints();
  const [messageList, setMessageList] = useState<IMessage[]>();
  const [selectedMessage, setSelectedMessage] = useState<IMessage>();
  const [newMessageModal, setNewMessageModal] = useState(false);

  const getMessages = async () => {
    try {
      const response = await getServiceMessagesAdmin();
      setMessageList(response);
    } catch {}
  };

  useEffect(() => {
    if (user) {
      getMessages();
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    let interval = undefined as any;

    interval = setInterval(() => {
      getMessages();
    }, 300000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const find = messageList?.find(
      (message) => message?.usersId === selectedMessage?.usersId
    );
    setSelectedMessage(null);
    setSelectedMessage(find);

    // eslint-disable-next-line
  }, [messageList]);

  const readMessages = async (userID: number) => {
    try {
      await markAsRead({ type: "client", userID });
    } catch {
    } finally {
      getMessages();
    }
  };

  useEffect(() => {
    if (selectedMessage?.type === "client" && !selectedMessage?.read) {
      readMessages(selectedMessage?.usersId);
    }

    // eslint-disable-next-line
  }, [selectedMessage]);

  const sendNewMessage = async (description: string, userID: number) => {
    try {
      if (userID) {
        await createServiceMessage({
          description,
          person: user?.user?.name,
          type: "admin",
          userID,
        });
      }
    } catch {
    } finally {
      getMessages();
    }
  };

  return (
    <Box display={"flex"} width={"100%"} height={430}>
      <Box
        sx={{ border: "1px gray dotted", overflowY: "auto", borderRadius: 2 }}
        width={250}
        height={isSmalldesktop ? 410 : 480}
        mr={1}
      >
        <Button
          fullWidth
          variant={"contained"}
          onClick={() => setNewMessageModal(true)}
        >
          new message
        </Button>
        <List
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {messageList?.map((message) => (
            <>
              <ListItem
                alignItems="flex-start"
                onClick={() => setSelectedMessage(message)}
                sx={{
                  background:
                    selectedMessage?.id === message?.id
                      ? teal["A100"]
                      : "transparent",
                }}
              >
                {message.type === "client" && (
                  <ListItemAvatar>
                    <Avatar alt="avatar" sx={{ background: amber[500] }}>
                      !
                    </Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText
                  primary={
                    <Typography
                      sx={{
                        textOverflow: "ellipsis",
                        height: "20px",
                        overflow: "hidden",
                      }}
                    >
                      {message.Users?.name}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      sx={{
                        textOverflow: "ellipsis",
                        height: "20px",
                        overflow: "hidden",
                        color: grey[500],
                      }}
                    >
                      {message.Users?.serviceMessage[0]?.description}
                    </Typography>
                  }
                />
              </ListItem>
              <Divider variant="middle" />
            </>
          ))}
        </List>
      </Box>
      <MessageBox
        data={selectedMessage?.Users?.serviceMessage}
        sendMessage={(message) =>
          sendNewMessage(message, selectedMessage?.usersId)
        }
      />

      <NewMessageModal
        open={newMessageModal}
        setOpen={setNewMessageModal}
        createNewConversation={(newMessage) =>
          sendNewMessage(newMessage?.description, newMessage?.userID)
        }
      />
    </Box>
  );
}
