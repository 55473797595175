import { Box, Typography } from "@mui/material";
import {
  FadeInContainer,
  SimpleTextBox,
} from "../../../../components/AtomicDesign/Atoms";
import { ProductComponent } from "../../../../components/AtomicDesign/Molecules/OrderAccordion/ProductComponent";
import { grey } from "@mui/material/colors";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { useDateTime } from "../../../../hooks/useDateTime";
import { colors } from "../../../../theme/colors";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function OrderCard({
  order,
  payStatus,
}: {
  order: OrderData;
  payStatus: String;
}) {
  const nav = useNavigate();
  const { JPYmoney } = useJPYmoney();
  const { formatedDate } = useDateTime();
  const [seconds, setSeconds] = useState(20);

  useEffect(() => {
    let interval = undefined;
    if (order) {
      interval = setInterval(
        () =>
          setSeconds((old) => {
            if (seconds <= 0) return 0;
            else return old - 1;
          }),
        1000
      );
      if (seconds === 0) {
        nav("/user/my-info");
        // setSeconds(20);
      }
    } else {
      setSeconds(20);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [order, seconds]);

  return (
    <Box width={"100%"}>
      <FadeInContainer>
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
          width={"100%"}
          sx={{
            border: "1px dotted #b5821b",
            background: "#ffab000f",
            borderRadius: 2,
            mb: 3,
            pt: 1,
            pb: 1,
          }}
        >
          <Typography
            variant="h1"
            textAlign={"center"}
            mb={3}
            color={"#b5821b"}
          >
            Your order was placed, i'll redirect you to your orders page in{" "}
            {seconds} seconds
          </Typography>
          <Typography variant="h1" textAlign={"center"} color={"#b5821b"}>
            Your order payment status is {payStatus}
          </Typography>
        </Box>

        <Box
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            background: colors.blue[900],
            borderRadius: "5px 5px 0 0",
            p: 1,
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: [2, 4],
            }}
          >
            <SimpleTextBox title="Order number" description={order?.id} />
            <SimpleTextBox
              title="Created at"
              description={formatedDate(order?.createdAt)}
            />
            <SimpleTextBox
              title="Total paid"
              description={JPYmoney(
                order?.ordersXproducts?.reduce((acc, next) => {
                  if (!next?.isPreOrder) {
                    return acc + next?.Products?.price * next?.quantity;
                  } else {
                    return acc;
                  }
                }, 0)
              )}
            />
            {order?.ordersXproducts?.some(
              (prd) => prd?.Products?.isPreOrder
            ) && (
              <SimpleTextBox
                title="Pre-order (pay in release)"
                description={JPYmoney(
                  order?.ordersXproducts?.reduce((acc, next) => {
                    if (next?.isPreOrder) {
                      return acc + next?.Products?.price * next?.quantity;
                    } else {
                      return acc;
                    }
                  }, 0)
                )}
              />
            )}
            {order?.ordersXproducts?.some(
              (prd) => prd?.Products?.isPreOrder
            ) && (
              <SimpleTextBox
                title="Total pre-order paid"
                description={JPYmoney(
                  order?.ordersXproducts?.reduce((acc, next) => {
                    if (next?.isPreOrder && next?.preOrderPaid) {
                      return acc + next?.Products?.price * next?.quantity;
                    } else {
                      return acc;
                    }
                  }, 0)
                )}
              />
            )}
            {order?.ordersXproducts?.reduce((acc, next) => {
              if (next?.isPreOrder) {
                return acc;
              } else {
                return acc + next?.Products?.price * next?.quantity;
              }
            }, 0) > 0 &&
              order.paymentStatus !== "paid" && (
                <SimpleTextBox
                  title="Status(not for pre-orders)"
                  description={order?.paymentStatus}
                  descriptionColor="red"
                />
              )}
          </Box>
        </Box>
        <Box sx={{ background: grey[200] }}>
          <ProductComponent
            selectedOrdersXproducts={order?.ordersXproducts}
            changeQuantityToCancel={() => {}}
          />
        </Box>
      </FadeInContainer>
    </Box>
  );
}
