import { Box, Typography } from "@mui/material";
import { colors } from "../../../../theme/colors";
import { CustomButton } from "../../Molecules";

interface Props {
  title: string;
  children: JSX.Element | React.ReactNode | React.ReactNode[];
  width?: string | number;
  onClick?: VoidFunction;
}

export function ContainerWithTopBorder({
  title,
  children,
  onClick,
  width = "100%",
}: Props) {
  return (
    <Box display={"flex"} flexDirection={"column"} width={width}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          background: colors.blue[900],
          p: 3,
          borderRadius: "5px 5px 0 0",
          height: 8,
        }}
      >
        <Box>
          <Typography color="#FFFFFF">{title}</Typography>
        </Box>
        {onClick && (
          <CustomButton
            fullWidth={false}
            btnType="login"
            onClick={() => onClick && onClick()}
          >
            New Product
          </CustomButton>
        )}
      </Box>
      <Box
        display={"flex"}
        gap={2}
        flexWrap={"wrap"}
        p={2}
        sx={{ border: "1px dotted black", borderRadius: "0 0 5px 5px" }}
      >
        {children}
      </Box>
    </Box>
  );
}
