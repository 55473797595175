import { createContext, useContext, useState } from "react";

const GlobalLoadingContext = createContext(
  {} as { setOpenLoading(val: boolean): void; open: boolean }
);

type GlobalLoadingProviderProps = {
  children: React.ReactNode;
};

function GlobalLoadingProvider({ children }: GlobalLoadingProviderProps) {
  const [open, setOpenLoading] = useState(false);

  return (
    <GlobalLoadingContext.Provider value={{ setOpenLoading, open }}>
      {children}
    </GlobalLoadingContext.Provider>
  );
}

export { GlobalLoadingProvider, GlobalLoadingContext };

export default function useGlobalLoading() {
  return useContext(GlobalLoadingContext);
}
