import { Box, Container } from "@mui/material";

import meninaEmPe from "../../../assets/images/menina_em_pe.png";
import {
  FadeInContainer,
  TitleDivider,
} from "../../../components/AtomicDesign/Atoms";
import { CustomAccordion } from "../../../components/AtomicDesign/Molecules";

export function FAQ() {
  const questions = [
    {
      title: "How to buy with Treasure Box Japan?",
      description:
        'First you need to make you account, before that, choose you product in our web site, select itens you want and go to checkout to pay, after all your products will go to your "personal storage"',
    },
    {
      title: "What is 'Personal Storage?'",
      description:
        "'Personal Storage' is your space in our warehouse, all products you buy will be alocated to your space and you can select them to ship whatever you want!",
    },
    {
      title: "How to ship?",
      description:
        "After you buy products, all go to 'Personal Storage', so you need to go in your Shipments menu in your info page to create a new shipment, you can select all of your products to ship in Japan! in any combination!",
    },
    {
      title: "After i buy, what happen if i dont wnat to ship now?",
      description:
        "We will store all of yout goods, don't worry you can stock the products in our warehouse to ship in future! Need to wait pre-orders to release to send with your products? no problem! You can do that!",
    },
    {
      title: "You are from Japan?",
      description:
        "Yes, we live in Japan and because of this, we have access to any existing store in Japan, don't found the product you what? Contact us!",
    },
    {
      title: "Do you have a warehouse to store products safely?",
      description:
        "Yes, we have a 3-storey warehouse where all your products will be stored in a safe and organized manner, we are not a small place, we have all the logistics to serve you in the best way!",
    },
    {
      title: "How does product organization work?",
      description:
        'Each customer when registering will have a "Personal storage", the "Personal storage" is their place in our warehouse, all their products are stored in their exclusive space within our warehouse, the "Personal storage" is unique and non-transferable.',
    },
    {
      title: "What payment methods are accepted?",
      description:
        "We accept payment by credit card and other methods available in Japan Easy-peasy to pay.",
    },
    // {
    //   title: "What shipping methods do you use?",
    //   description:
    //     'We use the 4 main methods, which are: "Small packet", "EMS", "Surface", and Air Parcel.',
    // },
  ];

  return (
    <Container maxWidth="lg" sx={{ mb: 5 }}>
      <FadeInContainer>
        <TitleDivider title="Top questions" />
        <Box
          width="100%"
          display={"flex"}
          justifyContent={"center"}
          mb={2}
          mt={-2}
        >
          <img
            src={meninaEmPe}
            alt="pirataphoto"
            width={"100%"}
            style={{ maxWidth: 500 }}
          />
        </Box>

        <Box width="100%" display={"flex"} justifyContent={"center"}>
          <Box width={"80%"}>
            <CustomAccordion list={questions} />
          </Box>
        </Box>
      </FadeInContainer>
    </Container>
  );
}
