import { Box, Button, TextField, Typography, colors } from "@mui/material";
import { FadeInContainer } from "../../Atoms";
import { amber, blue } from "@mui/material/colors";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoneIcon from "@mui/icons-material/Done";
import SendIcon from "@mui/icons-material/Send";
import { useDateTime } from "../../../../hooks/useDateTime";
import { useState } from "react";

interface Props {
  data: IMessage[] | undefined;
  sendNewMessage(val: string): void;
}

export function MessageContainer({ sendNewMessage, data }: Props) {
  const { formatedDate } = useDateTime();
  const [newMessage, setMessage] = useState("");

  return (
    <>
      <Box
        height={"100%"}
        sx={{
          maxHeight: "400px",
          overflowY: "auto",
          "&::-webkit-scrollbar": { width: 10 },
          "&::-webkit-scrollbar-track": {
            backgroundColor: colors.blue[900],
            borderRadius: 10,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: colors.blue[400],
            borderRadius: 5,
          },
        }}
        id="roll-Bar"
      >
        {data &&
          data.length > 0 &&
          data
            .map((message, index) => (
              <FadeInContainer key={index}>
                <Box
                  maxWidth={"95%"}
                  display={"flex"}
                  justifyContent={
                    message?.type === "admin" ? "flex-end" : "flex-start"
                  }
                  padding={1}
                  borderRadius={5}
                >
                  <Box
                    sx={{
                      bgcolor:
                        message?.type === "admin" ? amber[100] : blue[100],
                      p: 1,
                      borderRadius: 2,
                      maxWidth: "80%",
                    }}
                  >
                    <Typography variant="h1">
                      {message?.person} - {formatedDate(message?.created)}
                    </Typography>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"flex-end"}
                    >
                      <Typography
                        ml={1}
                        variant="h2"
                        maxWidth={"94%"}
                        sx={{ wordBreak: "break-word" }}
                      >
                        {message?.description}
                      </Typography>
                      {message?.read ? (
                        <DoneAllIcon sx={{ fill: "green" }} />
                      ) : (
                        <DoneIcon />
                      )}
                    </Box>
                  </Box>
                </Box>
              </FadeInContainer>
            ))
            .reverse()}
      </Box>
      <Box display={"flex"} width={"95%"} p={1}>
        <TextField
          id="text-message"
          variant="outlined"
          onChange={(e) => setMessage(e.target.value)}
          value={newMessage}
          fullWidth
        />
        <Button
          id="messageSubmit"
          onClick={() => {
            sendNewMessage(newMessage);
            setMessage("");
          }}
          sx={{ width: 50, borderRadius: 50 }}
        >
          <SendIcon />
        </Button>
      </Box>
    </>
  );
}
