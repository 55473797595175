import {
  Box,
  Button,
  Container,
  FormHelperText,
  Typography,
} from "@mui/material";

import { useFormik } from "formik";
import * as yup from "yup";

import NearMeIcon from "@mui/icons-material/NearMe";
import { amber, grey, red } from "@mui/material/colors";
import useToast from "../../../hooks/context/useToast";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import {
  BreakpointBox,
  FadeInContainer,
  TitleDivider,
} from "../../../components/AtomicDesign/Atoms";
import {
  ContactInfo,
  GenericInput,
  HtmlTextEditor,
} from "../../../components/AtomicDesign/Molecules";
import { sendEmail } from "../../../services/userServices";
import useAuth from "../../../hooks/context/useAuth";
import { useNavigate } from "react-router-dom";

export function Contact() {
  const { setOpen, setToastData } = useToast();
  const { isSmalldesktop } = useBreakpoints();
  const { user } = useAuth();
  const nav = useNavigate();
  const validationSchema = yup.object({
    name: yup.string().required("Write your name"),
    email: yup
      .string()
      .email("Insert a valid e-mail")
      .required("Write your e-mail"),
    subject: yup.string().required("Write your subject"),
    message: yup
      .string()
      .test("string test", (val) => {
        const regex = new RegExp(/(&nbsp;|<([^>]+)>)/gi);
        if (!val) return false;
        if (val.replace(regex, "").length === 0) return false;
        return true;
      })
      .required("Write details for your subject"),
  });
  const formik = useFormik<ISendEmail>({
    initialValues: {
      email: "",
      name: "",
      subject: "",
      message: "",
    },
    validationSchema,
    async onSubmit(values) {
      try {
        const response = await sendEmail({ ...values });
        if (response?.message === "success") {
          setToastData({
            anchor: { vertical: "top", horizontal: "center" },
            duration: 5000,
            type: "success",
            message: "The email has been sent",
          });
          setOpen(true);
        }
      } catch {
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "success",
          message:
            "An error occurred while sending the email, please try again later",
        });
        setOpen(true);
      }
    },
  });

  return (
    <Container maxWidth="lg">
      <FadeInContainer>
        <Box>
          <TitleDivider title="Any questions? Contact us anytime!" />

          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            <ContactInfo />
          </Box>

          {user ? (
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <TitleDivider title="Contact us by message!" />
              <Typography variant="h1" mb={3} color={grey[700]}>
                We're here every day to answer your questions, contact our
                customer service using the button below, we'll assist you as
                quickly as possible and with the greatest satisfaction!{" "}
              </Typography>
              <Button
                sx={{
                  width: 200,
                  height: 50,
                  background: red[500],
                  "&:hover": { background: red[300] },
                }}
                variant="contained"
                onClick={() => nav("/user/messages")}
              >
                Send message
              </Button>
            </Box>
          ) : (
            <>
              <TitleDivider title="Send an e-mail" />
              <Box
                width={"100%"}
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <BreakpointBox>
                    <GenericInput
                      hasIcon={false}
                      onChange={(value, name) =>
                        formik.setFieldValue(name, value)
                      }
                      error={
                        formik?.touched?.name && formik?.errors?.name
                          ? true
                          : false
                      }
                      errorMessage={
                        formik?.touched?.name ? formik?.errors?.name : ""
                      }
                      value={formik?.values?.name}
                      placeholder={"Name"}
                      inputName="name"
                    />

                    <GenericInput
                      hasIcon={false}
                      onChange={(value, name) =>
                        formik.setFieldValue(name, value)
                      }
                      error={
                        formik?.touched?.email && formik?.errors?.email
                          ? true
                          : false
                      }
                      errorMessage={
                        formik?.touched?.email ? formik?.errors?.email : ""
                      }
                      value={formik?.values?.email}
                      placeholder={"E-mail"}
                      inputName="email"
                    />
                  </BreakpointBox>
                  <GenericInput
                    hasIcon={false}
                    onChange={(value, name) =>
                      formik.setFieldValue(name, value)
                    }
                    error={
                      formik?.touched?.subject && formik?.errors?.subject
                        ? true
                        : false
                    }
                    errorMessage={
                      formik?.touched?.subject ? formik?.errors?.subject : ""
                    }
                    value={formik?.values?.subject}
                    placeholder={"Subject"}
                    inputName="subject"
                  />

                  <HtmlTextEditor
                    onChange={(val) => formik.setFieldValue("message", val)}
                    value={formik.values.message}
                  />
                  {formik?.touched?.message && formik?.errors?.message && (
                    <FormHelperText error sx={{ mt: 7 }}>
                      {formik?.errors?.message}
                    </FormHelperText>
                  )}
                </Box>

                <Button
                  variant={"contained"}
                  sx={{
                    width: "100%",
                    height: 40,
                    background: amber[500],
                    "&:hover": { background: amber[200] },
                    mb: 10,
                    mt: isSmalldesktop ? 10 : 7,
                  }}
                  onClick={() => formik.submitForm()}
                >
                  Send e-mail <NearMeIcon sx={{ ml: 1 }} />
                </Button>
              </Box>
            </>
          )}
        </Box>
      </FadeInContainer>
    </Container>
  );
}
