import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { colors } from "../../../../theme/colors";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import useAuth from "../../../../hooks/context/useAuth";
import { MessageContainer } from "./MessageContainer";

interface Props {
  data: IMessage[] | undefined;
  sendMessage(val: string): void;
}

export function MessageBox({ data, sendMessage }: Props) {
  const { user } = useAuth();
  const { isSmalldesktop } = useBreakpoints();

  const isAdmin = user?.user?.role === "admin";

  const scrollDown = () => {
    const getBarLocation = document?.querySelector("#roll-Bar") as any;
    if (getBarLocation) {
      setTimeout(() => {
        getBarLocation.scrollTop =
          getBarLocation?.scrollHeight - getBarLocation?.clientHeight;
      }, 500);
    }
  };

  const sendNewMessage = (val: string) => {
    if (val !== "") {
      sendMessage(val);

      scrollDown();
    }
  };

  useEffect(() => {
    scrollDown();

    const textMessage = document?.getElementById("text-message");

    textMessage?.addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        document?.getElementById("messageSubmit")?.click();
      }
    });

    // eslint-disable-next-line
  }, [data]);

  return (
    <Box width={"100%"}>
      <Box borderRadius={"10px"} width={"100%"}>
        <Box
          borderBottom={"1px solid lightgray"}
          p={2}
          bgcolor={colors.blue[900]}
          borderRadius={"10px"}
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography color={"#FFFFFF"} fontSize={18}>
            Messages
          </Typography>
        </Box>
      </Box>
      <Box
        width={"100%"}
        height={isSmalldesktop ? "83%" : "100%"}
        display={"flex"}
        flexDirection={"column"}
        gap={1}
      >
        {isAdmin ? (
          isAdmin && data?.length > 0 ? (
            <MessageContainer data={data} sendNewMessage={sendNewMessage} />
          ) : (
            <Typography variant="h1" textAlign={"center"} marginTop={"10%"}>
              Select a conversation
            </Typography>
          )
        ) : (
          <MessageContainer data={data} sendNewMessage={sendNewMessage} />
        )}
      </Box>
    </Box>
  );
}
