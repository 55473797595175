import {
  Box,
  FormControl,
  IconButton,
  Select,
  MenuItem,
  TextField,
  Divider,
  InputLabel,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { useCommonData } from "../../../../hooks/useCommonData";
import { useLocation } from "react-router-dom";
export interface Props {
  searchTerm?: string;
  onSelectField?(field: string): void;
  onChangeTextField?(searchTerm: string): void;
  selectedField?: string;
  onClickCleanFilter?: VoidFunction;
  handleItensperPage?(val: number): void;
  itensPerPage?: number;
  filterType?: "order" | "user";
}

const renderTextField = ["id", "suite", "userName", "productName"];

export function Filter({
  searchTerm,
  onSelectField,
  onChangeTextField,
  selectedField = "",
  onClickCleanFilter,
  handleItensperPage,
  itensPerPage,
  filterType = "order",
}: Props) {
  const { pathname } = useLocation();
  const { labelStatus, filter, orderFilterOptions } = useCommonData();
  const handleItensPage = (itens: number) => {
    handleItensperPage && handleItensperPage(itens);
  };

  const getFilterOptions = () => {
    const filterOptions = [
      { label: "ID", value: "id" },
      { label: "Suite", value: "suite" },
      { label: `${filter.userName}`, value: "userName" },
      { label: "Status", value: "status" },
      { label: `${filter.payMethod}`, value: "paymentChoice" },
      { label: `${filter.orderType}`, value: "orderType" },
      { label: `${filter.productName}`, value: "productName" },
    ];

    switch (pathname) {
      case "/admin/users":
        return [filterOptions[0], filterOptions[2]];
      default:
        return filterOptions;
    }
  };

  const statusChoices = [
    { value: "ordered", label: labelStatus.ordered.label },
    { value: "paymentSent", label: labelStatus.paymentSent.label },
    { value: "waitingStore", label: labelStatus.waitingStore.label },
    { value: "waitingPayment", label: labelStatus.waitingPayment.label },
    { value: "orderDone", label: labelStatus.orderDone.label },
    { value: "waitingCustomer", label: labelStatus.waitingCustomer.label },
    { value: "cancelOrder", label: labelStatus.cancelOrder.label },
    { value: "orderType", label: labelStatus.orderType.label },
    {
      value: "cancelOrderWithRefund",
      label: labelStatus.cancelOrderWithRefund.label,
    },
  ];

  const paymentMethodsChoices = [
    { value: "pix", label: "Pix (Brasil)" },
    { value: "wise", label: "Wise" },
    { value: "paypal", label: "Paypal" },
  ];

  const orderTypeOptions = [
    { value: "orderToBuy", label: orderFilterOptions.orderToBuy },
    { value: "askQuestion", label: orderFilterOptions.askQuestion },
    { value: "bid", label: orderFilterOptions.bid },
  ];

  return (
    <Box>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box display={"flex"}>
          {filterType === "order" ? (
            <Box display="flex" flexWrap={"wrap"} gap={1}>
              <FormControl variant="standard">
                <Select
                  variant="outlined"
                  labelId="filter"
                  id="filter"
                  value={selectedField}
                  onChange={(e) => {
                    onSelectField && onSelectField(e.target.value);
                    onChangeTextField && onChangeTextField("");
                  }}
                  sx={{ background: "white", height: 56, minWidth: 150 }}
                  defaultValue={""}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <em>{filter.title}</em>
                  </MenuItem>
                  {getFilterOptions()?.map(({ label, value }, index) => (
                    <MenuItem key={index} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {renderTextField.includes(selectedField) && (
                <TextField
                  id="outlined-basic"
                  placeholder="Search"
                  variant="outlined"
                  sx={{ background: "white" }}
                  value={searchTerm}
                  onChange={(e) => {
                    onChangeTextField && onChangeTextField(e.target.value);
                    // onSelectField && onSelectField("name");
                  }}
                />
              )}
            </Box>
          ) : (
            <Box display={"flex"} alignItems={"center"}>
              <Typography mr={1}>Name</Typography>
              <TextField
                id="outlined-basic"
                placeholder="Search"
                variant="outlined"
                sx={{ background: "white" }}
                value={searchTerm}
                onChange={(e) =>
                  onChangeTextField && onChangeTextField(e.target.value)
                }
              />
            </Box>
          )}

          {selectedField === "status" && (
            <FormControl variant="standard">
              <Select
                labelId="status"
                variant="outlined"
                id="status"
                value={searchTerm}
                onChange={(e) => {
                  onChangeTextField && onChangeTextField(e.target.value);
                }}
                sx={{ background: "white", height: 56, width: 110 }}
                defaultValue={""}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  <em>Select</em>
                </MenuItem>
                {statusChoices?.map(({ label, value }, index) => (
                  <MenuItem key={index} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {selectedField === "paymentChoice" && (
            <FormControl variant="standard">
              <Select
                labelId="paymentChoice"
                variant="outlined"
                id="paymentChoice"
                value={searchTerm}
                onChange={(e) => {
                  onChangeTextField && onChangeTextField(e.target.value);
                }}
                sx={{ background: "white", height: 56, width: 110 }}
                defaultValue={""}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  <em>Select</em>
                </MenuItem>
                {paymentMethodsChoices?.map(({ label, value }, index) => (
                  <MenuItem key={index} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {selectedField === "orderType" && (
            <FormControl variant="standard">
              <Select
                labelId="orderType"
                variant="outlined"
                id="orderType"
                value={searchTerm}
                onChange={(e) => {
                  onChangeTextField && onChangeTextField(e.target.value);
                }}
                sx={{ background: "white", height: 56, width: 110 }}
                defaultValue={""}
                displayEmpty
              >
                <MenuItem value="" disabled>
                  <em>Select</em>
                </MenuItem>
                {orderTypeOptions?.map(({ label, value }, index) => (
                  <MenuItem key={index} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <IconButton onClick={onClickCleanFilter} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Box>
        <FormControl sx={{ minWidth: 65 }}>
          <InputLabel id="demo-simple-select-label">Itens</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={itensPerPage}
            defaultValue={20}
            label="Age"
            onChange={(e) => handleItensPage(Number(e.target.value))}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={-1}>All</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Divider sx={{ mt: 1, mb: 1, width: "100%" }} />
    </Box>
  );
}
