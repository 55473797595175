import { createContext, useContext, useMemo, useState } from "react";
// import useToast from "./useToast";

import useGlobalLoading from "./useGlobalLoading";
import useAuth from "./useAuth";
import {
  getMyOrders,
  getMyPersonalStorage,
} from "../../services/ordersService";

const OrdersProviderContext = createContext({} as IOrdersContext);

function OrdersProvider({ children }: { children: React.ReactNode }) {
  const { setOpenLoading } = useGlobalLoading();
  // const { setOpen, setToastData } = useToast();
  const { user } = useAuth();
  const [ordersData, setOrdersData] = useState([] as OrderData[]);
  const [personalStorageData, setPersonalStorageData] = useState(
    [] as PersonalStorage[]
  );

  const [orderRecords, setOrdersRecords] = useState(0);
  const [personalStorageRecords, setPersonalStorageRecords] = useState(0);
  const [filters, setFilters] = useState<OrderFilter>({
    offset: 0,
    itensPerPage: 16,
    searchID: undefined,
  });

  const ordersDataMemo = useMemo(() => ordersData, [ordersData]);

  const personalStorageDataMemo = useMemo(
    () => personalStorageData,
    [personalStorageData]
  );

  const getOrders = async () => {
    setOpenLoading(true);

    try {
      const response = await getMyOrders({
        ...filters,
        userID: user?.user?.id,
        orderNumber: filters.searchID,
      });
      setOrdersData(response.data);
      setOrdersRecords(Math.ceil(response.count / filters.itensPerPage));
    } catch {
    } finally {
      setOpenLoading(false);
      // setTimeout(() => setOpenLoading(false), 5000);
    }
  };

  const getPersonalStorage = async (customFilter?: OrderFilter) => {
    setOpenLoading(true);

    try {
      const filt = customFilter ? customFilter : filters;
      const response = await getMyPersonalStorage({
        ...filt,
        personalStorageID: user?.personalStorageID,
      });
      setPersonalStorageData(response.data);
      setPersonalStorageRecords(
        Math.ceil(response.count / filters.itensPerPage)
      );
    } catch {
    } finally {
      setOpenLoading(false);

      // setTimeout(() => setOpenLoading(false), 5000);
    }
  };

  return (
    <OrdersProviderContext.Provider
      value={{
        orderRecords,
        personalStorageRecords,
        ordersDataMemo,
        personalStorageDataMemo,
        filters,
        setFilters,
        getOrders,
        getPersonalStorage,
      }}
    >
      {children}
    </OrdersProviderContext.Provider>
  );
}

export { OrdersProvider, OrdersProviderContext };

export default function useOrders() {
  return useContext(OrdersProviderContext);
}
