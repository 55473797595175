import { api } from "./api";

export const createShippmentService = async (data: CreateShippment) => {
  const response2 = await api.post("/create-shippment", { ...data });
  return response2.data;
};

export const getShippmentsService = async (data: ListShippmentsParams) => {
  const response2 = await api.get("/get-shippments", { params: { ...data } });
  return response2.data;
};

export const getShippmentsAdminService = async (data: ListShippmentsParams) => {
  const response2 = await api.get("/get-shippments-admin", {
    params: { ...data },
  });
  return response2.data;
};

export const getPersonalStorageToShip = async (personalStorageID: number) => {
  const response2 = await api.get("/get-personalstorage-shippment", {
    params: { personalStorageID },
  });
  return response2.data;
};

export const chargeShippmentService = async (data: ShippmentCharge) => {
  const response2 = await api.post("/charge-shippment", { ...data });
  return response2.data;
};

export const verifyPaymentShippmentService = async (
  data: VerifyPaymentShippment
) => {
  const response2 = await api.post("/update-payment-shippment", { ...data });
  return response2.data;
};

export const updateShippmentService = async (data: UpdateShippmentAddress) => {
  const response2 = await api.post("/update-shippment", { ...data });
  return response2.data;
};

export const updateShippmentProductsService = async (
  data: UpdateShippmentProducts
) => {
  const response2 = await api.post("/update-shippment-products", { ...data });
  return response2.data;
};

export const updateShippmentAdminService = async (
  data: UpdateShippmentAdmin
) => {
  const response2 = await api.post("/update-shippment-admin", { ...data });
  return response2.data;
};
