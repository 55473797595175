import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Pagination,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import {
  CustomButton,
  OrderAcordion,
} from "../../../components/AtomicDesign/Molecules";
import useOrdersAdmin from "../../../hooks/context/useOrdersAdmin";
import { updatePaymentStatus } from "../../../services/cartService";
import { useEffect, useState } from "react";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import SearchIcon from "@mui/icons-material/Search";

export function AdminOrders() {
  const {
    ordersAdminDataMemo,
    orderAdminRecords,
    getOrdersAdmin,
    setFilters,
    filters,
  } = useOrdersAdmin();
  const { isMobile } = useBreakpoints();
  const [page, setPage] = useState(1);
  const [text, setText] = useState("");
  const [loadingLine, setLoadingLine] = useState({
    index: null,
    loading: false,
  });

  const getPayInfo = async (orderID, payID, index) => {
    setLoadingLine({ index, loading: true });
    try {
      await updatePaymentStatus({
        orderID: Number(orderID),
        sessionid: payID,
      });
      getOrdersAdmin();
    } catch {
    } finally {
      setLoadingLine({ index: null, loading: true });
    }
  };

  const verifyAndCharge = () => {};

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((old: any) => ({
      ...old,
      paymentStatus: (event.target as HTMLInputElement).value,
    }));
  };

  useEffect(() => {
    setPage(1);
  }, [filters]);

  const cleanSearch = () => {
    setFilters((old: any) => ({
      ...old,
      orderNumber: undefined,
    }));
    setText("");
  };

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      mt={2}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "flex-start",
          alignItems: isMobile ? "flex-start" : "flex-end",
          gap: isMobile ? 1 : 5,
          pl: 2,
        }}
      >
        <TextField
          sx={{ background: "#FFF" }}
          label="Order number"
          value={text}
          onChange={(e) => setText(e.target.value)}
          InputProps={{
            endAdornment: (
              <>
                {text && (
                  <CustomButton
                    btnType="delete"
                    onClick={cleanSearch}
                    style={{ marginRight: 5 }}
                  />
                )}
                <CustomButton
                  btnType="search"
                  onClick={() => {
                    setFilters((old: any) => ({
                      ...old,
                      orderNumber: text,
                    }));
                  }}
                >
                  <SearchIcon />
                </CustomButton>
              </>
            ),
          }}
        />

        <FormControl>
          <FormLabel id="demo-radio-buttons-payment-status">
            Payment status
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-payment-status"
            defaultValue="all"
            value={filters.paymentStatus}
            name="radio-buttons-group"
            onChange={handleChange}
            sx={{ flexDirection: "row" }}
          >
            <FormControlLabel value="all" control={<Radio />} label="All" />
            <FormControlLabel
              value="unpaid"
              control={<Radio />}
              label="Unpaid"
            />
            <FormControlLabel
              value="processing"
              control={<Radio />}
              label="Processing"
            />
            <FormControlLabel value="paid" control={<Radio />} label="Paid" />
          </RadioGroup>
        </FormControl>

        <FormControlLabel
          control={
            <Checkbox
              checked={filters.preOrder}
              onChange={(e) =>
                setFilters((old: any) => ({
                  ...old,
                  preOrder: (e.target as HTMLInputElement).checked,
                }))
              }
            />
          }
          label="Pre-Orders"
        />
      </Box>

      <OrderAcordion
        getPayInfo={getPayInfo}
        loadingLine={loadingLine}
        verifyAndCharge={verifyAndCharge}
        orders={ordersAdminDataMemo}
        isAdmin
        getOrders={getOrdersAdmin}
      />
      <Box display={"flex"} justifyContent={"center"} width={"100%"} mt={2}>
        <Pagination
          count={orderAdminRecords}
          page={page}
          defaultPage={1}
          onChange={(_e, page) => {
            setFilters((old) => ({ ...old, offset: page - 1 }));
            setPage(page);
          }}
        />
      </Box>
    </Box>
  );
}
