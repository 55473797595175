import { api } from "./api";
import { apiShipping } from "./updateUserService";

export const getUserServiceFavoriteList = async (data: UserServicesFilter) => {
  const response2 = await api.get("/favorites-list", {
    params: { ...data },
  });
  return response2.data;
};

export const addFavorite = async (data: FavoritePayload) => {
  const response = await api.post("/add-favorite", { ...data });
  return response.data;
};

export const removeFavorite = async (data: FavoritePayload) => {
  await api.delete("/remove-favorite", { params: { ...data } });
};

export const sendEmail = async (data: ISendEmail) => {
  const response2 = await apiShipping.post("/send-email", {
    ...data,
  });
  return response2.data;
};

export const createServiceMessage = async (data: ServiceMessage) => {
  const response = await api.post("/create-message", { ...data });
  return response.data;
};

export const getServiceMessages = async (userID: number) => {
  const response = await api.get("/get-messages", { params: { userID } });
  return response.data;
};

export const getServiceMessagesAdmin = async () => {
  const response = await api.get("/get-messages-admin");
  return response.data;
};

export const verifyServiceMessages = async (userID: number) => {
  const response = await api.get("/verify-messages", { params: { userID } });
  return response.data;
};

export const verifyServiceMessagesAdmin = async () => {
  const response = await api.get("/verify-messages-admin");
  return response.data;
};

export const markAsRead = async (data: MarkReadMessage) => {
  const response = await api.post("/mark-as-read", { ...data });
  return response.data;
};
