import { Box, Chip, Typography } from "@mui/material";
import { FadeInContainer } from "../../Atoms";
import { Link, useLocation } from "react-router-dom";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { amber, brown, grey, yellow } from "@mui/material/colors";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { colors } from "../../../../theme/colors";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";

interface Props {
  list: Omit<ProductData, "Manufacturers" | "Versions" | "Origin">[];
  onClickAdminEdit?: (id: number) => void;
  hasRanking?: boolean;
}

export function ProductList({
  list,
  onClickAdminEdit,
  hasRanking = false,
}: Props) {
  const { JPYmoney } = useJPYmoney();
  const { pathname } = useLocation();
  const { isSmalldesktop } = useBreakpoints();

  const colorsRanking = {
    0: yellow[700],
    1: grey[400],
    2: brown[300],
  };

  const RenderWithLink = () => (
    <>
      {list?.map((item, index) => (
        <FadeInContainer key={index}>
          <Link
            to={`/details?product=${item?.id}`}
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Box
              width={151}
              display={"flex"}
              flexDirection={"column"}
              sx={{
                "&: hover": {
                  cursor: "pointer",
                  opacity: "70%",
                },
              }}
            >
              {hasRanking && index < 3 && (
                <Typography
                  style={{
                    position: "absolute",
                    background: colorsRanking[index],
                    width: 50,

                    borderRadius: "5px 0 5px 0",
                    color: "#FFFFFF",

                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: 30,
                  }}
                >
                  <EmojiEventsIcon /> {index + 1}°
                </Typography>
              )}
              {item?.isSale && (
                <Typography
                  style={{
                    position: "absolute",
                    background: colors.red[500],
                    width: 50,
                    marginLeft: 105,
                    marginTop: 5,
                    borderRadius: 5,
                    color: "#FFFFFF",
                    textAlign: "center",
                    transform: "rotate(45deg)",
                  }}
                >
                  Sale
                </Typography>
              )}
              <img
                src={item?.productsImages[0]?.code}
                style={{
                  objectFit: "contain",
                  width: 150,
                  height: 150,
                  borderRadius: 5,
                  background: grey[200],
                }}
                alt={item?.characterName}
              />
              {item?.isPreOwned && (
                <Typography
                  style={{
                    position: "absolute",
                    background: colors.yellow[400],
                    width: 150,
                    marginTop: 126,
                    borderRadius: "0 0 5px 5px",
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  Pre-Owned
                </Typography>
              )}
              <Chip
                label={
                  item?.isPreOrder
                    ? "Pre-Order"
                    : item.stock <= 0
                    ? "Out of stock"
                    : "In stock"
                }
                color={
                  item?.isPreOrder
                    ? "error"
                    : item?.stock > 0
                    ? "info"
                    : "default"
                }
                sx={{ mt: 1, width: 150 }}
              />
              <Box maxWidth={150} p={1}>
                <Typography style={{ wordBreak: "break-word" }}>
                  {item?.characterName}
                </Typography>
                <Typography style={{ wordBreak: "break-word", fontSize: 12 }}>
                  {item?.manufacturerName}
                </Typography>
                <Typography
                  style={{ wordBreak: "break-word", fontSize: 20 }}
                  mt={1}
                  color={amber[900]}
                >
                  {JPYmoney(item?.price)}
                </Typography>
              </Box>
            </Box>
          </Link>
        </FadeInContainer>
      ))}
    </>
  );

  const RenderWithoutLink = () => (
    <Box
      display={"flex"}
      flexWrap={"wrap"}
      width={"100%"}
      gap={2}
      justifyContent={isSmalldesktop ? "center" : "flex-start"}
      mt={2}
    >
      {list?.map((item, index) => (
        <Box
          key={index}
          width={151}
          display={"flex"}
          flexDirection={"column"}
          sx={{
            "&: hover": { cursor: "pointer", opacity: "70%" },
          }}
          onClick={() => onClickAdminEdit(item?.id)}
        >
          {item?.isSale && (
            <Typography
              style={{
                position: "absolute",
                background: colors.red[500],
                width: 50,
                marginLeft: 105,
                marginTop: 5,
                borderRadius: 5,
                color: "#FFFFFF",
                textAlign: "center",
                transform: "rotate(45deg)",
              }}
            >
              Sale
            </Typography>
          )}
          <img
            src={item?.productsImages[0]?.code}
            style={{
              objectFit: "contain",
              width: 150,
              height: 150,
              borderRadius: 5,
              background: grey[200],
            }}
            alt={item?.characterName}
          />

          {item?.isPreOwned && (
            <Typography
              style={{
                position: "absolute",
                background: colors.yellow[400],
                width: 150,
                marginTop: 126,
                borderRadius: "0 0 5px 5px",
                color: "#FFFFFF",
                textAlign: "center",
              }}
            >
              Pre-Owned
            </Typography>
          )}
          <Chip
            label={
              item?.isPreOrder
                ? "Pre-Order"
                : item.stock <= 0
                ? "Out of stock"
                : "In stock"
            }
            color={
              item?.isPreOrder ? "error" : item?.stock > 0 ? "info" : "default"
            }
            sx={{ mt: 1, width: 150 }}
          />
          <Box maxWidth={150} p={1}>
            <Typography style={{ wordBreak: "break-word" }}>
              {item?.characterName}
            </Typography>
            <Typography style={{ wordBreak: "break-word", fontSize: 12 }}>
              {item?.manufacturerName}
            </Typography>
            <Typography
              style={{ wordBreak: "break-word", fontSize: 20 }}
              mt={1}
              color={amber[900]}
            >
              {JPYmoney(item?.price)}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
  return (
    <>
      {pathname === "/admin/products" ? (
        <RenderWithoutLink />
      ) : (
        <RenderWithLink />
      )}
    </>
  );
}
