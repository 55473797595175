import { Box, Button, Typography } from "@mui/material";
import {
  FadeInContainer,
  TitleDivider,
} from "../../../components/AtomicDesign/Atoms";
import { amber, grey } from "@mui/material/colors";
import meninaComCelular from "../../../assets/images/menina_com_celular.png";
import { useBreakpoints } from "../../../hooks/useBreakpoins";

export function Proxy() {
  const { isSmalldesktop } = useBreakpoints();
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
      alignItems={"center"}
      width={"100%"}
    >
      <FadeInContainer>
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <TitleDivider title="Proxy service" />
          <Typography
            variant="h1"
            mb={2}
            color={grey[700]}
            textAlign={"center"}
          >
            We also have a proxy service!
          </Typography>
          <Typography
            variant="h1"
            mb={2}
            color={grey[700]}
            textAlign={"center"}
            p={1}
          >
            Didn't find the figure/item you wanted? Enter our proxy system and
            place your order!
          </Typography>
          <Typography
            variant="h1"
            mb={2}
            color={grey[700]}
            textAlign={"center"}
            p={1}
          >
            We will buy any item you want for you, we work with several Japanese
            web-sites such as Mercari, Yahoo Auctions, Amazon JP, Hobby Link,
            Pokemon Center, Mandarake and many others
          </Typography>
          <Typography
            variant="h1"
            mb={2}
            color={grey[700]}
            textAlign={"center"}
            p={1}
          >
            For more information, click bellow
          </Typography>
          <Button
            variant="contained"
            sx={{
              background: amber[500],
              "&:hover": { background: amber[200] },
              width: 300,
              height: 50,
              fontSize: 18,
              mt: 2,
              mb: 2,
            }}
          >
            <a
              href="https://treasureboxjapan.com/"
              target="_blank"
              rel="noreferrer"
              style={{
                all: "unset",
              }}
            >
              Go to our Proxy web-site
            </a>
          </Button>

          <img
            src={meninaComCelular}
            width={isSmalldesktop ? 350 : 500}
            alt="menina-com-celular"
          />
        </Box>
      </FadeInContainer>
    </Box>
  );
}
