import { Box, Button, Typography } from "@mui/material";

import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { useDateTime } from "../../../../hooks/useDateTime";
import { grey } from "@mui/material/colors";
import { colors } from "../../../../theme/colors";
import { filterByStatus } from "../../../../pages/MyInfo/componentsPage/Shippments/components/labels";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";

interface Props {
  list: ShippmentData[];
  setSelectedShip: (ship: ShippmentData) => void;
  verifyPayment: (data: VerifyPaymentShippment) => void;
}

export function ShipCards({ list, setSelectedShip, verifyPayment }: Props) {
  const { JPYmoney } = useJPYmoney();
  const { formatedDate } = useDateTime();
  const { isMobile, semiSmalldesktop } = useBreakpoints();

  const formatShipType = (choice: ShipTypes) => {
    const types = {
      smallPacket: "Small Packet",
      EMS: "EMS",
      surface: "Sal Parcel",
      airParcel: "Air Parcel",
      domestic: "Domestic",
    };
    return types[choice];
  };

  return (
    <Box minWidth={"100%"} display={"flex"} flexDirection={"column"} gap={1}>
      {list?.length > 0 &&
        list?.map((ship, index) => (
          <Box
            key={index}
            display={"flex"}
            sx={{ border: "1px dotted gray", borderRadius: 2 }}
            minWidth={"100%"}
          >
            <Box
              sx={{
                background: colors.blue[900],
                borderRadius: "8px 0px 0px 8px",
                color: "#FFFFFF",
              }}
              p={2}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
            >
              <Typography variant="h1" textAlign={"center"}>
                Shipment
              </Typography>
              <Typography variant="h1" textAlign={"center"}>
                {ship?.id}
              </Typography>
              <Button
                sx={{
                  mt: 1,
                  background: "#5c81a6",
                  "&:hover": { background: "#5c81a690" },
                }}
                variant="contained"
                onClick={() => setSelectedShip(ship)}
              >
                Details
              </Button>
            </Box>
            <Box
              width={isMobile ? "100%" : semiSmalldesktop ? "87.5%" : "100%"}
              sx={{ overflowX: "hidden" }}
            >
              <Box
                display={"flex"}
                flexWrap={"wrap"}
                minHeight={40}
                justifyContent={"space-evenly"}
                alignItems={"center"}
                sx={{
                  background: grey[200],
                  borderRadius: "0px 5px 0px 0px",
                }}
              >
                <Typography width={160}>
                  Created at: {formatedDate(ship?.createdAt)}
                </Typography>
                <Typography width={160}>
                  Shipped: {formatedDate(ship?.shippedDate)}
                </Typography>
                <Typography width={160}>
                  Price: {JPYmoney(ship?.shippingFee)}
                </Typography>
                <Typography width={160}>
                  Method: {formatShipType(ship?.shipType)}
                </Typography>
                <Typography
                  width={190}
                  sx={{
                    background: filterByStatus[ship?.status]?.color,
                    borderRadius: 5,
                    p: 0.5,
                    color: "#FFFFFF",
                    textAlign: "center",
                  }}
                >
                  {filterByStatus[ship?.status]?.text}
                </Typography>
                {ship?.paymentStatus === "processing" && (
                  <Button
                    variant="contained"
                    sx={{ fontSize: 11 }}
                    onClick={() =>
                      verifyPayment({
                        sessionid: ship?.chargeID,
                        shippmentID: ship?.id,
                      })
                    }
                  >
                    Click to verify payment
                  </Button>
                )}
              </Box>
              <Box
                display={"flex"}
                gap={1}
                sx={{ overflowX: "auto" }}
                p={1}
                width={isMobile ? "95%" : semiSmalldesktop ? "95%" : "100%"}
              >
                {ship?.shippmentXPersonalStorage?.map((item) => (
                  <img
                    src={
                      item.PersonalStorageXProduct?.Products?.productsImages[0]
                        ?.code
                    }
                    style={{
                      objectFit: "contain",
                      width: 100,
                      height: 100,
                      borderRadius: 5,
                      background: grey[200],
                    }}
                    alt={item.PersonalStorageXProduct?.Products?.characterName}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        ))}
    </Box>
  );
}
