import { Link } from "react-router-dom";
import NEWTREASUREBOXLOGO from "../../../assets/images/NEWTREASUREBOXLOGO2.png";
import { useBreakpoints } from "../../../hooks/useBreakpoins";

export function Logo({ onClick }: { onClick?: VoidFunction }) {
  const { isSmalldesktop } = useBreakpoints();

  return (
    <Link
      to={`/home`}
      rel="noopener noreferrer"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      <img
        src={NEWTREASUREBOXLOGO}
        width={isSmalldesktop ? 100 : 120}
        alt="treasurebox logo"
        style={{
          marginTop: 5,
          marginLeft: 15,
          paddingTop: 3,
          paddingBottom: 3,
          cursor: "pointer",
        }}
        onClick={() => {
          onClick && onClick();
        }}
      />
    </Link>
  );
}
