import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";

interface Props {
  value: string;
  onChange(value: string, name: string): void;
  error?: boolean;
  placeholder: string;
  width?: string;
  errorMessage?: string | any;
  name: string;
  options: {
    label: string | any;
    value: string | number;
  }[];
  disabled?: boolean;
  defaultValue?: any;
  formWidth?: string;
  variant?: "standard" | "outlined" | "filled";
  props?: SelectProps;
}

export function GenericSelect({
  value,
  onChange,
  error,
  placeholder,
  width = "100%",
  formWidth = "95%",
  errorMessage,
  name,
  options,
  disabled = false,
  defaultValue = "",
  variant,
  props,
}: Props) {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value, name);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: width,
      }}
    >
      <FormControl
        variant="standard"
        sx={{
          m: 1,
          width: formWidth,
        }}
      >
        <InputLabel id="demo-simple-select-standard-label">
          {placeholder}
        </InputLabel>
        <Select
          variant={variant}
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          {...props}
          value={value}
          onChange={handleChange}
          defaultValue={defaultValue}
          defaultChecked
          label={placeholder}
          name={name}
          error={error}
          disabled={disabled}
        >
          <MenuItem value="" disabled>
            <em>Select</em>
          </MenuItem>
          {options?.map(({ label, value }, index) => (
            <MenuItem value={value} key={index}>
              <p style={{ whiteSpace: "pre-wrap" }}>{label}</p>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormHelperText error>{errorMessage}</FormHelperText>
    </Box>
  );
}
