import { Box, Divider, Typography } from "@mui/material";

export function TitleDivider({
  title,
  fontSize = 40,
  dividerW = "80%",
}: {
  title: string;
  fontSize?: number;
  dividerW?: string;
}) {
  return (
    <Box
      width={"100%"}
      mt={3}
      mb={5}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Typography
        fontSize={fontSize}
        fontWeight={500}
        textAlign={"center"}
        pt={3}
      >
        {title}
      </Typography>
      <Divider sx={{ mt: 2, bgcolor: "white", width: dividerW }} />
    </Box>
  );
}
