import { createContext, useContext, useState } from "react";

const HeaderContext = createContext({} as IUseHeaderContext);

type HeaderProviderProps = {
  children: React.ReactNode;
};

function HeaderProvider({ children }: HeaderProviderProps) {
  const [filter, setFilter] = useState({ search: "" });
  const [activeTab, setActiveTab] = useState(0);

  return (
    <HeaderContext.Provider
      value={{ filter, setFilter, activeTab, setActiveTab }}
    >
      {children}
    </HeaderContext.Provider>
  );
}

export { HeaderProvider, HeaderContext };

export default function useHeader() {
  return useContext(HeaderContext);
}
