import { useEffect, useState } from "react";

import {
  CardActions,
  CardContent,
  IconButton,
  Card,
  Box,
  Button,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataText, CircularLoading, FadeInContainer } from "../../../Atoms";
import useAuth from "../../../../../hooks/context/useAuth";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { CustomButton } from "../../../Molecules";
import useToast from "../../../../../hooks/context/useToast";
import {
  createAddress,
  deleteAddress,
  editAddress,
  getUserAddress,
} from "../../../../../services/addressService";
import { CustomModal } from "../../Modal";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";
import { EditCreateAddress } from "../EditAddress";
import { red } from "@mui/material/colors";
import { colors } from "../../../../../theme/colors";

interface Props {
  userData: User;
}

export function AddressBox({ userData }: Props) {
  const { setOpen, setToastData } = useToast();
  const { user } = useAuth();
  const { isSmalldesktop } = useBreakpoints();
  const [loading, setLoading] = useState(false);
  const [loadingNewAd, setLoadingNewAd] = useState(false);
  const [address, setAddress] = useState<IAddress[]>();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<IAddress | null>();

  const closeModal = () => {
    setModalOpen(false);
    setSelectedAddress(null);
  };

  const myAddress = async (id: number) => {
    setLoading(true);
    try {
      const myAddress = await getUserAddress(id);
      setAddress(myAddress);
    } catch (err) {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 3000,
        type: "error",
        message: strings.toastMessages.errorAddress[language],
      });
      setOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const createEditAddress = async (val: EditCreateAddress) => {
    setLoadingNewAd(true);
    if (val.type === "create") {
      try {
        await createAddress(val);
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "success",
          message: strings.toastMessages.successCreateAddress[language],
        });
        setOpen(true);
        await myAddress(user?.user?.id);
        closeModal();
      } catch (err) {
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "error",
          message: strings.toastMessages.errorCreateAddress[language],
        });
        setOpen(true);
      } finally {
        setLoadingNewAd(false);
      }
    } else {
      try {
        await editAddress(val);
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "success",
          message: strings.toastMessages.successUpdateAddress[language],
        });
        setOpen(true);
        await myAddress(user?.user?.id);
        closeModal();
      } catch (err) {
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "error",
          message: strings.toastMessages.errorUpdateAddress[language],
        });
        setOpen(true);
      } finally {
        setLoadingNewAd(false);
      }
    }
  };

  const deleteMyAddress = async (addressID: number) => {
    try {
      await deleteAddress(addressID);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: strings.toastMessages.successDeleteAddress[language],
      });
      setOpen(true);
      await myAddress(user?.user?.id);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: strings.toastMessages.errorDeleteAddress[language],
      });
      setOpen(true);
    }
  };

  useEffect(() => {
    const localUser = localStorage.getItem("@treasurebox_user") as any;
    if (localUser) {
      const parseID = JSON.parse(localUser).user.id;

      myAddress(parseID);
    }
    // eslint-disable-next-line
  }, []);

  const { language } = useAuth();
  const { strings } = useTranslate();
  return (
    <Box
      width={"100%"}
      minHeight={"480px"}
      display="flex"
      flexWrap={"wrap"}
      rowGap={2}
      justifyContent={"space-evenly"}
    >
      <Box borderRadius={"10px"} width={"100%"}>
        <Box
          borderBottom={"1px solid lightgray"}
          p={2}
          bgcolor={colors.blue[900]}
          borderRadius={"10px"}
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography color={"#FFFFFF"} fontSize={18}>
            My address list
          </Typography>
          <Button
            sx={{
              bgcolor: red[500],
              color: "white",
              "&:hover": { bgcolor: red["A400"] },
            }}
            onClick={() => setModalOpen(true)}
          >
            New Address
          </Button>
        </Box>
      </Box>
      <FadeInContainer>
        <Box
          display={"flex"}
          width={"100%"}
          gap={1}
          justifyContent={"center"}
          flexWrap={"wrap"}
        >
          {loading ? (
            <CircularLoading />
          ) : (
            address &&
            address?.length > 0 &&
            address.map((item, index) => (
              <Card
                sx={{
                  width: 340,
                  height: 270,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                key={index}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      justifyContent: "center",
                      rowGap: 1,
                    }}
                  >
                    <DataText
                      title={strings.myAddress.labels.address[language]}
                      text={`${item.address} ${item.adressNumber}`}
                    />
                    <DataText
                      title={"Complement:"}
                      text={`${item.addressComplement}`}
                    />
                    <DataText
                      title={"Neighborhood:"}
                      text={`${item.neighborhood}`}
                    />
                    <DataText
                      title={strings.myAddress.labels.city[language]}
                      text={`${item.city} / ${item.state}`}
                    />
                    <DataText
                      title={strings.myAddress.labels.country[language]}
                      text={`${item.country}`}
                    />
                    <DataText
                      title={strings.myAddress.labels.zipCode[language]}
                      text={`${item.postalCode}`}
                    />
                    {/* <DataText
                      title={strings.myAddress.labels.ssn[language]}
                      text={`${item.ssn}`}
                    /> */}
                    <DataText
                      title={strings.myAddress.labels.receiver[language]}
                      text={`${item.receiver}`}
                    />
                  </Box>
                </CardContent>
                <CardActions
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CustomButton
                    btnType="login"
                    onClick={() => {
                      setSelectedAddress(item);
                      setModalOpen(true);
                    }}
                    style={{
                      color: "white",
                      minWidth: 50,
                      maxWidth: 50,
                      minHeight: 40,
                    }}
                    text="Edit"
                  />

                  <IconButton
                    onClick={() => deleteMyAddress(item.id)}
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </CardActions>
              </Card>
            ))
          )}
        </Box>
      </FadeInContainer>
      <CustomModal
        openModal={modalOpen}
        setOpenModal={closeModal}
        width={isSmalldesktop ? "90%" : "50%"}
      >
        <EditCreateAddress
          userData={userData}
          submitLoading={loadingNewAd}
          address={selectedAddress}
          handleSubmit={(val) => createEditAddress(val)}
          thisUserAlreadyHaveAddress={
            address && address?.length > 0 ? true : false
          }
        />
      </CustomModal>
    </Box>
  );
}
