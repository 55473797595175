import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import useProducts from "../../../../hooks/context/useProducts";
import { CustomModal } from "../../Organisms";
import { useState } from "react";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { CustomButton } from "../Buttons";
import { colors } from "../../../../theme/colors";

export function LateralFiltersAdmin() {
  const { productsDataMemo, productsfilters, setProductsFilters } =
    useProducts();
  const { isSmalldesktop } = useBreakpoints();

  const [openModal, setOpenModal] = useState(false);

  const Radios = () => (
    <>
      <FormControl>
        <FormLabel
          id="quantity-labels"
          sx={{ height: 36, display: "flex", alignItems: "center" }}
        >
          Stock
          <Button
            variant="text"
            onClick={() => {
              setProductsFilters((old) => ({
                ...old,
                outOfStock: undefined,
                preOrder: undefined,
                inStock: undefined,
                isSale: undefined,
                isPreOwned: undefined,
              }));
            }}
          >
            X
          </Button>
        </FormLabel>

        <RadioGroup>
          <FormControlLabel
            value={true}
            control={<Radio />}
            label={"Sale"}
            onChange={() => {
              setProductsFilters((old) => ({
                ...old,
                outOfStock: undefined,
                preOrder: undefined,
                inStock: undefined,
                isSale: true,
                isPreOwned: undefined,
              }));
            }}
            checked={productsfilters.isSale}
          />

          <FormControlLabel
            value={true}
            control={<Radio />}
            label={"Pre-Order"}
            onChange={() => {
              setProductsFilters((old) => ({
                ...old,
                outOfStock: undefined,
                preOrder: true,
                inStock: undefined,
                isSale: undefined,
                isPreOwned: undefined,
              }));
            }}
            checked={productsfilters.preOrder}
          />

          <FormControlLabel
            value={true}
            control={<Radio />}
            label={"Pre-Owned"}
            onChange={() => {
              setProductsFilters((old) => ({
                ...old,
                outOfStock: undefined,
                preOrder: undefined,
                inStock: undefined,
                isSale: undefined,
                isPreOwned: true,
              }));
            }}
            checked={productsfilters.isPreOwned}
          />

          <FormControlLabel
            value={true}
            control={<Radio />}
            label={"In-Stock"}
            onChange={() => {
              setProductsFilters((old) => ({
                ...old,
                outOfStock: undefined,
                preOrder: undefined,
                inStock: true,
                isSale: undefined,
                isPreOwned: undefined,
              }));
            }}
            checked={productsfilters.inStock}
          />

          <FormControlLabel
            value={true}
            control={<Radio />}
            label={"Out-of-Stock"}
            onChange={() => {
              setProductsFilters((old) => ({
                ...old,
                outOfStock: true,
                preOrder: undefined,
                inStock: undefined,
                isSale: undefined,
                isPreOwned: undefined,
              }));
            }}
            checked={productsfilters.outOfStock}
          />
        </RadioGroup>
      </FormControl>

      <FormControl>
        <FormLabel
          id="origin-labels"
          sx={{ height: 36, display: "flex", alignItems: "center" }}
        >
          Product Type
          {productsfilters.productTypeName && (
            <Button
              variant="text"
              onClick={() => {
                setProductsFilters((old) => ({
                  ...old,
                  productTypeName: undefined,
                }));
              }}
            >
              X
            </Button>
          )}
        </FormLabel>
        <RadioGroup>
          {productsDataMemo?.productType?.map((item) => (
            <FormControlLabel
              value={item?.name}
              control={<Radio />}
              label={item?.name}
              onChange={() => {
                setProductsFilters((old) => ({
                  ...old,
                  productTypeName: item?.name,
                }));
              }}
              checked={productsfilters.productTypeName === item?.name}
            />
          ))}
        </RadioGroup>
      </FormControl>

      <FormControl>
        <FormLabel
          id="origin-labels"
          sx={{ height: 36, display: "flex", alignItems: "center" }}
        >
          Origins
          {productsfilters.originName && (
            <Button
              variant="text"
              onClick={() => {
                setProductsFilters((old) => ({
                  ...old,
                  originName: undefined,
                }));
              }}
            >
              X
            </Button>
          )}
        </FormLabel>
        <RadioGroup>
          {productsDataMemo?.origins?.map((item) => (
            <FormControlLabel
              value={item?.name}
              control={<Radio />}
              label={item?.name}
              onChange={() => {
                setProductsFilters((old) => ({
                  ...old,
                  originName: item?.name,
                }));
              }}
              checked={productsfilters.originName === item?.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel
          id="mdanufacturers-labels"
          sx={{ height: 36, display: "flex", alignItems: "center" }}
        >
          Manufacturers
          {productsfilters.manufacturerName && (
            <Button
              variant="text"
              onClick={() => {
                setProductsFilters((old) => ({
                  ...old,
                  manufacturerName: undefined,
                }));
              }}
            >
              X
            </Button>
          )}
        </FormLabel>
        <RadioGroup>
          {productsDataMemo?.manufacturers?.map((item) => (
            <FormControlLabel
              value={item?.name}
              control={<Radio />}
              label={item?.name}
              onChange={() => {
                setProductsFilters((old) => ({
                  ...old,
                  manufacturerName: item?.name,
                }));
              }}
              checked={productsfilters.manufacturerName === item?.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
      <FormControl>
        <FormLabel
          id="versions-labels"
          sx={{ height: 36, display: "flex", alignItems: "center" }}
        >
          Versions
          {productsfilters.versionName && (
            <Button
              variant="text"
              onClick={() => {
                setProductsFilters((old) => ({
                  ...old,
                  versionName: undefined,
                }));
              }}
            >
              X
            </Button>
          )}
        </FormLabel>
        <RadioGroup>
          {productsDataMemo?.versions?.map((item) => (
            <FormControlLabel
              value={item?.name}
              control={<Radio />}
              label={item?.name}
              onChange={() => {
                setProductsFilters((old) => ({
                  ...old,
                  versionName: item?.name,
                }));
              }}
              checked={productsfilters.versionName === item?.name}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={isSmalldesktop ? "center" : "flex-start"}
      justifyContent={"center"}
      gap={3}
      minWidth={isSmalldesktop ? "100%" : "17.5%"}
      mt={2}
      sx={{
        border: isSmalldesktop ? "none" : "1px dotted black",
        p: isSmalldesktop ? 0 : 2,
        borderRadius: 5,
      }}
    >
      {!isSmalldesktop && (
        <Box
          sx={{
            background: colors.blue[900],
            width: "100%",
            borderRadius: 5,
          }}
        >
          <Typography
            sx={{ color: "#FFFFFF", p: 1.5, textAlign: "center", fontSize: 18 }}
          >
            Filter
          </Typography>
        </Box>
      )}
      {isSmalldesktop ? (
        <>
          <CustomButton
            btnType="share"
            fullWidth
            style={{ width: "90%", marginBottom: 8 }}
            onClick={() => setOpenModal(true)}
          >
            Add refined condition
          </CustomButton>
          <CustomModal
            width={"90%"}
            openModal={openModal}
            setOpenModal={setOpenModal}
            title="Filter"
          >
            <Box px={3} py={1} display={"flex"} flexDirection={"column"}>
              <Radios />
            </Box>
          </CustomModal>
        </>
      ) : (
        <Radios />
      )}
    </Box>
  );
}
