import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";

interface Props {
  list: { photo: string; link: string }[];
}

export function Carrousel({ list }: Props) {
  const { isSmalldesktop, isMobile } = useBreakpoints();

  const width = () => {
    if (isMobile) return { width: 360, height: 140 };
    if (isSmalldesktop) return { width: 680, height: 230 };
    return { width: 750, height: 250 };
  };

  return (
    <Box width={width().width} margin={"auto"}>
      <Carousel
        next={() => {
          /* Do stuff */
        }}
        prev={() => {
          /* Do other stuff */
        }}
        autoPlay
        interval={10000}
        animation="slide"
        duration={1000}
        swipe
      >
        {list?.map((item, index) => (
          <Box key={index}>
            <Link to={item?.link} target="_blank">
              <img
                src={item?.photo}
                width={width().width}
                height={width().height}
                alt={"banner"}
              />
            </Link>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
}
