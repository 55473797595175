import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Switch,
} from "@mui/material";
import { BreakpointBox, CircularLoading, ConfirmButtons } from "../../Atoms";
import { CustomModal, DropImageZone } from "../../Organisms";
import { CustomButton } from "../Buttons";
import { GenericInput } from "../GenericInput";
import { GenericSelect } from "../GenericSelect";

import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { CustomDatePicker } from "../DatePicker";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import EditIcon from "@mui/icons-material/Edit";
import { useFormik } from "formik";
import { validationSchema } from "./validationSchema";
import { OptionsModal, SelectOptionsModal } from "../../../../pages";
import { useEffect, useMemo, useState } from "react";
import {
  createManufacturer,
  createOrigin,
  createProductType,
  createVersion,
  getProductOptionsService,
  updateManufacturer,
  updateOrigin,
  updateProductType,
  updateVersion,
} from "../../../../services/productsService";
import useProducts from "../../../../hooks/context/useProducts";
import { SimpleConfirmModal } from "../SimpleConfirmModal";
import { Link } from "react-router-dom";
import { HtmlTextEditor } from "../ReactQuillHtmlEditor";

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;

  productID?: number;
}

const regexNumber = new RegExp("^[0-9]*$");

export function ModalProductEditCreate({ open, setOpen, productID }: Props) {
  const {
    submitProduct,
    product,
    loading,
    productRemoveImage,
    updateProduct,
    setHandleIDProduct,
    setProduct,
  } = useProducts();
  const [cleanFileState, setCleanFileState] = useState(false);
  const [openDelImage, setOpenDelImage] = useState({
    open: false,
    imageID: null,
  });
  const { isSmalldesktop } = useBreakpoints();

  const closeModal = () => {
    setOpen(false);
    formik.resetForm();
    setProduct({});
    setCleanFileState((old) => !old);
    setHandleIDProduct(0);
  };

  const formik = useFormik<SubmitProduct>({
    initialValues: {
      characterName: "",
      description: "",
      price: 0,
      stock: 0,
      weight: 0,
      isSale: false,
      isPreOrder: false,
      isPreOwned: false,
      preOrderDeadline: new Date(),
      canGoSmallPacket: true,

      file: [],

      originName: "",
      manufacturerName: "",
      versionName: "",
      productTypeName: "",

      originId: 0,
      versionsId: 0,
      manufacturersId: 0,
      productTypeId: 0,
    },
    validationSchema,

    async onSubmit(values) {
      try {
        if (product?.id) {
          updateProduct({ ...values, productID: product?.id });
          closeModal();
        } else {
          submitProduct(values);
          closeModal();
        }
      } catch {}
    },
  });

  useEffect(() => {
    if (product?.id) {
      formik.setFieldValue("characterName", product?.characterName);
      formik.setFieldValue("description", product?.description);
      formik.setFieldValue("price", product?.price);
      formik.setFieldValue("stock", product?.stock);

      formik.setFieldValue("weight", product?.weight);
      formik.setFieldValue("isSale", product?.isSale);
      formik.setFieldValue("canGoSmallPacket", product?.canGoSmallPacket);
      formik.setFieldValue("isPreOrder", product?.isPreOrder);
      formik.setFieldValue("isPreOwned", product?.isPreOwned);
      formik.setFieldValue(
        "preOrderDeadline",
        new Date(product?.preOrderDeadline)
      );

      formik.setFieldValue("originName", product?.originName);
      formik.setFieldValue("manufacturerName", product?.manufacturerName);
      formik.setFieldValue("versionName", product?.versionName);
      formik.setFieldValue("productTypeName", product?.productTypeName);

      formik.setFieldValue("originId", product?.originId);
      formik.setFieldValue("versionsId", product?.versionsId);
      formik.setFieldValue("manufacturersId", product?.manufacturersId);
      formik.setFieldValue("productTypeId", product?.productTypeId);
    }

    // eslint-disable-next-line
  }, [product?.id]);

  const [openOptionsModal, setOpenOptionsModal] = useState({
    open: false,
    name: "",
    type: "versions",
    operation: "create",
  } as OptionsModal);
  const [selectedOptionToUpdate, setSelectedOptionToUpdate] = useState({
    id: 0,
    value: "",
  } as SelectOptionsModal);
  const [productOptionsList, setProductOptionsList] = useState(
    {} as ProductOptions
  );

  const productOptionsListMemo = useMemo(
    () => productOptionsList,
    [productOptionsList]
  ) as ProductOptions;

  const getProductOptions = async () => {
    try {
      const response = await getProductOptionsService();
      setProductOptionsList(response);
    } catch {}
  };

  useEffect(() => {
    getProductOptions();
  }, []);

  const handleCreateNewOption = async () => {
    try {
      if (openOptionsModal.operation === "update") {
        switch (openOptionsModal.type) {
          case "versions":
            await updateVersion({
              name: openOptionsModal.name,
              versionID: selectedOptionToUpdate.id,
            });
            setOpenOptionsModal((old) => ({ ...old, open: false }));
            getProductOptions();
            break;
          case "manufacturers":
            await updateManufacturer({
              name: openOptionsModal.name,
              manufacturerID: selectedOptionToUpdate.id,
            });
            setOpenOptionsModal((old) => ({ ...old, open: false }));
            getProductOptions();
            break;
          case "origins":
            await updateOrigin({
              name: openOptionsModal.name,
              originID: selectedOptionToUpdate.id,
            });
            setOpenOptionsModal((old) => ({ ...old, open: false }));
            getProductOptions();
            break;

          case "productTypes":
            await updateProductType({
              name: openOptionsModal.name,
              productTypeID: selectedOptionToUpdate.id,
            });
            setOpenOptionsModal((old) => ({ ...old, open: false }));
            getProductOptions();
            break;
        }
      } else {
        switch (openOptionsModal.type) {
          case "versions":
            await createVersion(openOptionsModal.name);
            setOpenOptionsModal((old) => ({ ...old, open: false }));
            getProductOptions();
            break;
          case "manufacturers":
            await createManufacturer(openOptionsModal.name);
            setOpenOptionsModal((old) => ({ ...old, open: false }));
            getProductOptions();
            break;
          case "origins":
            await createOrigin(openOptionsModal.name);
            setOpenOptionsModal((old) => ({ ...old, open: false }));
            getProductOptions();
            break;
          case "productTypes":
            await createProductType(openOptionsModal.name);
            setOpenOptionsModal((old) => ({ ...old, open: false }));
            getProductOptions();
            break;
        }
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <>
      <CustomModal
        openModal={open}
        setOpenModal={() => {
          closeModal();
        }}
        width={isSmalldesktop ? "90%" : "30%"}
        disableBackdropClick
        title={product?.id ? "Update product" : "Create new product"}
      >
        {loading.getOneProduct ? (
          <CircularLoading />
        ) : (
          <>
            {product?.id && (
              <Link
                to={`/details?product=${product?.id}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <Button sx={{ mt: 1, mb: 1 }} fullWidth variant="outlined">
                  Click here to open product details in a new tab
                </Button>
              </Link>
            )}
            <GenericInput
              hasIcon={false}
              onChange={(value, name) => formik.setFieldValue(name, value)}
              error={
                formik?.touched?.characterName && formik?.errors?.characterName
                  ? true
                  : false
              }
              errorMessage={
                formik?.touched?.characterName
                  ? formik?.errors?.characterName
                  : ""
              }
              value={formik?.values?.characterName}
              placeholder={"Product name"}
              inputName="characterName"
            />

            <Box width={"100%%"} px={1} mb={isSmalldesktop ? 9 : 7}>
              <HtmlTextEditor
                title="Description"
                onChange={(value) => formik.setFieldValue("description", value)}
                value={formik?.values?.description}
                error={
                  formik?.touched?.description && formik?.errors?.description
                    ? true
                    : false
                }
                errorMessage={
                  formik?.touched?.description
                    ? formik?.errors?.description
                    : ""
                }
              />
            </Box>

            <BreakpointBox>
              <GenericInput
                endAdornment={"￥"}
                hasIcon={false}
                onChange={(value, name) => {
                  if (value.match(regexNumber)) {
                    formik.setFieldValue(name, Number(value));
                  }
                }}
                error={
                  formik?.touched?.price && formik?.errors?.price ? true : false
                }
                errorMessage={
                  formik?.touched?.price ? formik?.errors?.price : ""
                }
                value={formik?.values?.price}
                placeholder={"Price"}
                inputName="price"
              />

              <GenericInput
                hasIcon={false}
                onChange={(value, name) => {
                  if (value.match(regexNumber)) {
                    formik.setFieldValue(name, Number(value));
                  }
                }}
                error={
                  formik?.touched?.stock && formik?.errors?.stock ? true : false
                }
                errorMessage={
                  formik?.touched?.stock ? formik?.errors?.stock : ""
                }
                value={formik?.values?.stock}
                placeholder={"Stock"}
                inputName="stock"
              />
            </BreakpointBox>

            <GenericInput
              endAdornment={"g"}
              hasIcon={false}
              onChange={(value, name) => {
                if (value.match(regexNumber)) {
                  formik.setFieldValue(name, Number(value));
                }
              }}
              error={
                formik?.touched?.weight && formik?.errors?.weight ? true : false
              }
              errorMessage={
                formik?.touched?.weight ? formik?.errors?.weight : ""
              }
              value={formik?.values?.weight}
              placeholder={"Box weight"}
              inputName="weight"
            />

            <BreakpointBox>
              <Box width={"100%"} sx={{ p: 2, ml: isSmalldesktop ? 5 : 0 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.canGoSmallPacket}
                      size={"small"}
                      color="warning"
                      onChange={(_e, check) =>
                        formik.setFieldValue("canGoSmallPacket", check)
                      }
                    />
                  }
                  label="Can go by Small Packet"
                />
              </Box>
              <Box width={"100%"} sx={{ p: 2, ml: isSmalldesktop ? 5 : 0 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.isSale}
                      size={"small"}
                      color="warning"
                      onChange={(_e, check) =>
                        formik.setFieldValue("isSale", check)
                      }
                    />
                  }
                  label="Is sale"
                />
              </Box>
            </BreakpointBox>
            <BreakpointBox>
              <Box width={"100%"} sx={{ p: 2, ml: isSmalldesktop ? 5 : 0 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.isPreOwned}
                      size={"small"}
                      color="warning"
                      onChange={(_e, check) =>
                        formik.setFieldValue("isPreOwned", check)
                      }
                    />
                  }
                  label="Is Pre-owned"
                />
              </Box>
              <Box width={"100%"} sx={{ p: 2, ml: isSmalldesktop ? 5 : 0 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={formik.values.isPreOrder}
                      size={"small"}
                      color="warning"
                      onChange={(_e, check) =>
                        formik.setFieldValue("isPreOrder", check)
                      }
                    />
                  }
                  label="Is pre-order"
                />
              </Box>
            </BreakpointBox>

            {formik.values.isPreOrder && (
              <CustomDatePicker
                onChange={(date) =>
                  formik.setFieldValue("preOrderDeadline", date)
                }
                value={formik.values.preOrderDeadline}
              />
            )}
            <Box display={"flex"}>
              <GenericSelect
                formWidth="98%"
                onChange={(value) => {
                  const findName = productOptionsListMemo?.origins?.find(
                    (item) => item.name === value
                  );
                  formik.setFieldValue("originName", findName?.name);
                  formik.setFieldValue("originId", findName?.id);
                }}
                error={
                  formik?.touched?.originName && formik?.errors?.originName
                    ? true
                    : false
                }
                errorMessage={
                  formik?.touched?.originName ? formik?.errors?.originName : ""
                }
                value={formik?.values?.originName}
                placeholder={"Origin"}
                name="originName"
                options={productOptionsListMemo?.origins?.map(({ name }) => ({
                  label: name,
                  value: name,
                }))}
              />
              <IconButton
                onClick={() =>
                  setOpenOptionsModal({
                    name: "",
                    open: true,
                    type: "origins",
                    operation: "create",
                  })
                }
                sx={{ width: 60, height: 60 }}
              >
                <FiberNewIcon style={{ fill: "green", fontSize: 35 }} />
              </IconButton>

              <IconButton
                onClick={() =>
                  setOpenOptionsModal({
                    name: "",
                    open: true,
                    type: "origins",
                    operation: "update",
                  })
                }
                sx={{ width: 60, height: 60 }}
              >
                <EditIcon style={{ fill: "purple", fontSize: 35 }} />
              </IconButton>
            </Box>
            <Box display={"flex"}>
              <GenericSelect
                formWidth="98%"
                onChange={(value) => {
                  const findName = productOptionsListMemo?.manufacturers?.find(
                    (item) => item.name === value
                  );
                  formik.setFieldValue("manufacturerName", findName?.name);
                  formik.setFieldValue("manufacturersId", findName?.id);
                }}
                error={
                  formik?.touched?.manufacturerName &&
                  formik?.errors?.manufacturerName
                    ? true
                    : false
                }
                errorMessage={
                  formik?.touched?.manufacturerName
                    ? formik?.errors?.manufacturerName
                    : ""
                }
                value={formik?.values?.manufacturerName}
                placeholder={"Manufacturer"}
                name="manufacturerName"
                options={productOptionsListMemo?.manufacturers?.map(
                  ({ name }) => ({
                    label: name,
                    value: name,
                  })
                )}
              />
              <IconButton
                onClick={() =>
                  setOpenOptionsModal({
                    name: "",
                    open: true,
                    type: "manufacturers",
                    operation: "create",
                  })
                }
                sx={{ width: 60, height: 60 }}
              >
                <FiberNewIcon style={{ fill: "green", fontSize: 35 }} />
              </IconButton>

              <IconButton
                onClick={() =>
                  setOpenOptionsModal({
                    name: "",
                    open: true,
                    type: "manufacturers",
                    operation: "update",
                  })
                }
                sx={{ width: 60, height: 60 }}
              >
                <EditIcon style={{ fill: "purple", fontSize: 35 }} />
              </IconButton>
            </Box>

            <Box display={"flex"}>
              <GenericSelect
                formWidth="98%"
                onChange={(value) => {
                  const findName = productOptionsListMemo?.versions?.find(
                    (item) => item.name === value
                  );
                  formik.setFieldValue("versionName", findName?.name);
                  formik.setFieldValue("versionsId", findName?.id);
                }}
                error={
                  formik?.touched?.versionName && formik?.errors?.versionName
                    ? true
                    : false
                }
                errorMessage={
                  formik?.touched?.versionName
                    ? formik?.errors?.versionName
                    : ""
                }
                value={formik?.values?.versionName}
                placeholder={"Version"}
                name="versionName"
                options={productOptionsListMemo?.versions?.map(({ name }) => ({
                  label: name,
                  value: name,
                }))}
              />

              <IconButton
                onClick={() =>
                  setOpenOptionsModal({
                    name: "",
                    open: true,
                    type: "versions",
                    operation: "create",
                  })
                }
                sx={{ width: 60, height: 60 }}
              >
                <FiberNewIcon style={{ fill: "green", fontSize: 35 }} />
              </IconButton>

              <IconButton
                onClick={() =>
                  setOpenOptionsModal({
                    name: "",
                    open: true,
                    type: "versions",
                    operation: "update",
                  })
                }
                sx={{ width: 60, height: 60 }}
              >
                <EditIcon style={{ fill: "purple", fontSize: 35 }} />
              </IconButton>
            </Box>

            <Box display={"flex"}>
              <GenericSelect
                formWidth="98%"
                onChange={(value) => {
                  const findName = productOptionsListMemo?.productTypes?.find(
                    (item) => item.name === value
                  );
                  formik.setFieldValue("productTypeName", findName?.name);
                  formik.setFieldValue("productTypeId", findName?.id);
                }}
                error={
                  formik?.touched?.productTypeName &&
                  formik?.errors?.productTypeName
                    ? true
                    : false
                }
                errorMessage={
                  formik?.touched?.productTypeName
                    ? formik?.errors?.productTypeName
                    : ""
                }
                value={formik?.values?.productTypeName}
                placeholder={"Product type"}
                name="productTypeName"
                options={productOptionsListMemo?.productTypes?.map(
                  ({ name }) => ({
                    label: name,
                    value: name,
                  })
                )}
              />

              <IconButton
                onClick={() =>
                  setOpenOptionsModal({
                    name: "",
                    open: true,
                    type: "productTypes",
                    operation: "create",
                  })
                }
                sx={{ width: 60, height: 60 }}
              >
                <FiberNewIcon style={{ fill: "green", fontSize: 35 }} />
              </IconButton>

              <IconButton
                onClick={() =>
                  setOpenOptionsModal({
                    name: "",
                    open: true,
                    type: "productTypes",
                    operation: "update",
                  })
                }
                sx={{ width: 60, height: 60 }}
              >
                <EditIcon style={{ fill: "purple", fontSize: 35 }} />
              </IconButton>
            </Box>

            <DropImageZone
              oldFiles={product?.productsImages}
              removeFileAdmin={(imageID) =>
                setOpenDelImage({ imageID, open: true })
              }
              handleChangeFiles={(images) =>
                formik.setFieldValue("file", images)
              }
              cleanFileState={cleanFileState}
            />
            {formik?.touched?.file && formik?.errors?.file && (
              <FormHelperText sx={{ textAlign: "center", mb: 3 }} error>
                Product images is required
              </FormHelperText>
            )}

            <CustomButton
              btnType="login"
              disabled={
                product?.id
                  ? false
                  : formik.values.file.length === 0
                  ? true
                  : false
              }
              onClick={() => formik.submitForm()}
            >
              {product?.id ? "Update" : "Create"}
            </CustomButton>
          </>
        )}
      </CustomModal>

      <CustomModal
        openModal={openOptionsModal.open}
        setOpenModal={() => {
          setOpenOptionsModal((old) => ({ ...old, open: false }));
          setSelectedOptionToUpdate({ id: 0, value: "" });
        }}
        width={isSmalldesktop ? "90%" : "30%"}
        disableBackdropClick
        title={
          openOptionsModal.operation === "create"
            ? `Create new ${openOptionsModal.type}`
            : `Update ${openOptionsModal.type}`
        }
      >
        {openOptionsModal.operation === "create" && (
          <GenericInput
            hasIcon={false}
            onChange={(value) => {
              setOpenOptionsModal((old) => ({ ...old, name: value }));
            }}
            value={openOptionsModal.name}
            placeholder={"Name"}
            inputName=""
          />
        )}

        {openOptionsModal.operation === "update" && (
          <>
            <GenericSelect
              formWidth="98%"
              onChange={(value) => {
                const findName = productOptionsListMemo[
                  openOptionsModal.type
                ]?.find((item) => item.name === value);
                setSelectedOptionToUpdate({ id: findName?.id, value });
              }}
              value={selectedOptionToUpdate.value}
              placeholder={openOptionsModal.type}
              name="originName"
              options={productOptionsListMemo[openOptionsModal.type]?.map(
                ({ name, id }) => ({
                  label: name,
                  value: name,
                })
              )}
            />
            <GenericInput
              hasIcon={false}
              onChange={(value) => {
                setOpenOptionsModal((old) => ({ ...old, name: value }));
              }}
              value={openOptionsModal.name}
              placeholder={"New name"}
              inputName=""
            />
          </>
        )}

        <ConfirmButtons
          confirmClick={handleCreateNewOption}
          cancelClick={() => {
            setOpenOptionsModal((old) => ({ ...old, open: false }));
            setSelectedOptionToUpdate({ id: 0, value: "" });
          }}
        />
      </CustomModal>

      <SimpleConfirmModal
        open={openDelImage.open}
        cancelClick={() => setOpenDelImage({ imageID: null, open: false })}
        confirmClick={async () => {
          await productRemoveImage(openDelImage.imageID);
          setOpenDelImage({ imageID: null, open: false });
        }}
        text="Do you really want to delete this image? The image will be deleted now!"
      />
    </>
  );
}
