import { Box, Typography } from "@mui/material";

import { useBreakpoints } from "../../../../../hooks/useBreakpoins";

import { CircularLoading } from "../../../../../components/AtomicDesign/Atoms";

import { ProductCard } from "./ProductCard";

interface Props {
  list: PersonalStorageToShip[];
  selectedList: PersonalStorageToShip[];
  setSelectedList: (item: PersonalStorageToShip[]) => void;
  isLoading: boolean;
}

export function ProductToShip({
  list,
  selectedList,
  setSelectedList,
  isLoading,
}: Props) {
  const { isSmalldesktop } = useBreakpoints();

  const selectPrd = (item: PersonalStorageToShip, remove?: boolean) => {
    const find = selectedList.find((prd) => prd.id === item.id);

    if (find) {
      if (remove) {
        const filter = selectedList.filter((prd) => prd.id !== item.id);
        return setSelectedList(filter);
      }
      // const index = selectedList.findIndex((prd) => prd.id === item.id);

      // const newList = selectedList;
      // newList[index] = item;
      const filter = selectedList.filter((prd) => prd.id !== item.id);
      setSelectedList([...filter, item]);
    } else {
      setSelectedList([...selectedList, item]);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent={isSmalldesktop ? "center" : "flex-start"}
      flexWrap={"wrap"}
      alignItems={"center"}
      width={"100%"}
      mt={2}
      gap={1}
      p={1}
    >
      {isLoading ? (
        <CircularLoading />
      ) : (
        <Box width={"98%"}>
          {list?.length > 0 && (
            <Typography textAlign={"center"} mb={2}>
              Select your products to ship
            </Typography>
          )}
          <Box
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent={"center"}
            sx={{ overflowX: "auto" }}
            width={"100%"}
            gap={1}
          >
            {list &&
              list.length > 0 &&
              list.map((prd, index) => (
                <ProductCard
                  key={index}
                  inputDisabled={prd?.isPreOrder && !prd?.preOrderPaid}
                  index={index}
                  onClick={(product, remove) => selectPrd(product, remove)}
                  prd={prd}
                  hasHighlight
                  isSelected={Boolean(
                    selectedList.find((item) => item.id === prd.id)
                  )}
                />
              ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}
