import { Box, Button, Pagination, TextField, Typography } from "@mui/material";
import { colors } from "../../../../theme/colors";
import { red } from "@mui/material/colors";
import { FadeInContainer } from "../../../../components/AtomicDesign/Atoms";
import { useEffect, useState } from "react";
import { ShipCards } from "../../../../components/AtomicDesign/Organisms";
import useShippments from "../../../../hooks/context/useShippments";
import { ShippmentDetails } from "./components/Details";
import { CreateShippmentModal } from "./components/CreateShippmetModal";
import useAuth from "../../../../hooks/context/useAuth";
import {
  CustomButton,
  GenericSelect,
} from "../../../../components/AtomicDesign/Molecules";
import SearchIcon from "@mui/icons-material/Search";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";

const regexNumber = new RegExp("^[0-9]*$");

const statusOptionsList = [
  { label: "Shipment requested", value: "deliverieRequested" },
  { label: "Waiting payment", value: "waitingPayment" },
  { label: "Payment sent", value: "paymentSent" },
  { label: "Shipped", value: "shipped" },
  { label: "Edit address", value: "editDeliveryAddress" },
  { label: "Edit products", value: "editDeliveryProducts" },
  { label: "Canceled", value: "canceled" },
  { label: "Canceled with refund", value: "cancelWithRefund" },
];

export function Shippments() {
  const {
    shippmentsDataMemo,
    getPersonalStorageForShip,
    verifyPayment,
    shippmentsRecords,
    setFilters,
  } = useShippments();
  const { user } = useAuth();
  const { isSmalldesktop } = useBreakpoints();
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [text, setText] = useState("");
  const [statusOption, setStatusOption] = useState(null);
  const [seconds, setSeconds] = useState(5);
  //details
  const [selectedShip, setSelectedShip] = useState<ShippmentData | null>();
  const [openDetails, setOpenDetails] = useState(false);
  // details

  useEffect(() => {
    if (modalOpen && seconds === 0) {
      setTimeout(() => {
        setSeconds(0);
      }, 5000);

      getPersonalStorageForShip();
    }

    // eslint-disable-next-line
  }, [modalOpen]);

  useEffect(() => {
    if (openDetails) {
      const find = shippmentsDataMemo.find(
        (ship) => selectedShip?.id === ship?.id
      );
      setSelectedShip(null);
      setOpenDetails(false);
      setSelectedShip(find);
      setOpenDetails(true);
    }
    // eslint-disable-next-line
  }, [shippmentsDataMemo]);

  const cleanSearch = () => {
    setFilters((old: any) => ({
      ...old,
      shippmentID: null,
    }));
    setText("");
  };

  const isAdmin = user?.user?.role === "admin";

  return (
    <Box
      width={"100%"}
      minHeight={"480px"}
      display="flex"
      flexWrap={"wrap"}
      rowGap={2}
      justifyContent={"space-evenly"}
    >
      <Box borderRadius={"10px 0px 10px 10px"} width={"100%"}>
        <Box
          borderBottom={"1px solid lightgray"}
          p={2}
          bgcolor={colors.blue[900]}
          borderRadius={"10px 0px 10px 10px"}
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography color={"#FFFFFF"} fontSize={18}>
            {user?.user?.role === "client" ? "My shippments" : "Shipments"}
          </Typography>
          {user?.user?.role === "client" && (
            <Button
              sx={{
                bgcolor: red[500],
                color: "white",
                "&:hover": { bgcolor: red["A400"] },
              }}
              onClick={() => setModalOpen(true)}
            >
              New shippment
            </Button>
          )}
        </Box>
      </Box>
      <Box width={"100%"}>
        <FadeInContainer>
          {isAdmin && (
            <Box
              display={"flex"}
              gap={isSmalldesktop ? 0 : 2}
              flexWrap={"wrap"}
              width={"100%"}
            >
              <TextField
                sx={{
                  background: "#FFF",
                  mb: isSmalldesktop ? 0 : 2,
                  ml: 2,
                  width: isSmalldesktop ? "90%" : "auto",
                }}
                label="Shipment number"
                value={text}
                onChange={(e) => {
                  if (e?.target?.value.match(regexNumber)) {
                    setText(e.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {text && (
                        <CustomButton
                          btnType="delete"
                          onClick={cleanSearch}
                          style={{ marginRight: 5 }}
                        />
                      )}
                      <CustomButton
                        btnType="search"
                        onClick={() => {
                          setFilters((old: any) => ({
                            ...old,
                            shippmentID: text,
                          }));
                        }}
                      >
                        <SearchIcon />
                      </CustomButton>
                    </>
                  ),
                }}
              />

              <Box display={"flex"} width={isSmalldesktop ? "100%" : "350px"}>
                <GenericSelect
                  formWidth={"90%"}
                  onChange={(value) => {
                    setStatusOption(value);
                    setFilters((old: any) => ({
                      ...old,
                      status: value,
                    }));
                  }}
                  value={statusOption}
                  placeholder={"Status"}
                  name="statusOption"
                  options={statusOptionsList}
                />
                {statusOption && (
                  <CustomButton
                    btnType="delete"
                    onClick={() => {
                      setStatusOption(null);
                      setFilters((old: any) => ({
                        ...old,
                        status: null,
                      }));
                    }}
                    style={{
                      marginLeft: 2,
                      marginRight: 20,
                      height: 36,
                      width: 36,
                      marginTop: 12,
                    }}
                  />
                )}
              </Box>
            </Box>
          )}
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box display={"flex"} justifyContent={"center"} width={"95%"}>
              <ShipCards
                list={shippmentsDataMemo}
                setSelectedShip={(ship) => {
                  setSelectedShip(ship);
                  setOpenDetails(true);
                }}
                verifyPayment={verifyPayment}
              />
            </Box>
          </Box>
        </FadeInContainer>
      </Box>

      <Box display={"flex"} justifyContent={"center"} width={"100%"} mt={2}>
        <Pagination
          count={shippmentsRecords}
          page={page}
          defaultPage={1}
          onChange={(_e, page) => {
            setFilters((old) => ({ ...old, offset: page - 1 }));
            setPage(page);
          }}
        />
      </Box>

      <ShippmentDetails
        open={openDetails}
        onClose={() => {
          setOpenDetails(false);
          setSelectedShip(null);
        }}
        selectedShip={selectedShip}
      />

      <CreateShippmentModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </Box>
  );
}
