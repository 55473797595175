import { useEffect, useState } from "react";
import useAuth from "../../../hooks/context/useAuth";
import {
  createServiceMessage,
  getServiceMessages,
  markAsRead,
} from "../../../services/userServices";
import { MessageBox } from "../../../components/AtomicDesign/Molecules";

export function ServiceMessages() {
  const { user } = useAuth();
  const [messageList, setMessageList] = useState<IMessage[]>();

  const getMessages = async () => {
    try {
      const response = await getServiceMessages(user?.user?.id);
      setMessageList(response);
    } catch {}
  };

  useEffect(() => {
    if (user?.user?.id) {
      getMessages();
    }
    // eslint-disable-next-line
  }, [user]);

  const readMessages = async () => {
    try {
      await markAsRead({ type: "admin", userID: user?.user?.id });
    } catch {
    } finally {
      getMessages();
    }
  };

  useEffect(() => {
    if (
      user &&
      messageList?.length > 0 &&
      messageList[0]?.type === "admin" &&
      !messageList[0]?.read
    ) {
      readMessages();
    }
    // eslint-disable-next-line
  }, [messageList]);

  const sendNewMessage = async (description: string) => {
    try {
      await createServiceMessage({
        description,
        person: user?.user?.name,
        type: "client",
        userID: user?.user?.id,
      });
    } catch {
    } finally {
      getMessages();
    }
  };
  return (
    <MessageBox
      data={messageList}
      sendMessage={(message) => sendNewMessage(message)}
    />
  );
}
