import SupportAgentIcon from "@mui/icons-material/SupportAgent";

import useAuth from "../../../../hooks/context/useAuth";
import { useEffect, useState } from "react";
import {
  verifyServiceMessages,
  verifyServiceMessagesAdmin,
} from "../../../../services/userServices";
import { red } from "@mui/material/colors";
import { Box, Tooltip } from "@mui/material";

export function WarningMessage() {
  const { user } = useAuth();

  const [hasMessage, setHasMessage] = useState(false);
  const isAdmin = user?.user?.role === "admin";
  const [seconds, setSeconds] = useState(0);

  const verifyMessage = async () => {
    try {
      let response = false;
      if (isAdmin) {
        response = await verifyServiceMessagesAdmin();
      } else {
        response = await verifyServiceMessages(user?.user?.id);
      }
      setHasMessage(response);
    } catch {}
  };

  const notShow = ["/user/messages", "/admin/messages"];

  useEffect(() => {
    if (user?.user?.id && seconds === 0) {
      verifyMessage();
    }
    let interval = undefined;
    if (user) {
      interval = setInterval(
        () =>
          setSeconds((old) => {
            if (seconds <= 0) return 0;
            else return old - 1;
          }),
        1000
      );
      if (seconds === 0) {
        setSeconds(isAdmin ? 300 : 1800);
      }
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [user, seconds, window.location.pathname]);

  useEffect(() => {
    if (isAdmin) setSeconds(300);
    else setSeconds(1800);
    // eslint-disable-next-line
  }, [user]);

  return (
    hasMessage &&
    !notShow.includes(window.location.pathname) && (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          right: 0,
          background: red[500],
          p: 1,
          cursor: "pointer",
        }}
      >
        <Tooltip title="You have new messages, click here">
          <SupportAgentIcon
            sx={{
              fill: "#FFFFFF",
              fontSize: 30,
            }}
            onClick={() => {
              if (isAdmin) {
                localStorage.setItem("@treasure_box_admintab", String(4));
                window.location.replace("/admin/messages");
              } else {
                window.location.replace("/user/messages");
              }
            }}
          />
        </Tooltip>
      </Box>
    )
  );
}
