import { ButtonProps, Button as MaterialButton } from "@mui/material";
import { amber, lightBlue, red } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props extends ButtonProps {
  btnType?: "login" | "register" | "search" | "delete" | "share";
  text?: string;
}

export function CustomButton({ btnType, text, children, ...rest }: Props) {
  switch (btnType) {
    case "delete":
      return (
        <MaterialButton
          {...rest}
          variant={"contained"}
          sx={{
            bgcolor: red["A700"],
            "&:hover": { bgcolor: red[100] },
            color: "white",
          }}
        >
          <DeleteIcon />
        </MaterialButton>
      );
    case "search":
      return (
        <MaterialButton
          {...rest}
          variant={"contained"}
          sx={{ bgcolor: "#ed2623", "&:hover": { bgcolor: "#ed262380" } }}
        >
          {text ? text : children}
        </MaterialButton>
      );

    case "login":
      return (
        <MaterialButton
          variant={"contained"}
          fullWidth
          {...rest}
          sx={{ bgcolor: "#ed2623", "&:hover": { bgcolor: "#ed262380" } }}
        >
          {text ? text : children}
        </MaterialButton>
      );

    case "register":
      return (
        <MaterialButton
          {...rest}
          variant={"contained"}
          fullWidth
          sx={{
            bgcolor: lightBlue["A700"],
            "&: hover": { bgcolor: lightBlue[300] },
          }}
        >
          {text ? text : children}
        </MaterialButton>
      );

    case "share":
      return (
        <MaterialButton
          variant={"contained"}
          fullWidth
          {...rest}
          sx={{
            bgcolor: amber["A700"],
            "&: hover": { bgcolor: amber[300] },
          }}
        >
          {text ? text : children}
        </MaterialButton>
      );

    default:
      return (
        <MaterialButton {...rest} fullWidth>
          {text ? text : children}
        </MaterialButton>
      );
  }
}
