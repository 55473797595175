import { CircularProgress, Tab, Tabs } from "@mui/material";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";
import { CustomModal } from "../../../../../components/AtomicDesign/Organisms";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useEffect, useState } from "react";
import { SelectProduct } from "../Steps/SelectProduct";
import { SelectAddress } from "../Steps/Address";
import { ResumeShip } from "../Steps/ResumeShip";
import useShippments from "../../../../../hooks/context/useShippments";
import useAuth from "../../../../../hooks/context/useAuth";

const tabButtons = { minHeight: "50px", height: "50px" };

interface Props {
  open: boolean;
  onClose: VoidFunction;
  selectedShip: ShippmentData | null;
}

export function NewProductsToShippment({ open, onClose, selectedShip }: Props) {
  const {
    personalStorageToShipDataMemo,
    loading,
    getPersonalStorageForShip,
    updateShippmentProducts,
  } = useShippments();

  const { isSmalldesktop } = useBreakpoints();
  const { user } = useAuth();
  const [tabStep, setTabStep] = useState(1);
  const [selectedListADD, setSelectedListADD] = useState(
    [] as PersonalStorageToShip[]
  );
  const [selectedListREMOVE, setSelectedListREMOVE] = useState(
    [] as PersonalStorageToShip[]
  );
  const [selectedAddress, setSelectedAddress] = useState({} as IAddress);
  const [selectedShipType, setSelectedShipType] = useState({
    shipPrice: 0,
    selectedShipType: "",
  } as SelectedShipType);

  const closeModal = () => {
    setSelectedListADD([]);
    setSelectedAddress({} as IAddress);
    setSelectedShipType({} as SelectedShipType);
    setTabStep(1);
    onClose();
  };

  const handleUpdateShippment = async () => {
    const payload = {
      personalStorageItensIDADD: selectedListADD.map((item) => ({
        personalItemID: item?.id,
        quantity: item?.quantityToShip,
        personalStock: item.quantity,
      })),
      personalStorageItensIDREMOVE: selectedListREMOVE.map((item) => ({
        personalItemID: item?.id,
        quantity: item?.quantityToShip,
        personalStock: item.quantity,
      })),
      userID: user?.user?.id,
      addressID: selectedAddress?.id,
      shippingCost: selectedShipType.shipPrice,
      shipType: selectedShipType.selectedShipType,
      shippmentID: selectedShip?.id,
    };

    await updateShippmentProducts(payload);

    closeModal();
  };

  useEffect(() => {
    if (open) {
      getPersonalStorageForShip();
    }
    // eslint-disable-next-line
  }, [open]);

  //   ver se valor ta sendo multiplicado pela quantidade

  const sumPrds = () => {
    const newPrds = selectedListADD?.reduce(
      (acc, next) => acc + next?.Products?.weight * next?.quantity,
      0
    );

    const oldPrds = selectedShip?.shippmentXPersonalStorage?.reduce(
      (acc, next) =>
        acc + next?.PersonalStorageXProduct?.Products?.weight * next?.quantity,
      0
    );

    const sumSelected = newPrds === 0 ? oldPrds : newPrds + oldPrds;

    const sub = Math.abs(
      sumSelected -
        selectedListREMOVE?.reduce(
          (acc, next) => acc + next?.Products?.weight * next?.quantityToShip,
          0
        )
    );

    return sub;
  };

  const canGoBySmallPacket = () => {
    const canGo = selectedShip?.shippmentXPersonalStorage?.some(
      (prd) => !prd?.PersonalStorageXProduct?.Products?.canGoSmallPacket
    );

    const newPrds = selectedListADD?.some(
      (prd) => !prd?.Products?.canGoSmallPacket,
      0
    );

    if (canGo || newPrds) return false;
    return true;
  };

  return (
    <CustomModal
      openModal={open}
      setOpenModal={onClose}
      width={isSmalldesktop ? "90%" : "70%"}
    >
      {loading.createShippment ? (
        <CircularProgress />
      ) : (
        <>
          <Tabs
            value={tabStep}
            variant="fullWidth"
            sx={{
              "& .Mui-selected": {
                borderRadius: "5px 5px 0 0",
                background: "#FFFFFF",
              },
              "& .MuiTab-root": {
                fontSize: 12,

                "&: hover": {
                  borderRadius: "5px 5px 0 0",
                },
                pointerEvents: "none",
              },
            }}
          >
            <Tab
              icon={<CardGiftcardIcon />}
              iconPosition="start"
              value={1}
              label="Select products"
              sx={tabButtons}
            />
            <Tab
              sx={tabButtons}
              value={2}
              label="Select address"
              icon={<ImportContactsIcon />}
              iconPosition="start"
            />
            <Tab
              sx={tabButtons}
              value={3}
              label="Summary of your submission"
              icon={<EditNoteIcon />}
              iconPosition="start"
            />
          </Tabs>
          {tabStep === 1 && (
            <SelectProduct
              isLoading={loading.personalStorage}
              personalStorageToShipDataMemo={personalStorageToShipDataMemo}
              selectedList={selectedListADD}
              setSelectedList={setSelectedListADD}
              onClickNext={() => setTabStep(2)}
              hasRemove
              onClickRemove={(prds) => setSelectedListREMOVE(prds)}
              removedList={selectedListREMOVE}
              shippmentData={selectedShip}
            />
          )}

          {tabStep === 2 && (
            <SelectAddress
              setSelectedShipType={setSelectedShipType}
              selectedShipType={selectedShipType}
              selectedAddress={selectedAddress}
              totalProductsWeight={sumPrds()}
              onClickNext={() => setTabStep(3)}
              onClicBack={() => setTabStep(1)}
              setSelectedAddress={(address) => setSelectedAddress(address)}
              canGoSmallPacket={canGoBySmallPacket()}
            />
          )}
          {tabStep === 3 && (
            <ResumeShip
              canGoBySmallPacket={canGoBySmallPacket()}
              selectedList={selectedListADD}
              selectedShipType={selectedShipType}
              selectedAddress={selectedAddress}
              onClicBack={() => setTabStep(2)}
              onClickNext={() => handleUpdateShippment()}
              isUpdate
              removedList={selectedListREMOVE}
              totalProductsWeight={sumPrds()}
            />
          )}
        </>
      )}
    </CustomModal>
  );
}
