import React, { useEffect, useState } from "react";
import { usePhoneCountryCode } from "../../../../hooks/usePhoneCountryCode";
import { Button, Grid, Popover, Typography } from "@mui/material";
import { GenericInput } from "../../Molecules";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { lightBlue } from "@mui/material/colors";

export default function CountrySelect({
  selectedCode,
  defaultValue,
}: {
  selectedCode(val: string): void;
  defaultValue?: string;
}) {
  const { countries } = usePhoneCountryCode();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedContry, setSelectedCountry] = useState({} as CountryPhone);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (defaultValue) {
      const find = countries.find((country) => country.phone === defaultValue);

      if (find) {
        setSelectedCountry(find);
      }
    }
    // eslint-disable-next-line
  }, [defaultValue]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const filterCountries = (countriesList: CountryPhone[]) => {
    if (searchTerm) {
      return countriesList.filter((country) =>
        country.label.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return countriesList;
  };

  return (
    <>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          width: 120,
          mt: 2,
          color: "white",
          bgcolor: lightBlue["A700"],
          "&: hover": { bgcolor: lightBlue[300] },
        }}
      >
        {selectedContry.phone ? (
          `+${selectedContry.phone}`
        ) : (
          <Typography
            textAlign={"center"}
            sx={{ display: "flex", alignItems: "center" }}
          >
            Code
            <PlayArrowIcon
              sx={{
                fill: "white",
                fontSize: 30,
                rotate: "90deg",
                mt: -0.5,
              }}
            />
          </Typography>
        )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <GenericInput
          inputName="searchPhone"
          onChange={(val) => setSearchTerm(val)}
          placeholder="Country"
          value={searchTerm}
          hasIcon={false}
        />
        <Grid spacing={2} container display="flex" flexDirection="column" p={2}>
          {filterCountries(countries).map((option, index) => (
            <Grid
              key={index}
              item
              sx={{
                "& > img": { mr: 2, flexShrink: 0 },
                ml: 2,
                borderRadius: 5,
                background:
                  option.label === selectedContry.label
                    ? "lightgray"
                    : "transparent",
              }}
              onClick={() => {
                setSelectedCountry(option);
                selectedCode(option.phone);
                setAnchorEl(null);
              }}
              pb={1.5}
            >
              <img
                loading="lazy"
                width="20"
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                alt=""
              />
              {option.label} ({option.code}) +{option.phone}
            </Grid>
          ))}
        </Grid>
      </Popover>
    </>
  );
}
