import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { LoginLayout, MainLayout } from "../components/layouts";
import {
  AdminOrders,
  Cart,
  Config,
  Contact,
  Details,
  FAQ,
  FavoritesPage,
  ForgotPassword,
  Home,
  Login,
  MyInfo,
  Products,
  ProductsAdmin,
  Proxy,
  ServiceMessages,
  ServiceMessagesAdmin,
  VerifyPay,
} from "../pages";
import { AdminLayout } from "../components/layouts/AdminLayout";
import { UsersCards } from "../components/AtomicDesign/Organisms";
import { OrdersProvider } from "../hooks/context/useOrders";
import { OrdersAdminProvider } from "../hooks/context/useOrdersAdmin";
import { ShippmentsProvider } from "../hooks/context/useShippments";
import { Shippments } from "../pages/MyInfo/componentsPage/Shippments";

export function RoutesTreasureBox() {
  return (
    <BrowserRouter>
      <Routes>
        {/* login */}

        <Route path="/" element={<MainLayout />}>
          <Route path="home" element={<Home />} />
          <Route path="products" element={<Products />} />
          <Route path="sale" element={<Products />} />
          <Route path="contact" element={<Contact />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="proxy" element={<Proxy />} />
          <Route path="details" element={<Details />} />
        </Route>

        <Route path="/checkout" element={<LoginLayout />}>
          <Route path="mycart" element={<Cart />} />
          <Route path="success-pay" element={<VerifyPay />} />
          <Route path="payment-error" element={<Cart />} />
        </Route>

        <Route
          path="/user"
          element={
            <OrdersProvider>
              <LoginLayout />
            </OrdersProvider>
          }
        >
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="my-info" element={<MyInfo />} />
          <Route path="favorites" element={<FavoritesPage />} />
          <Route path="messages" element={<ServiceMessages />} />
        </Route>

        <Route path="/admin" element={<AdminLayout />}>
          <Route path="users" element={<UsersCards />} />
          <Route path="products" element={<ProductsAdmin />} />
          <Route path="messages" element={<ServiceMessagesAdmin />} />
          <Route
            path="orders"
            element={
              <OrdersAdminProvider>
                <AdminOrders />
              </OrdersAdminProvider>
            }
          />
          <Route
            path="deliveries"
            element={
              <ShippmentsProvider>
                <Shippments />
              </ShippmentsProvider>
            }
          />
          <Route path="config" element={<Config />} />
        </Route>

        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </BrowserRouter>
  );
}
