import { useEffect, useState } from "react";
import {
  GenericInput,
  GenericSelect,
} from "../../../components/AtomicDesign/Molecules";
import { CustomModal } from "../../../components/AtomicDesign/Organisms";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import { getUserServiceAdmin } from "../../../services/getUsersAdmin";
import SendIcon from "@mui/icons-material/Send";
import { Button } from "@mui/material";

interface MessageProps {
  userID: null | number;
  description: string;
  userName: string;
}

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  createNewConversation: (data: MessageProps) => void;
}

export function NewMessageModal({
  open,
  setOpen,
  createNewConversation,
}: Props) {
  const [messageData, setMessageData] = useState<MessageProps>({
    userID: null,
    description: "",
    userName: "",
  });
  const { isSmalldesktop } = useBreakpoints();
  const [usersData, setUsersData] = useState<UsersAdmin[]>();

  const getUsersAdmin = async () => {
    try {
      const response = await getUserServiceAdmin({
        searchKey: "name",
        searchTerm: messageData?.userName,
        itensPerPage: 50,
        offset: 0,
      });
      setUsersData(response.data);
    } catch {}
  };

  useEffect(() => {
    if (open && messageData?.userName) {
      getUsersAdmin();
    }
    // eslint-disable-next-line
  }, [messageData?.userName, open]);

  return (
    <CustomModal
      openModal={open}
      setOpenModal={() => setOpen(false)}
      title="Create new conversation"
      width={isSmalldesktop ? "90%" : "30%"}
    >
      <GenericInput
        hasIcon={false}
        onChange={(value) =>
          setMessageData((old) => ({ ...old, userName: value }))
        }
        value={messageData?.userName}
        placeholder={"Find user by name"}
        inputName="userName"
      />
      <GenericSelect
        formWidth="98%"
        onChange={(value) => {
          setMessageData((old) => ({ ...old, userID: Number(value) }));
        }}
        value={String(messageData?.userID)}
        placeholder={"Select user"}
        name="originName"
        options={usersData?.map((user) => ({
          label: `${user?.user?.name} - id: ${user?.user?.id}`,
          value: user?.user?.id,
        }))}
        disabled={messageData?.userName === ""}
      />

      <GenericInput
        hasIcon={false}
        onChange={(value) =>
          setMessageData((old) => ({ ...old, description: value }))
        }
        value={messageData?.description}
        placeholder={"Write the message"}
        inputName="description"
        disabled={!messageData?.userID}
      />

      <Button
        fullWidth
        variant="contained"
        onClick={() => {
          createNewConversation(messageData);
          setOpen(false);
          setMessageData({ description: "", userID: null, userName: "" });
        }}
      >
        Send <SendIcon sx={{ ml: 1 }} />
      </Button>
    </CustomModal>
  );
}
