import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";
import { blue, red } from "@mui/material/colors";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../../../../hooks/context/useAuth";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import ChatIcon from "@mui/icons-material/Chat";
import { Box } from "@mui/material";

export function LoginPopover({ setTab }: { setTab?: VoidFunction }) {
  const { user, token, logout } = useAuth();
  const { isSmalldesktop } = useBreakpoints();
  const nav = useNavigate();
  const { pathname } = useLocation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const isLogged = user && token ? true : false;
  const isAdmin = user?.user?.role === "admin";

  return (
    <div>
      {isLogged ? (
        <>
          <Box
            display={"flex"}
            flexDirection={isSmalldesktop ? "column" : "row"}
          >
            <Button
              aria-describedby={id}
              variant="text"
              onClick={() => {
                nav("/contact?tab=3");
                setTab();
              }}
              sx={{ mr: 1 }}
            >
              <PhoneForwardedIcon sx={{ mr: 1 }} />
              Support
            </Button>
            <Button
              aria-describedby={id}
              variant="outlined"
              onClick={handleClick}
            >
              {isAdmin ? (
                <AdminPanelSettingsIcon sx={{ mr: 1 }} />
              ) : (
                <AccountCircleIcon sx={{ mr: 1 }} />
              )}
              {user?.user?.name?.split(" ")[0]}
            </Button>
          </Box>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", width: 150 }}>
              {isAdmin ? (
                <>
                  <Button
                    variant="text"
                    onClick={() => nav("/admin/users")}
                    style={{ height: 60 }}
                    fullWidth
                  >
                    <ContactMailIcon sx={{ mr: 2 }} />
                    Wesite info
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => {
                      localStorage.setItem("@treasure_box_admintab", String(4));
                      nav("/admin/messages");
                    }}
                    style={{ height: 60 }}
                    fullWidth
                  >
                    <ChatIcon sx={{ mr: 2 }} />
                    Messages
                  </Button>
                  <Button
                    sx={{
                      p: 0,
                      borderRadius: 0,
                      minHeight: 36,
                      background: red[300],
                      "&: hover": { background: red[100] },
                    }}
                    variant="contained"
                    onClick={() => {
                      logout();

                      nav("/home");
                    }}
                  >
                    <PowerSettingsNewIcon sx={{ mr: 1 }} /> Logout
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="text"
                    onClick={() => nav("/user/my-info")}
                    style={{ height: 60 }}
                    fullWidth
                  >
                    <ContactMailIcon sx={{ mr: 2 }} />
                    My info
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => nav("/user/favorites")}
                    style={{ height: 60 }}
                    fullWidth
                  >
                    <FavoriteIcon sx={{ mr: 2 }} />
                    Favorites
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => nav("/user/messages")}
                    style={{ height: 60 }}
                    fullWidth
                  >
                    <ChatIcon sx={{ mr: 2 }} />
                    Messages
                  </Button>
                  <Button
                    sx={{
                      p: 0,
                      borderRadius: 0,
                      minHeight: 36,
                      background: red[300],
                      "&: hover": { background: red[100] },
                    }}
                    variant="contained"
                    onClick={() => {
                      logout();

                      nav("/home");
                    }}
                  >
                    <PowerSettingsNewIcon sx={{ mr: 1 }} /> Logout
                  </Button>
                </>
              )}
            </Box>
          </Popover>
        </>
      ) : (
        <Button
          variant={"contained"}
          sx={{
            width: isSmalldesktop ? 150 : 200,
            height: 40,
            background: blue[300],
            "&:hover": { background: blue[200] },
          }}
          onClick={() => {
            if (pathname === "/user/login") {
              nav("/home");
            } else {
              nav("/user/login");
            }
          }}
        >
          {pathname === "/user/login" ? "Back" : "Login"}
        </Button>
      )}
    </div>
  );
}
