import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { updatePaymentStatus } from "../../../services/cartService";
import { updatePaymentPreOrder } from "../../../services/ordersService";
import { verifyPaymentShippmentService } from "../../../services/shippmentsService";
import { OrderCard } from "./components/OrderCard";
import { PreorderCard } from "./components/PreorderCard";
import { ShippmentCard } from "./components/ShippmentCard";
import { CircularLoading } from "../../../components/AtomicDesign/Atoms";

interface PaymentProps {
  payStatus: null | string;
  dataOrder: OrderData;
  dataPreOrder: PreOrderConfirmationPayment;
  dataShipment: ShippmentData;
}

export function VerifyPay() {
  const [searchParams] = useSearchParams();
  const payID = searchParams.get("session_id");
  const orderID = searchParams.get("orderID");
  const preorder = searchParams.get("preorder");
  const personalStPrd = searchParams.get("personalStPrd");
  const shippmentID = searchParams.get("shippmentID");
  const [payStatus, setPayStatus] = useState<PaymentProps>({
    payStatus: null,
    dataOrder: null,
    dataPreOrder: null,
    dataShipment: null,
  });

  const [loading, setLoading] = useState(false);

  const getPayInfo = async () => {
    setLoading(true);
    try {
      if (shippmentID) {
        const response = await verifyPaymentShippmentService({
          sessionid: payID,
          shippmentID: Number(shippmentID),
        });
        setPayStatus({
          payStatus: response.payStatus,
          dataOrder: null,
          dataPreOrder: null,
          dataShipment: response.data,
        });
      } else {
        if (preorder && personalStPrd) {
          const response = await updatePaymentPreOrder({
            orderID: Number(orderID),
            personalStPrd: Number(personalStPrd),
            productsId: Number(preorder),
            sessionid: payID,
          });
          setPayStatus({
            payStatus: response.payStatus,
            dataOrder: null,
            dataPreOrder: response.data,
            dataShipment: null,
          });
        } else {
          const response = await updatePaymentStatus({
            orderID: Number(orderID),
            sessionid: payID,
          });
          setPayStatus({
            payStatus: response.payStatus,
            dataOrder: response.data,
            dataPreOrder: null,
            dataShipment: null,
          });
        }
      }
    } catch {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (payID) {
      getPayInfo();
    }
    // eslint-disable-next-line
  }, [payID]);

  const render = () => {
    if (payStatus?.dataOrder) {
      return (
        <OrderCard
          payStatus={payStatus?.payStatus}
          order={payStatus?.dataOrder}
        />
      );
    }

    if (payStatus?.dataPreOrder) {
      return (
        <PreorderCard
          payStatus={payStatus?.payStatus}
          preOrderData={payStatus?.dataPreOrder}
        />
      );
    }

    if (payStatus?.dataShipment) {
      return (
        <ShippmentCard
          payStatus={payStatus?.payStatus}
          shippmentData={payStatus?.dataShipment}
        />
      );
    }
    return <></>;
  };

  return loading ? <CircularLoading /> : render();
}
