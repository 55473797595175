import { Badge, Box, Button, Popover, Typography } from "@mui/material";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import useCart from "../../../../hooks/context/useCart";
import React from "react";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { colors } from "../../../../theme/colors";
import { grey } from "@mui/material/colors";
import { CustomButton } from "../Buttons";
import { useNavigate } from "react-router-dom";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { CircularLoading } from "../../Atoms";
import useAuth from "../../../../hooks/context/useAuth";

export function CartPopover() {
  const navigate = useNavigate();
  const { isSmalldesktop } = useBreakpoints();
  const { cartDataMemo, loading } = useCart();
  const { user } = useAuth();
  const { JPYmoney } = useJPYmoney();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const data = cartDataMemo[0];

  const redirectToCheckout = () => {
    navigate("/checkout/mycart");
  };

  return (
    <>
      <Badge
        badgeContent={data?.totalItens}
        color="primary"
        style={{ width: "100%" }}
        sx={{
          "& .MuiBadge-badge": {
            right: 10,
            top: 23,

            background: "red",
            borderRadius: "50%",
            width: 30,
            height: 30,
            padding: "0 4px",
          },
        }}
      >
        <Button
          variant="contained"
          onClick={(e) => {
            if (!user?.user) {
              navigate("/user/login");
            } else {
              if (isSmalldesktop) {
                redirectToCheckout();
              } else {
                handleClick(e);
              }
            }
          }}
          sx={{
            mt: isSmalldesktop ? 0 : 0.5,
            background: colors.blue[900],
            "&:hover": { background: colors.blue[400] },
          }}
          fullWidth
        >
          <ShoppingCartOutlinedIcon sx={{ mr: 1 }} />
          {JPYmoney(data?.totalPrice)}
        </Button>
      </Badge>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p={2}>
          {loading.cartLoading ? (
            <CircularLoading />
          ) : (
            <>
              <CustomButton
                btnType="login"
                style={{ marginBottom: 10 }}
                onClick={redirectToCheckout}
              >
                Go to cart <ShoppingCartCheckoutIcon sx={{ ml: 1 }} />
              </CustomButton>

              <Box display={"flex"} flexDirection={"column"} gap={1}>
                {data?.cartXProduct?.map((item, index) => (
                  <Box
                    key={index}
                    display={"flex"}
                    onClick={() =>
                      navigate(`/details?product=${item?.product?.id}`)
                    }
                    sx={{
                      cursor: "pointer",
                      "&:hover": { background: grey[100], borderRadius: 2 },
                    }}
                  >
                    <img
                      src={item?.product?.productsImages[0]?.code}
                      style={{
                        objectFit: "contain",
                        width: 100,
                        height: 100,
                        borderRadius: 5,
                        background: grey[200],
                      }}
                      alt={item?.product?.characterName}
                    />

                    <Typography
                      maxWidth={250}
                      maxHeight={100}
                      overflow={"hidden"}
                      ml={1}
                    >
                      x{item?.quantity} -{" "}
                      {JPYmoney(item?.product?.price * item?.quantity)} -{" "}
                      {item?.product?.characterName}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </>
          )}
        </Box>
      </Popover>
    </>
  );
}
