import { Box, Grow, Typography } from "@mui/material";
import { teal } from "@mui/material/colors";
import CheckIcon from "@mui/icons-material/Check";

export function PaymentSuccess({ seconds }: { seconds: number }) {
  return (
    <Box
      sx={{
        background: teal[400],
        height: 325,
        width: "100%",
        borderRadius: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        p={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Grow timeout={1000} in>
          <CheckIcon
            style={{
              fill: teal[400],
              background: "#FFFFFF",
              borderRadius: "50%",
              fontSize: 100,
            }}
          />
        </Grow>
        <Grow timeout={2000} in>
          <Typography color="#FFFFFF" fontSize={25}>
            All done!
          </Typography>
        </Grow>
        <Grow timeout={3000} in>
          <Typography color="#FFFFFF" fontSize={25} textAlign={"center"}>
            Your order was placed, i'll redirect you to your orders page in{" "}
            {seconds} seconds
          </Typography>
        </Grow>
      </Box>
    </Box>
  );
}
