import { Box } from "@mui/material";

export function GlobalContainer({ children, ...rest }) {
  return (
    <Box display={"flex"} justifyContent={"center"} width={"100%"}>
      <Box maxWidth={"1400px"} width={"100%"} {...rest}>
        {children}
      </Box>
    </Box>
  );
}
