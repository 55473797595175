import { Box, Typography } from "@mui/material";
import { SimpleTextBox } from "../../Atoms";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { GenericInput } from "../GenericInput";

export function ProductComponent({
  selectedOrdersXproducts,
  isPartialCancelation,
  changeQuantityToCancel,
}: {
  selectedOrdersXproducts: ItensToCancel[];
  isPartialCancelation?: boolean;
  changeQuantityToCancel?: (val: ItensToCancel[]) => void;
}) {
  const { JPYmoney } = useJPYmoney();

  const changeQTDCancel = (quandity: number, productID: number) => {
    const find = selectedOrdersXproducts.find(
      (item) => item.productsId === productID
    );
    find.quantityToCancel = quandity;

    const filter = selectedOrdersXproducts.filter(
      (prd) => prd.productsId !== productID
    );

    changeQuantityToCancel([...filter, find]);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        minWidth: "100%",
        justifyContent: "center",
        overflowX: "auto",
        gap: 1,
        pt: 3,
        pb: 3,
      }}
    >
      {selectedOrdersXproducts?.map((item, index) => (
        <Box
          maxWidth={150}
          height={isPartialCancelation ? 360 : 300}
          overflow={"hidden"}
          key={index}
          sx={{ background: "#FFFFFF", p: 1, borderRadius: 2 }}
        >
          <img
            src={item?.Products?.productsImages[0]?.code}
            style={{
              objectFit: "contain",
              width: 150,
              height: 150,
              borderRadius: 5,
              background: "#FFFFFF",
            }}
            alt={item?.Products?.characterName}
          />
          {item?.Products?.isPreOrder && (
            <Typography
              style={{
                position: "relative",
                background: "#d32f2f",
                width: 150,
                marginTop: -25,
                borderRadius: "0 0 5px 5px",
                color: "#FFFFFF",
                textAlign: "center",
              }}
            >
              Pre-Order
            </Typography>
          )}
          <SimpleTextBox
            title=""
            description={`x${item?.quantity} - ${item?.Products?.characterName}`}
            height={90}
          />
          <SimpleTextBox
            title="Bought price"
            description={JPYmoney(item?.boughtPrice)}
            titleColor="black"
          />
          {isPartialCancelation && (
            <GenericInput
              width="200px"
              hasIcon={false}
              inputName="cancelQuantity"
              onChange={(newQTD) => {
                const regexNumber = new RegExp("^[0-9]*$");

                if (
                  newQTD.match(regexNumber) &&
                  Number(newQTD) <= item.quantity
                ) {
                  changeQTDCancel(Number(newQTD), item?.productsId);
                }
              }}
              value={item?.quantityToCancel}
              placeholder="Quantity to cancel"
            />
          )}
        </Box>
      ))}
    </Box>
  );
}
