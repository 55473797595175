import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CustomModal } from "../Modal";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { chargeUnpaidSession } from "../../../../services/ordersService";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

interface Props {
  myOrderID: number;
  open: boolean;
  setOpen: (val: boolean) => void;
  setAttData: VoidFunction;
}

export function PaymentModalInList({
  myOrderID,
  open,
  setOpen,
  setAttData,
}: Props) {
  const { isSmalldesktop } = useBreakpoints();

  const fetchClientSecret = async () => {
    try {
      const secret = await chargeUnpaidSession(myOrderID);
      setAttData();
      return secret;
    } catch {}
  };
  return (
    <CustomModal
      openModal={open}
      setOpenModal={() => {
        setOpen(false);
      }}
      width={isSmalldesktop ? "90%" : "60%"}
      disableBackdropClick
      title={"Please, insert your credit card data"}
    >
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={{
          fetchClientSecret,
        }}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </CustomModal>
  );
}
