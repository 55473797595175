import { useEffect, useState } from "react";
import { getUserAddress } from "../../../../../services/addressService";
import useAuth from "../../../../../hooks/context/useAuth";
import { FadeInContainer } from "../../../../../components/AtomicDesign/Atoms";
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Typography,
} from "@mui/material";
import { GenericSelect } from "../../../../../components/AtomicDesign/Molecules";
import { amber, red, teal } from "@mui/material/colors";
import { ShippingFeeSimulator } from "../../../../../components/AtomicDesign/Organisms";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

interface Props {
  onClickNext?: VoidFunction;
  onClicBack?: VoidFunction;
  setSelectedAddress?: (address: IAddress) => void;
  selectedAddress: IAddress;
  totalProductsWeight: number;
  setSelectedShipType?: (val: SelectedShipType) => void;
  selectedShipType: SelectedShipType;
  disabled?: boolean;
  canGoSmallPacket: boolean;
}

export function SelectAddress({
  setSelectedAddress,
  selectedAddress,
  totalProductsWeight,
  onClickNext,
  onClicBack,
  setSelectedShipType,
  selectedShipType,
  disabled = false,
  canGoSmallPacket,
}: Props) {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState<IAddress[]>([]);

  const myAddress = async () => {
    setLoading(true);
    try {
      const myAddress = await getUserAddress(user?.user?.id);
      setAddress(myAddress);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    myAddress();
    // eslint-disable-next-line
  }, []);

  return (
    <FadeInContainer>
      <Box mt={5} mb={5}>
        <Typography textAlign={"center"}>Select address</Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <GenericSelect
              onChange={(val) => {
                setSelectedAddress(
                  address?.find((item) => item?.id === Number(val))
                );
              }}
              options={
                address?.map((ad) => ({
                  label: `${ad?.address} ${ad?.adressNumber} - ${ad?.city}/${ad?.country}`,
                  value: String(ad?.id),
                })) ?? []
              }
              placeholder={"Select address"}
              value={String(selectedAddress?.id)}
              name="address"
              disabled={disabled}
            />

            {!loading && address?.length === 0 && (
              <FormHelperText
                sx={{
                  bgcolor: amber[100],
                  borderRadius: "12px",
                  p: 1,
                  mt: 1,
                  mb: 2,
                  textAlign: "center",
                }}
              >
                <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
                You don't have any address in your account, please, go to "MY
                INFO" menu and create one
              </FormHelperText>
            )}

            {selectedAddress?.id && (
              <>
                {/* <Typography textAlign={"center"} mt={2}>
                  Total weight: {totalProductsWeight} g
                </Typography> */}
                {!disabled && (
                  <Typography textAlign={"center"} mt={2}>
                    Select your ship choice
                  </Typography>
                )}
                <ShippingFeeSimulator
                  selectedShipType={selectedShipType}
                  onClickShipOption={setSelectedShipType}
                  address={selectedAddress}
                  productsWeight={totalProductsWeight}
                  clickDisabled={disabled}
                  canGoSmallPacket={canGoSmallPacket}
                />
              </>
            )}
            {!disabled && (
              <Box
                display={"flex"}
                width={"100%"}
                justifyContent={"space-between"}
                mt={2}
              >
                <Button
                  onClick={onClicBack}
                  variant="contained"
                  sx={{
                    background: red[500],
                    "&:hover": { background: red[100] },
                    height: 50,
                    width: 100,
                  }}
                >
                  Back
                </Button>
                <Button
                  onClick={onClickNext}
                  variant="contained"
                  sx={{
                    background: teal[500],
                    "&:hover": { background: teal[100] },
                    height: 50,
                    width: 100,
                  }}
                  disabled={
                    !selectedAddress.id || selectedShipType?.shipPrice <= 0
                  }
                >
                  Next
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </FadeInContainer>
  );
}
