import { Box, Button, Typography } from "@mui/material";
import { FadeInContainer } from "../../../../../components/AtomicDesign/Atoms";
import { SelectAddress } from "./Address";
import { ProductCard } from "../components/ProductCard";
import { red, teal } from "@mui/material/colors";

interface Props {
  selectedList: PersonalStorageToShip[];
  selectedShipType: SelectedShipType;
  selectedAddress: IAddress;
  onClicBack: VoidFunction;
  onClickNext: VoidFunction;
  isUpdate?: boolean;
  removedList?: PersonalStorageToShip[];
  totalProductsWeight?: number;
  canGoBySmallPacket: boolean;
}

export function ResumeShip({
  selectedList,
  selectedShipType,
  selectedAddress,
  onClicBack,
  onClickNext,
  isUpdate = false,
  removedList,
  totalProductsWeight,
  canGoBySmallPacket,
}: Props) {
  return (
    <FadeInContainer>
      <Box mt={2}>
        <Box
          display={"flex"}
          justifyContent={"center"}
          sx={{ overflowX: "auto" }}
          width={"100%"}
          gap={1}
        >
          {selectedList?.length > 0 &&
            selectedList?.map((prd, index) => (
              <ProductCard
                index={index}
                hasClick={false}
                prd={prd}
                inputDisabled
              />
            ))}
        </Box>

        {removedList?.length > 0 && (
          <Typography textAlign={"center"} mb={2}>
            Products to remove from shipment
          </Typography>
        )}
        <Box
          display={"flex"}
          justifyContent={"center"}
          sx={{ overflowX: "auto" }}
          width={"100%"}
          gap={1}
        >
          {removedList?.length > 0 &&
            removedList.map((prd, index) => (
              <ProductCard
                index={index}
                hasClick={false}
                prd={prd}
                inputDisabled
              />
            ))}
        </Box>
        <SelectAddress
          disabled
          selectedShipType={selectedShipType}
          selectedAddress={selectedAddress}
          totalProductsWeight={totalProductsWeight}
          canGoSmallPacket
        />

        <Box
          display={"flex"}
          width={"100%"}
          justifyContent={"space-between"}
          mt={2}
        >
          <Button
            onClick={onClicBack}
            variant="contained"
            sx={{
              background: red[500],
              "&:hover": { background: red[100] },
              height: 50,
              width: 100,
            }}
          >
            Back
          </Button>
          <Button
            onClick={onClickNext}
            variant="contained"
            sx={{
              background: teal[500],
              "&:hover": { background: teal[100] },
              height: 50,
              width: 180,
            }}
          >
            {isUpdate ? "Update shipment" : "Create shipment"}
          </Button>
        </Box>
      </Box>
    </FadeInContainer>
  );
}
