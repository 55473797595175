import { api } from "./api";

export const uploadBannerPhotosService = async (data: any[]) => {
  // await new Promise((resolve, reject) => {
  try {
    await Promise.all(
      data.map(async (item) => {
        await api.post(
          "/update-banner-photos",
          { file: item },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      })
    );
    // resolve(true);
  } catch (err: any) {
    throw new Error(err);
  }
  // });
};

export const getBannerService = async () => {
  const response2 = await api.get("/get-banner");
  return response2.data;
};

export const updateBannerLink = async (data: UpdateBannerLink) => {
  const response2 = await api.post("/update-banner-link", { ...data });
  return response2.data;
};

export const deleteBannerPhoto = async (photoID: number) => {
  await api.delete("/delete-banner-photo", {
    params: { photoID },
  });
};
