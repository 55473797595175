import { createContext, useContext, useEffect, useMemo, useState } from "react";
// import useToast from "./useToast";

import useGlobalLoading from "./useGlobalLoading";
import { getMyOrdersAdmin } from "../../services/ordersService";

const OrdersAdminProviderContext = createContext({} as IOrdersAdminContext);

function OrdersAdminProvider({ children }: { children: React.ReactNode }) {
  const { setOpenLoading } = useGlobalLoading();
  // const { setOpen, setToastData } = useToast();
  const [ordersData, setOrdersData] = useState([] as OrderData[]);
  const [orderAdminRecords, setOrdersAdminRecords] = useState(0);
  const [filters, setFilters] = useState<orderAdminFilter>({
    offset: 0,
    itensPerPage: 50,
    orderNumber: undefined,
    paymentStatus: "all",
    preOrder: false,
  });

  const ordersAdminDataMemo = useMemo(() => ordersData, [ordersData]);

  const getOrdersAdmin = async () => {
    setOpenLoading(true);

    try {
      const response = await getMyOrdersAdmin({
        ...filters,
      });
      setOrdersData(response.data);
      setOrdersAdminRecords(Math.ceil(response.count / filters.itensPerPage));
    } catch {
    } finally {
      setOpenLoading(false);
      // setTimeout(() => setOpenLoading(false), 5000);
    }
  };

  useEffect(() => {
    getOrdersAdmin();
    // eslint-disable-next-line
  }, [filters]);

  return (
    <OrdersAdminProviderContext.Provider
      value={{
        orderAdminRecords,
        ordersAdminDataMemo,
        filters,
        setFilters,
        getOrdersAdmin,
      }}
    >
      {children}
    </OrdersAdminProviderContext.Provider>
  );
}

export { OrdersAdminProvider, OrdersAdminProviderContext };

export default function useOrdersAdmin() {
  return useContext(OrdersAdminProviderContext);
}
