import { Box, Button, Switch, Typography } from "@mui/material";
import { BoxContent, FadeInContainer } from "../../../Atoms";
import { EmailField, GenericInput, PasswordField } from "../../../Molecules";

// import treasureBoxLogo from "../../../../../assets/images/NEWTREASUREBOXLOGO2.png";
import useAuth from "../../../../../hooks/context/useAuth";
import { useTranslate } from "../../../../../hooks/useTranslate";
import * as yup from "yup";

import { useFormik } from "formik";
import { updateUserService } from "../../../../../services/updateUserService";
import CountrySelect from "../../AutocompletePhone";
import { useEffect } from "react";
import { usePhoneCode } from "../../../../../hooks/usePhoneCode";
import useToast from "../../../../../hooks/context/useToast";
import { AddressBox } from "../AddressBook";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";

interface IFormInputs {
  name: string;
  phoneCode: string;
  phone: string;
  ssn: string;
  email: string;
  password: string;
  mailNotification: boolean;
}

interface Props {
  userData: User;
}

export function UpdateMyUser({ userData }: Props) {
  const { strings } = useTranslate();
  const { language, getUser } = useAuth();
  const { convertPhone } = usePhoneCode();
  const { setOpen, setToastData } = useToast();
  const { isSmalldesktop } = useBreakpoints();

  const validationSchema = yup.object({
    name: yup
      .string()
      .required(strings.validationFields.name.required[language]),
    phoneCode: yup
      .string()
      .required(strings.validationFields.phoneCode.required[language]),
    phone: yup
      .number()
      .typeError(strings.validationFields.phoneCode.typeError[language])
      .required(strings.validationFields.phoneCode.required[language]),
    // ssn: yup
    //   .number()
    //   .typeError(strings.validationFields.phoneCode.typeError[language])
    //   .test((val) => {
    //     if (!val) return false;
    //     if (String(val).length > 15) return false;
    //     return true;
    //   })
    //   .required(strings.validationFields.ssn[language]),
    email: yup
      .string()
      .email(strings.validationFields.email[language])
      .required(strings.validationFields.email.required[language]),
  });

  const formik = useFormik<IFormInputs>({
    initialValues: {
      email: "",
      name: "",
      phone: "",
      phoneCode: "",
      ssn: "",
      password: "",
      mailNotification: true,
    },
    validationSchema,
    async onSubmit(values: any) {
      const updateUser = await updateUserService({
        id: userData?.user.id,
        ...values,
        phone: `${values?.phoneCode}${values.phone}`,
      });

      if (updateUser?.message === "success") {
        await getUser(userData?.user?.id);
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "success",
          message: strings.toastMessages.editPersonalData[language],
        });
        setOpen(true);
      } else {
        setToastData({
          anchor: { vertical: "top", horizontal: "center" },
          duration: 5000,
          type: "error",
          message: strings.toastMessages.editErrorPersonalData[language],
        });
        setOpen(true);
      }
    },
  });

  useEffect(() => {
    if (userData) {
      formik.setFieldValue("email", userData?.user?.email);
      formik.setFieldValue("name", userData?.user?.name);
      formik.setFieldValue(
        "phone",
        convertPhone(userData?.user?.phoneCode, userData?.user?.phone)
      );
      formik.setFieldValue("phoneCode", userData?.user?.phoneCode);
      formik.setFieldValue("ssn", userData?.user?.ssn);
      formik.setFieldValue(
        "mailNotification",
        userData?.user?.mailNotification
      );
    }
    // eslint-disable-next-line
  }, [userData]);

  return (
    <Box height={"100%"} width={"100%"}>
      <FadeInContainer>
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          gap={1}
        >
          <BoxContent
            config={{
              p: 3,
              mt: 1,
              minWidth: 320,
              width: isSmalldesktop ? "85%" : "40%",
            }}
          >
            {/* <img src={treasureBoxLogo} width={150} alt="treasurebox logo" /> */}

            <GenericInput
              hasIcon={false}
              onChange={(value, name) => formik.setFieldValue(name, value)}
              error={
                formik?.touched?.name && formik?.errors?.name ? true : false
              }
              errorMessage={formik?.touched?.name ? formik?.errors?.name : ""}
              value={formik?.values?.name}
              placeholder={strings.createUser.namePlaceholder[language]}
              inputName="name"
            />

            {/* <BreakpointBox> */}
            <Box display="flex" sx={{ width: "100%" }}>
              <CountrySelect
                selectedCode={(val) => formik.setFieldValue("phoneCode", val)}
                defaultValue={formik?.values?.phoneCode}
              />
              <GenericInput
                hasIcon={false}
                onChange={(value, name) => formik.setFieldValue(name, value)}
                error={
                  (formik?.touched?.phone && formik?.errors?.phone) ||
                  (formik?.touched?.phoneCode && formik?.errors?.phoneCode)
                    ? true
                    : false
                }
                errorMessage={
                  formik?.touched?.phone || formik?.touched?.phoneCode
                    ? formik?.errors?.phone
                      ? formik?.errors?.phone
                      : formik?.errors?.phoneCode
                      ? formik?.errors?.phoneCode
                      : ""
                    : ""
                }
                value={formik?.values?.phone}
                placeholder={strings.createUser.phonePlaceholder[language]}
                inputName="phone"
              />
            </Box>
            {/* <GenericInput
                hasIcon={false}
                onChange={(value, name) => formik.setFieldValue(name, value)}
                error={
                  formik?.touched?.ssn && formik?.errors?.ssn ? true : false
                }
                errorMessage={formik?.touched?.ssn ? formik?.errors?.ssn : ""}
                value={formik?.values?.ssn}
                placeholder={strings.createUser.ssn[language]}
                inputName="ssn"
              /> */}
            {/* </BreakpointBox> */}

            <EmailField
              hasIcon={false}
              onChange={(value) => formik.setFieldValue("email", value)}
              error={
                formik?.touched?.email && formik?.errors?.email ? true : false
              }
              errorMessage={formik?.touched?.email ? formik?.errors?.email : ""}
              value={formik?.values?.email}
              placeholder="E-mail"
            />
            <PasswordField
              hasIcon={false}
              onChange={(value) => formik.setFieldValue("password", value)}
              error={false}
              value={formik?.values?.password}
              placeholder={strings.createUser.passwordPlaceholder[language]}
            />
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              width={"97%"}
              mt={2}
            >
              <Typography>
                {strings.createUser.mailNotification[language]}
              </Typography>
              <Switch
                checked={formik.values.mailNotification}
                size={"small"}
                color="warning"
                onChange={(_e, check) =>
                  formik.setFieldValue("mailNotification", check)
                }
              />
            </Box>
            <Button
              sx={{ mt: 3 }}
              fullWidth
              variant="contained"
              onClick={() => formik.handleSubmit()}
            >
              Update
            </Button>
          </BoxContent>
          <Box width={isSmalldesktop ? "95%" : "50%"} minWidth={350}>
            <AddressBox userData={userData} />
          </Box>
        </Box>
      </FadeInContainer>
    </Box>
  );
}
