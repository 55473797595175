import { Box, Typography } from "@mui/material";
import { amber } from "@mui/material/colors";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";

interface Props {
  title: string;
  description: string | number;
  height?: string | number;
  titleColor?: string;
  descriptionColor?: string;
}

export function SimpleTextBox({
  title,
  description,
  height = "auto",
  titleColor = "#FFFFFF",
  descriptionColor = amber[700],
}: Props) {
  const { isSmalldesktop } = useBreakpoints();
  return (
    <Box
      display="flex"
      flexDirection={"column"}
      height={height}
      overflow={"hidden"}
    >
      <Typography fontSize={isSmalldesktop ? 14 : 20} color={titleColor}>
        {title}
      </Typography>
      <Typography fontSize={isSmalldesktop ? 14 : 20} color={descriptionColor}>
        {description}
      </Typography>
    </Box>
  );
}
