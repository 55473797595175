import { CircularProgress, Tab, Tabs } from "@mui/material";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";
import { CustomModal } from "../../../../../components/AtomicDesign/Organisms";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useEffect, useState } from "react";
import { SelectProduct } from "../Steps/SelectProduct";
import { SelectAddress } from "../Steps/Address";
import { ResumeShip } from "../Steps/ResumeShip";
import useShippments from "../../../../../hooks/context/useShippments";
import useAuth from "../../../../../hooks/context/useAuth";

const tabButtons = { minHeight: "50px", height: "50px" };

interface Props {
  open: boolean;
  onClose: VoidFunction;
}

export function CreateShippmentModal({ open, onClose }: Props) {
  const {
    personalStorageToShipDataMemo,
    loading,
    getPersonalStorageForShip,
    createShippment,
  } = useShippments();

  const { isSmalldesktop } = useBreakpoints();
  const { user } = useAuth();
  const [tabStep, setTabStep] = useState(1);
  const [selectedList, setSelectedList] = useState(
    [] as PersonalStorageToShip[]
  );
  const [selectedAddress, setSelectedAddress] = useState({} as IAddress);
  const [selectedShipType, setSelectedShipType] = useState({
    shipPrice: 0,
    selectedShipType: "",
  } as SelectedShipType);

  const closeModal = () => {
    setSelectedList([]);
    setSelectedAddress({} as IAddress);
    setSelectedShipType({} as SelectedShipType);
    setTabStep(1);
    onClose();
  };

  const handleCreateShippment = async () => {
    const payload = {
      personalStorageItensID: selectedList.map((item) => ({
        personalItemID: item?.id,
        quantity: item?.quantityToShip,
        personalStock: item.quantity,
      })),
      userID: user?.user?.id,
      addressID: selectedAddress?.id,
      shippingCost: selectedShipType.shipPrice,
      shipTpe: selectedShipType.selectedShipType,
    };
    await createShippment(payload);
    closeModal();
  };

  useEffect(() => {
    if (open) {
      getPersonalStorageForShip();
    }
    // eslint-disable-next-line
  }, [open]);

  const canGoBySmallPacket = () => {
    const canGo = selectedList?.some((prd) => !prd?.Products?.canGoSmallPacket);

    if (canGo) return false;
    return true;
  };

  return (
    <CustomModal
      openModal={open}
      setOpenModal={onClose}
      width={isSmalldesktop ? "90%" : "70%"}
    >
      {loading.createShippment ? (
        <CircularProgress />
      ) : (
        <>
          <Tabs
            value={tabStep}
            variant="fullWidth"
            sx={{
              "& .Mui-selected": {
                borderRadius: "5px 5px 0 0",
                background: "#FFFFFF",
              },
              "& .MuiTab-root": {
                fontSize: 16,

                "&: hover": {
                  borderRadius: "5px 5px 0 0",
                },
                pointerEvents: "none",
              },
            }}
          >
            <Tab
              icon={<CardGiftcardIcon />}
              iconPosition="start"
              value={1}
              label="Select products"
              sx={tabButtons}
            />
            <Tab
              sx={tabButtons}
              value={2}
              label="Select address"
              icon={<ImportContactsIcon />}
              iconPosition="start"
            />
            <Tab
              sx={tabButtons}
              value={3}
              label="Summary of your submission"
              icon={<EditNoteIcon />}
              iconPosition="start"
            />
          </Tabs>

          {tabStep === 1 && (
            <SelectProduct
              isLoading={loading.personalStorage}
              personalStorageToShipDataMemo={personalStorageToShipDataMemo}
              selectedList={selectedList}
              setSelectedList={setSelectedList}
              onClickNext={() => setTabStep(2)}
            />
          )}

          {tabStep === 2 && (
            <SelectAddress
              setSelectedShipType={setSelectedShipType}
              selectedShipType={selectedShipType}
              selectedAddress={selectedAddress}
              totalProductsWeight={selectedList.reduce(
                (acc, next) =>
                  acc + next?.Products?.weight * next?.quantityToShip,
                0
              )}
              onClickNext={() => setTabStep(3)}
              onClicBack={() => setTabStep(1)}
              setSelectedAddress={(address) => setSelectedAddress(address)}
              canGoSmallPacket={canGoBySmallPacket()}
            />
          )}

          {tabStep === 3 && (
            <ResumeShip
              canGoBySmallPacket={canGoBySmallPacket()}
              selectedList={selectedList}
              selectedShipType={selectedShipType}
              selectedAddress={selectedAddress}
              onClicBack={() => setTabStep(2)}
              onClickNext={() => handleCreateShippment()}
              totalProductsWeight={selectedList.reduce(
                (acc, next) =>
                  acc + next?.Products?.weight * next?.quantityToShip,
                0
              )}
            />
          )}
        </>
      )}
    </CustomModal>
  );
}
