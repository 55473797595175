import { Box, Pagination, TextField } from "@mui/material";
import useProducts from "../../../hooks/context/useProducts";
import { ProductList } from "../../../components/AtomicDesign/Organisms";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import { ContainerWithTopBorder } from "../../../components/AtomicDesign/Atoms";
import { useEffect, useState } from "react";

import {
  CustomButton,
  LateralFiltersAdmin,
  ModalProductEditCreate,
} from "../../../components/AtomicDesign/Molecules";

import SearchIcon from "@mui/icons-material/Search";

export interface OptionsModal {
  open: boolean;
  name: string;
  type: "versions" | "manufacturers" | "origins" | "productTypes";
  operation: "update" | "create";
}

export interface SelectOptionsModal {
  id: number;
  value: string;
}

export function ProductsAdmin() {
  const {
    productsDataMemo,
    records,
    productsfilters,
    setProductsFilters,
    setHandleIDProduct,
    handleIDProduct,
    setProduct,
  } = useProducts();
  const { isSmalldesktop } = useBreakpoints();
  const [open, setOpen] = useState(false);

  const [text, setText] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [
    productsfilters.inStock,
    productsfilters.isPreOwned,
    productsfilters.isSale,
    productsfilters.manufacturerName,
    productsfilters.name,
    productsfilters.originName,
    productsfilters.outOfStock,
    productsfilters.preOrder,
    productsfilters.productTypeName,
    productsfilters.versionName,
  ]);

  const cleanFilter = () => {
    setText("");
    setProductsFilters({
      offset: 0,
      itensPerPage: 18,
      name: undefined,
      manufacturerName: undefined,
      originName: undefined,
      versionName: undefined,
      isSale: undefined,
    });
  };

  useEffect(() => {
    cleanFilter();
    // eslint-disable-next-line
  }, []);

  return (
    <ContainerWithTopBorder
      title="My products"
      onClick={() => {
        setProduct({});
        setHandleIDProduct(0);
        setOpen(true);
      }}
    >
      <TextField
        sx={{ width: "100%", mr: 1 }}
        label="Search by name or origin"
        value={text}
        onChange={(e) => setText(e.target.value)}
        InputProps={{
          endAdornment: (
            <>
              {text && (
                <CustomButton
                  btnType="delete"
                  onClick={cleanFilter}
                  style={{ marginRight: 5 }}
                />
              )}
              <CustomButton
                btnType="search"
                onClick={() => {
                  setProductsFilters((old) => ({
                    ...old,
                    offset: 0,
                    name: text,
                  }));
                }}
              >
                <SearchIcon />
              </CustomButton>
            </>
          ),
        }}
      />
      <Box
        display={"flex"}
        flexDirection={isSmalldesktop ? "column" : "row"}
        gap={1}
        width={"100%"}
      >
        <LateralFiltersAdmin />
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          width={"100%"}
        >
          <ProductList
            list={productsDataMemo?.data}
            onClickAdminEdit={(id) => {
              setHandleIDProduct(id);
              setOpen(true);
            }}
          />
          <Pagination
            count={records}
            page={page}
            defaultPage={1}
            onChange={(_e, page) => {
              setProductsFilters((old) => ({ ...old, offset: page - 1 }));
              setPage(page);
            }}
          />
        </Box>
      </Box>

      <ModalProductEditCreate
        open={open}
        setOpen={setOpen}
        productID={handleIDProduct}
      />
    </ContainerWithTopBorder>
  );
}
