import { Box, Typography } from "@mui/material";
import { colors } from "../../../../theme/colors";
import { amber, grey } from "@mui/material/colors";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";

import { DrawerCheckout } from "../../../../components/AtomicDesign/Molecules";
import { useState } from "react";
import { PaymentModal } from "../../../../components/AtomicDesign/Organisms";

interface Props {
  setStep: VoidFunction;
  selectedProducts: CartXProduct[];
  cleanStateCart: VoidFunction;
}

export function CheckoutStep({
  setStep,
  selectedProducts,
  cleanStateCart,
}: Props) {
  const { JPYmoney } = useJPYmoney();
  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  const makeOrder = () => {
    setOpenPaymentModal(true);
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={1} mt={2}>
        {selectedProducts?.length > 0 &&
          selectedProducts?.map((item, index) => (
            <Box
              key={index}
              display={"flex"}
              flex={1}
              width={"100%"}
              alignItems={"flex-start"}
              flexDirection={"column"}
              sx={{
                border: `1px solid ${colors.blue[400]}`,
                borderRadius: 3,
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                width={"100%"}
              >
                <Box width={80} height={80} pl={1}>
                  <img
                    src={item?.product?.productsImages[0]?.code}
                    style={{
                      objectFit: "contain",
                      width: 80,
                      height: 80,
                      borderRadius: 5,
                      background: grey[200],
                    }}
                    alt={item?.product?.characterName}
                  />
                </Box>
                <Box display={"flex"} width={"100%"} alignItems={"center"}>
                  <Typography
                    overflow={"hidden"}
                    ml={1}
                    width={"50%"}
                    textOverflow={"ellipsis"}
                    textAlign={"center"}
                    maxHeight={75}
                  >
                    {item?.product?.characterName}
                  </Typography>

                  <Box
                    display={"flex"}
                    gap={2}
                    width={"50%"}
                    justifyContent={"center"}
                  >
                    Quantity: {item?.quantity}
                  </Box>
                </Box>
              </Box>

              <Typography
                textAlign={"center"}
                width={"100%"}
                sx={{
                  background: item?.product?.isPreOrder
                    ? amber[700]
                    : colors.blue[400],
                  borderRadius: "0 0 10px 10px",
                  color: "#FFFFFF",
                }}
              >
                {`${JPYmoney(item?.product?.price * item?.quantity)}${
                  item?.product?.isPreOrder ? " - Pre-Order" : ""
                }`}
              </Typography>
            </Box>
          ))}
      </Box>

      <DrawerCheckout
        fullPrice={selectedProducts.reduce((acc, next) => {
          if (!next.product.isPreOrder) {
            return acc + next.product.price * next.quantity;
          } else {
            return acc;
          }
        }, 0)}
        preOrderPrice={selectedProducts.reduce((acc, next) => {
          if (next.product.isPreOrder) {
            return acc + next.product.price * next.quantity;
          } else {
            return acc;
          }
        }, 0)}
        totalItens={selectedProducts.length}
        nextStep={makeOrder}
        backStep={setStep}
        btnText={
          selectedProducts.reduce((acc, next) => {
            if (!next.product.isPreOrder) {
              return acc + next.product.price * next.quantity;
            } else {
              return acc;
            }
          }, 0) > 0
            ? "Send payment"
            : "Make order"
        }
      />

      <PaymentModal
        cleanStateCart={cleanStateCart}
        selectedProducts={selectedProducts}
        itensInfo={{
          totalItens: selectedProducts.reduce(
            (acc, next) => acc + next?.quantity,
            0
          ),
          totalValue: selectedProducts.reduce((acc, next) => {
            if (!next.product.isPreOrder) {
              return acc + next.product.price * next.quantity;
            } else {
              return acc;
            }
          }, 0),
        }}
        open={openPaymentModal}
        setOpen={setOpenPaymentModal}
      />
    </>
  );
}
