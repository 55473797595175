import treasureLogo from "../../../../assets/images/NEWTREASUREBOXLOGO2.png";
import useGlobalLoading from "../../../../hooks/context/useGlobalLoading";
import { Box, Modal } from "@mui/material";
import "./styles.css";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";

export function GlobalLoading() {
  const { isSmalldesktop } = useBreakpoints();
  const { open } = useGlobalLoading();

  return (
    <Modal open={open} sx={{ background: "#FFFFFF90" }} disableAutoFocus>
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: isSmalldesktop ? "30%" : "40%",
          left: isSmalldesktop ? "20%" : "40%",

          borderRadius: 2,
          p: 1,
          pointerEvents: "none",
        }}
        className="container"
      >
        <img src={treasureLogo} width={150} height={130} alt="treasurelogo" />
      </Box>
    </Modal>
  );
}
