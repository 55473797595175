import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PhoneForwardedSharpIcon from "@mui/icons-material/PhoneForwardedSharp";
import LogoutIcon from "@mui/icons-material/Logout";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import CottageIcon from "@mui/icons-material/Cottage";
import QuizSharpIcon from "@mui/icons-material/QuizSharp";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MenuIcon from "@mui/icons-material/Menu";
import LoginIcon from "@mui/icons-material/Login";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import useAuth from "../../../hooks/context/useAuth";
import { Logo } from "../Logo";
import ConnectingAirportsIcon from "@mui/icons-material/ConnectingAirports";

interface Props {
  navigation(where: string, tab: number): void;
  activeTab: number;
}

export function MenuDrawer({ navigation, activeTab }: Props) {
  const navigate = useNavigate();
  const { isMobile } = useBreakpoints();
  const { logout, user, token } = useAuth();

  const [open, setOpen] = useState(false);
  const isLogged = user && token ? true : false;
  const paths = [
    {
      name: "Home",
      path: "/home",
      icon: <CottageIcon />,
      menuTitle: "Store",
    },
    {
      name: "Products",
      path: "/products",
      icon: <CardGiftcardIcon />,
    },
    {
      name: "Sale",
      path: "/sale",
      icon: <LoyaltyIcon />,
    },
    {
      name: "Contact us",
      path: "/contact",
      icon: <PhoneForwardedSharpIcon />,
    },
    {
      name: "Faq",
      path: "/faq",
      icon: <QuizSharpIcon />,
    },
    {
      name: "Proxy",
      path: "/proxy",
      icon: <ConnectingAirportsIcon />,
    },
  ].concat(
    isLogged
      ? [
          {
            name: "My info",
            path: "/user/my-info",
            icon: <AccountCircleSharpIcon />,
            menuTitle: `${user?.user?.name.split(" ")[0]} account`,
          },
          {
            name: "Favorites",
            path: "/user/favorites",
            icon: <FavoriteIcon />,
          },
        ]
      : []
  );

  const pathsAdmin = [
    {
      name: "WEBSITE INFO",
      path: "/admin/users",
      icon: <SpaceDashboardIcon />,
      menuTitle: "Main",
    },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const filterRoutesByUser = (role: "client" | "admin") => {
    if (role === "client") return paths;
    if (role === "admin") return pathsAdmin;
    return paths;
  };

  return (
    <Box sx={{ overflowY: "auto" }}>
      <Box>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          sx={{
            width: 240,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 240,
              boxSizing: "border-box",
            },
          }}
          variant={"temporary"}
          anchor="left"
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
          open={open}
        >
          <Box
            onKeyDown={handleDrawerClose}
            display={"flex"}
            justifyContent={"space-between"}
            height={"72px"}
          >
            {isLogged ? (
              <Button
                variant="text"
                onClick={() => {
                  navigate("/home");
                  logout();
                }}
              >
                <LogoutIcon style={{ marginRight: 3, color: "red" }} /> Logout
              </Button>
            ) : (
              <Button
                variant="text"
                onClick={() => {
                  navigate("/user/login");
                }}
              >
                <LoginIcon style={{ marginRight: 3, color: "#ed2623" }} /> Login
              </Button>
            )}
            <IconButton onClick={handleDrawerClose}>
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Box>
          {/* <Divider /> */}

          <Divider />
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"center"}
            mb={-1.5}
          >
            <Logo />
          </Box>
          <List>
            {filterRoutesByUser(user?.user?.role).map((item, index) => (
              <ListItem
                key={index}
                disablePadding
                onClick={() => {
                  navigation(item.path, index);

                  if (isMobile) {
                    handleDrawerClose();
                  }
                }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflowX: "hidden",
                }}
              >
                {item.menuTitle && (
                  <>
                    <Divider
                      sx={{
                        border: "0.5 solid lightgray",
                        mt: 1,
                        mb: 1,
                        width: "95% ",
                      }}
                    />
                    <Typography width={"100%"} ml={1} mb={1}>
                      {item.menuTitle}
                    </Typography>
                  </>
                )}
                <ListItemButton
                  sx={{
                    background: activeTab === index ? "#ed2623" : "transparent",
                    width: "100%",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: activeTab === index ? "white" : "inherit",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color: activeTab === index ? "white" : "inherit",
                    }}
                    primary={item.name}
                  />
                  {activeTab === index && (
                    <PlayArrowIcon
                      sx={{
                        fill: "white",
                        fontSize: 30,
                        rotate: "58deg",
                        marginRight: -3.5,
                      }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </SwipeableDrawer>
      </Box>
    </Box>
  );
}
