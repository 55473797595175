import { Box, Typography } from "@mui/material";
import { ContactInfo } from "../../AtomicDesign/Molecules";
import { grey } from "@mui/material/colors";
import { Header } from "../Header";
import { GlobalContainer } from "../../AtomicDesign/Atoms";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export function MainLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // if (user && token) {
  //   if (user.user.role === "admin") {
  //     navigate("/treasurebox-app/admin");
  //   } else {
  //     navigate("/treasurebox-app");
  //   }
  // }

  useEffect(() => {
    if (pathname === "/") {
      navigate("/home?tab=0");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      minWidth={"100%"}
      minHeight={"100vh"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Box sx={{ width: "100%" }}>
        <Header />
      </Box>
      {/* <Container
        maxWidth={"xl"}
        sx={{
          mt: 2,
          mb: 2,
          display: "flex",
          justifyContent: "center",
        }}
      >
       
      </Container> */}
      <Box sx={{ background: grey[900], width: "100%", mt: 3 }}>
        <GlobalContainer sx={{ mt: 2, mb: 2 }}>
          <ContactInfo />
          <Typography textAlign={"center"} mt={10} color={"#FFF"}>
            {String(new Date().getFullYear())} © Treasure Box
          </Typography>
        </GlobalContainer>
      </Box>
    </Box>
  );
}
