import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { RoutesTreasureBox } from "./routes";
import { AuthProvider } from "./hooks/context/useAuth";
import { Toast } from "./components/AtomicDesign/Organisms";
import { ToastProvider } from "./hooks/context/useToast";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { HeaderProvider } from "./hooks/context/useHeader";
import { ProductsProvider } from "./hooks/context/useProducts";
import { GlobalLoadingProvider } from "./hooks/context/useGlobalLoading";
import { GlobalLoading } from "./components/AtomicDesign/Molecules";
import { CartProvider } from "./hooks/context/useCart";
import { WarningMessage } from "./components/AtomicDesign/Atoms";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalLoadingProvider>
        <AuthProvider>
          <HeaderProvider>
            <ToastProvider>
              <ProductsProvider>
                <CartProvider>
                  <RoutesTreasureBox />
                  <Toast />
                  <WarningMessage />
                </CartProvider>
              </ProductsProvider>
            </ToastProvider>
          </HeaderProvider>
        </AuthProvider>
        <GlobalLoading />
      </GlobalLoadingProvider>
    </ThemeProvider>
  );
}

export default App;
