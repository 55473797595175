import { Box, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { amber, grey } from "@mui/material/colors";
import {
  FadeInContainer,
  GlobalContainer,
} from "../../../components/AtomicDesign/Atoms";
import { UpdateMyUser } from "../../../components/AtomicDesign/Organisms";

import useAuth from "../../../hooks/context/useAuth";
import { useLocation } from "react-router-dom";
import { MyOrders } from "../componentsPage/MyOrders";

import { PersonalStorage } from "../componentsPage/PersonalStorage";
import { Shippments } from "../componentsPage/Shippments";
import { ShippmentsProvider } from "../../../hooks/context/useShippments";

export function MyInfo() {
  const [activeTab, setActiveTab] = useState(2);
  const [selectedColor, setColor] = useState("");
  const [userInfo, setUserInfo] = useState({} as User);
  const { getUser, user } = useAuth();
  const { pathname } = useLocation();

  const getUserData = async (id: number) => {
    const userGet = (await getUser(id)) as any;
    setUserInfo(userGet);
  };

  useEffect(() => {
    setColor("#FFF");
  }, [pathname]);

  useEffect(() => {
    if (user?.user?.id && activeTab === 0) {
      getUserData(user?.user?.id);
    }

    //eslint-disable-next-line
  }, [activeTab, user]);

  return (
    <GlobalContainer sx={{ p: 0 }}>
      <FadeInContainer>
        <Tabs
          value={activeTab}
          onChange={(_e, value) => setActiveTab(value)}
          aria-label="disabled tabs example"
          variant={"fullWidth"}
          TabIndicatorProps={{
            style: { background: amber[500], color: "red" },
          }}
          sx={{
            "& .Mui-selected": {
              background: "#ed2623",
              borderRadius: "5px 5px 0 0",
              color: selectedColor,
            },
            "& .MuiTab-root": {
              fontSize: 12,
              "&: hover": {
                background: grey[200],
                borderRadius: "5px 5px 0 0",
              },
              "&: focus": {
                background: "#ed2623",
              },
            },
          }}
        >
          <Tab label="My info" value={0} onClick={() => setActiveTab(0)} />
          <Tab label="My orders" value={2} onClick={() => setActiveTab(2)} />
          <Tab
            label="Personal storage"
            value={1}
            onClick={() => setActiveTab(1)}
          />
          <Tab
            label="My shippments"
            value={3}
            onClick={() => setActiveTab(3)}
          />
        </Tabs>
        <Box display={"flex"} justifyContent={"center"}>
          {activeTab === 0 && <UpdateMyUser userData={userInfo} />}
          {activeTab === 2 && <MyOrders />}
          {activeTab === 1 && <PersonalStorage />}
          {activeTab === 3 && (
            <ShippmentsProvider>
              <Shippments />
            </ShippmentsProvider>
          )}
        </Box>
      </FadeInContainer>
    </GlobalContainer>
  );
}
