import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useBreakpoints } from "../../../../hooks/useBreakpoins";

interface Props {
  onChange: (val: Date) => void;
  value: Date;
}

export function CustomDatePicker({ onChange, value }: Props) {
  const { isSmalldesktop } = useBreakpoints();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        sx={{
          width: "95%",
          ml: isSmalldesktop ? 0 : 0.5,
        }}
        slotProps={{ textField: { variant: "standard" } }}
        label="Pre-order deadline"
        value={value}
        onChange={(newValue) => onChange(newValue)}
      />
    </LocalizationProvider>
  );
}
