import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import {
  FadeInContainer,
  SimpleTextBox,
} from "../../../../components/AtomicDesign/Atoms";

export function PreorderCard({
  preOrderData,
  payStatus,
}: {
  preOrderData: PreOrderConfirmationPayment;
  payStatus: string;
}) {
  const nav = useNavigate();

  const [seconds, setSeconds] = useState(20);

  useEffect(() => {
    let interval = undefined;
    if (preOrderData) {
      interval = setInterval(
        () =>
          setSeconds((old) => {
            if (seconds <= 0) return 0;
            else return old - 1;
          }),
        1000
      );
      if (seconds === 0) {
        nav("/user/my-info");
        // setSeconds(20);
      }
    } else {
      setSeconds(20);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [preOrderData, seconds]);

  return (
    <Box width={"100%"}>
      <FadeInContainer>
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
          width={"100%"}
          sx={{
            border: "1px dotted #b5821b",
            background: "#ffab000f",
            borderRadius: 2,
            mb: 3,
            pt: 1,
            pb: 1,
          }}
        >
          <Typography
            variant="h1"
            textAlign={"center"}
            mb={3}
            color={"#b5821b"}
          >
            I'll redirect you to your orders page in {seconds} seconds
          </Typography>
          <Typography variant="h1" textAlign={"center"} color={"#b5821b"}>
            Your pre-order payment status is {payStatus}
          </Typography>
        </Box>
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img
            src={preOrderData?.Products?.productsImages[0]?.code}
            alt="product"
            width={300}
            style={{ borderRadius: 5 }}
          />
          <SimpleTextBox
            titleColor="black"
            title={preOrderData?.Products?.characterName}
            description={preOrderData?.Products?.originName}
          />
        </Box>
      </FadeInContainer>
    </Box>
  );
}
