import { Box, Divider, Typography } from "@mui/material";
import {
  Carrousel,
  PopularCategories,
} from "../../../components/AtomicDesign/Molecules";
import { useEffect, useState } from "react";
import { getHomeInfo } from "../../../services/productsService";

import { useBreakpoints } from "../../../hooks/useBreakpoins";
import { ProductList } from "../../../components/AtomicDesign/Organisms";
import { grey, red } from "@mui/material/colors";
import useGlobalLoading from "../../../hooks/context/useGlobalLoading";

type ProductWithoutCategories = Omit<
  ProductData,
  "Manufacturers" | "Versions" | "Origin"
>[];

export function Home() {
  const { setOpenLoading } = useGlobalLoading();
  const { isSmalldesktop } = useBreakpoints();
  const [homeInfo, setHomeInfo] = useState(
    {} as {
      mostAccess: ProductWithoutCategories;
      latestPrds: ProductWithoutCategories;
      carrousel: Banner[];
      productCategories: {
        manufacturers: ProductsType[];
        versions: ProductsType[];
        origins: ProductsType[];
        productType: ProductsType[];
      };
    }
  );

  const getInfo = async () => {
    setOpenLoading(true);
    try {
      const response = await getHomeInfo();
      setHomeInfo(response);
    } catch {
    } finally {
      setOpenLoading(false);
    }
  };

  useEffect(() => {
    getInfo();

    // eslint-disable-next-line
  }, []);

  const Categories = () => (
    <Box
      display="flex"
      flexDirection={"column"}
      width={isSmalldesktop ? "95%" : "19.5%"}
      gap={1}
      mt={1}
    >
      <PopularCategories
        list={homeInfo?.productCategories?.productType}
        linkTo="productTypeName"
        title="product types"
      />
      <PopularCategories
        list={homeInfo?.productCategories?.manufacturers}
        linkTo="manufacturerName"
        title="manufacturers"
      />
      <PopularCategories
        list={homeInfo?.productCategories?.origins}
        linkTo="originName"
        title="origins"
      />
      <PopularCategories
        list={homeInfo?.productCategories?.versions}
        linkTo="versionName"
        title="versions"
      />
    </Box>
  );

  const CarrouselCp = () => (
    <Box width={"100%"} mt={1}>
      <Carrousel list={homeInfo?.carrousel} />
    </Box>
  );

  const HotProducts = () => (
    <Box width={isSmalldesktop ? "95%" : "100%"}>
      <Typography ml={2} color={red[400]} fontSize={24} fontWeight={500}>
        Hot items
      </Typography>
      <Divider sx={{ mb: 2, ml: 2 }} />
      <Box
        width={"100%"}
        justifyContent={isSmalldesktop ? "center" : "row"}
        display={"flex"}
        flexWrap={"wrap"}
        gap={5}
        ml={isSmalldesktop ? 1 : 2}
      >
        <ProductList list={homeInfo?.mostAccess} hasRanking />
      </Box>
    </Box>
  );

  const LatestProducts = () => (
    <Box width={isSmalldesktop ? "95%" : "100%"}>
      <Typography ml={2} color={grey[600]} fontSize={24}>
        Latest added products
      </Typography>
      <Divider sx={{ mb: 2, ml: 2 }} />
      <Box
        width={"100%"}
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={isSmalldesktop ? "center" : "row"}
        gap={5}
        ml={isSmalldesktop ? 1 : 2}
      >
        <ProductList list={homeInfo?.latestPrds} />
      </Box>
    </Box>
  );

  return (
    <Box
      display={"flex"}
      width={"100%"}
      alignItems={"flex-start"}
      flexDirection={isSmalldesktop ? "column" : "row"}
    >
      {isSmalldesktop ? (
        <>
          <CarrouselCp />
          <Box width={"100%"}>
            <HotProducts />
            <LatestProducts />
          </Box>
          <Categories />
        </>
      ) : (
        <>
          <Categories />
          <Box width={"100%"}>
            <CarrouselCp />
            <HotProducts />
            <LatestProducts />
          </Box>
        </>
      )}
    </Box>
  );
}
