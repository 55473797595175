import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import useProducts from "../../../../hooks/context/useProducts";
import {
  createSearchParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { CustomModal } from "../../Organisms";
import { useState } from "react";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { CustomButton } from "../Buttons";
import { colors } from "../../../../theme/colors";
import { FormControlMenu } from "./FormControl";

export function LateralFilters() {
  const { productsDataMemo, productsfilters } = useProducts();
  const { isSmalldesktop } = useBreakpoints();
  const location = useLocation();

  const [params, setSearchParams] = useSearchParams();
  const [openModal, setOpenModal] = useState(false);
  const manufacturerNow = params.get("manufacturerName");
  const versionNow = params.get("versionName");
  const originNow = params.get("originName");
  const nameNow = params.get("name");
  const tabNow = params.get("tab");
  const inStockNow = params.get("inStock");
  const outOfStockNow = params.get("outOfStock");
  const productTypeNow = params.get("productTypeName");
  const preOrderNow = params.get("preOrder");
  const preOwnedNow = params.get("preOwned");

  const Radios = () => (
    <>
      {location.pathname !== "/sale" && (
        <FormControl>
          <FormLabel
            id="quantity-labels"
            sx={{ height: 36, display: "flex", alignItems: "center" }}
          >
            Stock
            <Button
              variant="text"
              onClick={() =>
                setSearchParams(
                  createSearchParams({
                    ...(nameNow && {
                      name: nameNow,
                    }),
                    ...(manufacturerNow && {
                      manufacturerName: manufacturerNow,
                    }),
                    ...(versionNow && {
                      versionName: versionNow,
                    }),
                    ...(originNow && {
                      originName: originNow,
                    }),

                    outOfStock: undefined,
                    preOrder: undefined,
                    inStock: undefined,
                    originName: undefined,
                    preOwned: undefined,
                    tab: String(tabNow),
                  })
                )
              }
            >
              X
            </Button>
          </FormLabel>

          <RadioGroup>
            <FormControlLabel
              value={true}
              control={<Radio />}
              label={"Pre-Order"}
              onChange={() =>
                setSearchParams(
                  createSearchParams({
                    ...(nameNow && {
                      name: nameNow,
                    }),
                    ...(manufacturerNow && {
                      manufacturerName: manufacturerNow,
                    }),
                    ...(versionNow && {
                      versionName: versionNow,
                    }),
                    // ...(inStockNow && {
                    //   inStock: inStockNow,
                    // }),
                    // ...(outOfStockNow && {
                    //   outOfStock: outOfStockNow,
                    // }),
                    ...(originNow && {
                      originName: originNow,
                    }),
                    preOrder: String(true),
                    inStock: undefined,
                    outOfStock: undefined,
                    preOwned: undefined,
                    tab: String(tabNow),
                  })
                )
              }
              checked={preOrderNow && preOrderNow !== "undefined"}
            />

            <FormControlLabel
              value={true}
              control={<Radio />}
              label={"Pre-Owned"}
              onChange={() =>
                setSearchParams(
                  createSearchParams({
                    ...(nameNow && {
                      name: nameNow,
                    }),
                    ...(manufacturerNow && {
                      manufacturerName: manufacturerNow,
                    }),
                    ...(versionNow && {
                      versionName: versionNow,
                    }),
                    // ...(inStockNow && {
                    //   inStock: inStockNow,
                    // }),
                    // ...(outOfStockNow && {
                    //   outOfStock: outOfStockNow,
                    // }),
                    ...(originNow && {
                      originName: originNow,
                    }),
                    preOrder: undefined,
                    inStock: undefined,
                    outOfStock: undefined,
                    preOwned: String(true),
                    tab: String(tabNow),
                  })
                )
              }
              checked={preOwnedNow && preOwnedNow !== "undefined"}
            />

            <FormControlLabel
              value={true}
              control={<Radio />}
              label={"In-Stock"}
              onChange={() =>
                setSearchParams(
                  createSearchParams({
                    ...(nameNow && {
                      name: nameNow,
                    }),
                    ...(manufacturerNow && {
                      manufacturerName: manufacturerNow,
                    }),
                    ...(versionNow && {
                      versionName: versionNow,
                    }),
                    // ...(inStockNow && {
                    //   inStock: inStockNow,
                    // }),
                    // ...(outOfStockNow && {
                    //   outOfStock: outOfStockNow,
                    // }),
                    ...(originNow && {
                      originName: originNow,
                    }),
                    preOrder: undefined,
                    inStock: String(true),
                    outOfStock: undefined,
                    preOwned: undefined,
                    tab: String(tabNow),
                  })
                )
              }
              checked={inStockNow && inStockNow !== "undefined"}
            />

            <FormControlLabel
              value={true}
              control={<Radio />}
              label={"Out-of-Stock"}
              onChange={() =>
                setSearchParams(
                  createSearchParams({
                    ...(nameNow && {
                      name: nameNow,
                    }),
                    ...(manufacturerNow && {
                      manufacturerName: manufacturerNow,
                    }),
                    ...(versionNow && {
                      versionName: versionNow,
                    }),
                    // ...(inStockNow && {
                    //   inStock: inStockNow,
                    // }),
                    // ...(outOfStockNow && {
                    //   outOfStock: outOfStockNow,
                    // }),
                    ...(originNow && {
                      originName: originNow,
                    }),
                    preOrder: undefined,
                    inStock: undefined,
                    outOfStock: String(true),
                    preOwned: undefined,
                    tab: String(tabNow),
                  })
                )
              }
              checked={outOfStockNow && outOfStockNow !== "undefined"}
            />
          </RadioGroup>
        </FormControl>
      )}

      <FormControlMenu
        menuName="Type"
        active={productsfilters.productTypeName ? true : false}
        onRemove={() =>
          setSearchParams(
            createSearchParams({
              ...(nameNow && {
                name: nameNow,
              }),
              ...(manufacturerNow && {
                manufacturerName: manufacturerNow,
              }),
              ...(versionNow && {
                versionName: versionNow,
              }),
              ...(inStockNow && {
                inStock: inStockNow,
              }),
              ...(outOfStockNow && {
                outOfStock: outOfStockNow,
              }),
              ...(preOrderNow && {
                preOrder: preOrderNow,
              }),
              ...(originNow && {
                originName: originNow,
              }),
              productTypeName: undefined,
              tab: String(tabNow),
            })
          )
        }
        checked={productTypeNow}
        list={productsDataMemo?.productType}
        onChange={(item) =>
          setSearchParams(
            createSearchParams({
              ...(nameNow && {
                name: nameNow,
              }),
              ...(manufacturerNow && {
                manufacturerName: manufacturerNow,
              }),
              ...(versionNow && {
                versionName: versionNow,
              }),
              ...(inStockNow && {
                inStock: inStockNow,
              }),
              ...(outOfStockNow && {
                outOfStock: outOfStockNow,
              }),
              ...(preOrderNow && {
                preOrder: preOrderNow,
              }),
              ...(originNow && {
                originName: originNow,
              }),
              productTypeName: item?.name,
              tab: String(tabNow),
            })
          )
        }
      />

      <FormControlMenu
        menuName="Origin"
        active={productsfilters.originName ? true : false}
        onRemove={() =>
          setSearchParams(
            createSearchParams({
              ...(nameNow && {
                name: nameNow,
              }),
              ...(manufacturerNow && {
                manufacturerName: manufacturerNow,
              }),
              ...(versionNow && {
                versionName: versionNow,
              }),
              ...(inStockNow && {
                inStock: inStockNow,
              }),
              ...(outOfStockNow && {
                outOfStock: outOfStockNow,
              }),
              ...(preOrderNow && {
                preOrder: preOrderNow,
              }),
              originName: undefined,
              tab: String(tabNow),
            })
          )
        }
        checked={originNow}
        list={productsDataMemo?.origins}
        onChange={(item) =>
          setSearchParams(
            createSearchParams({
              ...(nameNow && {
                name: nameNow,
              }),
              ...(manufacturerNow && {
                manufacturerName: manufacturerNow,
              }),
              ...(versionNow && {
                versionName: versionNow,
              }),
              ...(inStockNow && {
                inStock: inStockNow,
              }),
              ...(outOfStockNow && {
                outOfStock: outOfStockNow,
              }),
              ...(preOrderNow && {
                preOrder: preOrderNow,
              }),
              originName: item?.name,
              tab: String(tabNow),
            })
          )
        }
      />

      <FormControlMenu
        menuName="Manufacturers"
        active={productsfilters.manufacturerName ? true : false}
        onRemove={() =>
          setSearchParams(
            createSearchParams({
              ...(nameNow && {
                name: nameNow,
              }),
              ...(originNow && {
                originName: originNow,
              }),
              ...(versionNow && {
                versionName: versionNow,
              }),
              ...(inStockNow && {
                inStock: inStockNow,
              }),
              ...(outOfStockNow && {
                outOfStock: outOfStockNow,
              }),
              ...(preOrderNow && {
                preOrder: preOrderNow,
              }),
              manufacturerName: undefined,
              tab: String(tabNow),
            })
          )
        }
        checked={manufacturerNow}
        list={productsDataMemo?.manufacturers}
        onChange={(item) =>
          setSearchParams(
            createSearchParams({
              ...(nameNow && {
                name: nameNow,
              }),
              ...(originNow && {
                originName: originNow,
              }),
              ...(versionNow && {
                versionName: versionNow,
              }),
              ...(inStockNow && {
                inStock: inStockNow,
              }),
              ...(outOfStockNow && {
                outOfStock: outOfStockNow,
              }),
              ...(preOrderNow && {
                preOrder: preOrderNow,
              }),
              manufacturerName: item?.name,
              tab: String(tabNow),
            })
          )
        }
      />

      <FormControlMenu
        menuName="Versions"
        active={productsfilters.versionName ? true : false}
        onRemove={() =>
          setSearchParams(
            createSearchParams({
              ...(nameNow && {
                name: nameNow,
              }),
              ...(originNow && {
                originName: originNow,
              }),
              ...(manufacturerNow && {
                manufacturerName: manufacturerNow,
              }),
              ...(inStockNow && {
                inStock: inStockNow,
              }),
              ...(outOfStockNow && {
                outOfStock: outOfStockNow,
              }),
              ...(preOrderNow && {
                preOrder: preOrderNow,
              }),
              versionName: undefined,
              tab: String(tabNow),
            })
          )
        }
        checked={versionNow}
        list={productsDataMemo?.versions}
        onChange={(item) =>
          setSearchParams(
            createSearchParams({
              ...(nameNow && {
                name: nameNow,
              }),
              ...(originNow && {
                originName: originNow,
              }),
              ...(manufacturerNow && {
                manufacturerName: manufacturerNow,
              }),
              ...(inStockNow && {
                inStock: inStockNow,
              }),
              ...(outOfStockNow && {
                outOfStock: outOfStockNow,
              }),
              ...(preOrderNow && {
                preOrder: preOrderNow,
              }),
              versionName: item?.name,
              tab: String(tabNow),
            })
          )
        }
      />
    </>
  );
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems={isSmalldesktop ? "center" : "flex-start"}
      gap={3}
      maxWidth={isSmalldesktop ? "100%" : "17.5%"}
      minWidth={isSmalldesktop ? "100%" : "17.5%"}
      mt={2}
      sx={{
        border: isSmalldesktop ? "none" : "1px dotted black",
        p: isSmalldesktop ? 0 : 2,
        borderRadius: 5,
      }}
    >
      {!isSmalldesktop && (
        <Box
          sx={{
            background: colors.blue[900],
            width: "100%",
            borderRadius: 5,
          }}
        >
          <Typography
            sx={{ color: "#FFFFFF", p: 1.5, textAlign: "center", fontSize: 18 }}
          >
            Filter
          </Typography>
        </Box>
      )}
      {isSmalldesktop ? (
        <>
          <CustomButton
            btnType="share"
            style={{ width: "90%", marginBottom: 8 }}
            onClick={() => setOpenModal(true)}
          >
            Add refined condition
          </CustomButton>
          <CustomModal
            width={"90%"}
            openModal={openModal}
            setOpenModal={setOpenModal}
            title="Filter"
          >
            <Box px={3} py={1} display={"flex"} flexDirection={"column"}>
              <Radios />
            </Box>
          </CustomModal>
        </>
      ) : (
        <Radios />
      )}
    </Box>
  );
}
