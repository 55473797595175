import { api } from "./api";

export const getOneProductService = async (
  productID: number,
  userID: number
) => {
  const response2 = await api.get("/get-one-product", {
    params: { productID, userID },
  });
  return response2.data;
};

export const getProductsListService = async (filters: ProductsFilters) => {
  const response = await api.get("/list-all-products", {
    params: { ...filters },
  });
  return response.data;
};

export const getProductOptionsService = async () => {
  const response = await api.get("/get-all-product-options");
  return response.data;
};

export const createManufacturer = async (name: string) => {
  const response = await api.post("/create-manufacturer", { name });
  return response.data;
};

export const updateManufacturer = async (data: UpdateManufacturer) => {
  const response = await api.post("/update-manufacturer", { ...data });
  return response.data;
};

export const createOrigin = async (name: string) => {
  const response = await api.post("/create-origin", { name });
  return response.data;
};

export const updateOrigin = async (data: UpdateOrigin) => {
  const response = await api.post("/update-origin", { ...data });
  return response.data;
};

export const createProductType = async (name: string) => {
  const response = await api.post("/create-product-type", { name });
  return response.data;
};

export const updateProductType = async (data: UpdateProductType) => {
  const response = await api.post("/update-product-type", { ...data });
  return response.data;
};

export const createVersion = async (name: string) => {
  const response = await api.post("/create-version", { name });
  return response.data;
};

export const updateVersion = async (data: UpdateVersion) => {
  const response = await api.post("/update-version", { ...data });
  return response.data;
};

export const createProductService = async (data: SubmitProduct) => {
  const {
    file,
    characterName,
    description,
    isPreOrder,
    isSale,
    manufacturerName,
    canGoSmallPacket,
    manufacturersId,
    productTypeId,
    productTypeName,
    originId,
    originName,
    preOrderDeadline,
    price,
    stock,
    versionName,
    versionsId,
    isPreOwned,
    weight,
  } = data;

  try {
    const response = await api.post("/create-product", {
      characterName,
      description,
      isPreOrder,
      isSale,
      manufacturerName,
      canGoSmallPacket,
      manufacturersId,
      productTypeId,
      productTypeName,
      originId,
      originName,
      isPreOwned,
      preOrderDeadline,
      price,
      stock,
      versionName,
      versionsId,
      weight,
    });
    await api.post(
      "/create-product-images",
      { productID: response.data, file },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } catch (err: any) {
    throw new Error(err);
  }
};

export const delImageFromProduct = async (imageID: number) => {
  const response = await api.delete("/delete-product-images", {
    params: { imageID },
  });
  return response.data;
};

export const updateProductService = async (data: UpdateProduct) => {
  try {
    const response = await api.post("/update-product", { ...data });

    if (data.file.length > 0) {
      await api.post(
        "/create-product-images",
        { productID: data.productID, file: data.file },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    }

    return response.data;
  } catch (err: any) {
    throw new Error(err);
  }
};

export const getHomeInfo = async () => {
  const response = await api.get("/get-home-info");
  return response.data;
};
