import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { CustomModal } from "../../Organisms";
import { green, red } from "@mui/material/colors";
import { useCommonData } from "../../../../hooks/useCommonData";

interface Props {
  open: boolean;
  text: string;
  confirmClick: VoidFunction;
  cancelClick: VoidFunction;
  isLoading?: boolean;
}

export function SimpleConfirmModal({
  open,
  text,
  confirmClick,
  cancelClick,
  isLoading = false,
}: Props) {
  const { isSmalldesktop } = useBreakpoints();
  const { adminOrder } = useCommonData();
  return (
    <CustomModal
      openModal={open}
      setOpenModal={cancelClick}
      width={isSmalldesktop ? "90%" : "30%"}
    >
      <Box display={"flex"} justifyContent={"center"} mb={5} width={"100%"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"100%"}
        >
          <Typography textAlign={"center"} px={5} py={2}>
            {text}
          </Typography>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box display={"flex"} justifyContent={"center"} gap={5}>
              <Button
                sx={{ bgcolor: green[500], color: "white" }}
                style={{ width: 100 }}
                onClick={confirmClick}
              >
                {adminOrder.confirmBtn}
              </Button>
              <Button
                sx={{ bgcolor: red[500], color: "white" }}
                style={{ width: 100 }}
                onClick={cancelClick}
              >
                {adminOrder.cancelBtn}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </CustomModal>
  );
}
