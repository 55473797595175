import { createContext, useContext, useEffect, useMemo, useState } from "react";
import useToast from "./useToast";

import useGlobalLoading from "./useGlobalLoading";
import useAuth from "./useAuth";
import {
  addToMyCardSevice,
  getMyCartSevice,
  removeFromMyCartSevice,
  updateMyCartSevice,
} from "../../services/cartService";

const CartContext = createContext({} as ICartContext);

type Props = {
  children: React.ReactNode;
};

function CartProvider({ children }: Props) {
  const { setOpenLoading } = useGlobalLoading();
  const { setOpen, setToastData } = useToast();
  const { user } = useAuth();
  const [cartData, setCartData] = useState([] as Cart[]);

  const [loading, setLoading] = useState({
    cartLoading: false,
    addToCartLoading: false,
    updateCart: false,
    removeFromCartLoading: false,
  });

  const cartDataMemo: Cart[] = useMemo(() => cartData, [cartData]);

  const getCart = async (globalLoading?: boolean) => {
    if (globalLoading) {
      setOpenLoading(true);
    } else {
      setLoading((old) => ({ ...old, cartLoading: true }));
    }

    try {
      const response = await getMyCartSevice(user?.user?.id);
      setCartData(response);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: "An error occurred while getting your cart.",
      });
    } finally {
      if (globalLoading) {
        setOpenLoading(false);
      } else {
        setLoading((old) => ({ ...old, cartLoading: false }));
      }
      // setTimeout(() => setOpenLoading(false), 5000);
    }
  };

  const addToMyCart = async (data: ProductCart, getMyCart?: boolean) => {
    setLoading((old) => ({ ...old, addToCartLoading: true }));
    setOpenLoading(true);
    try {
      await addToMyCardSevice(data);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: "Successfully add item to your cart",
      });
      setOpen(true);
      return true;
    } catch (err: any) {
      const filterError = {
        5: "Your cart has exceeded the maximum number of items",
        6: "An error occurred while add product to your cart, please try again.",
        7: "Item limit has been exceeded",
      };

      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: filterError[err?.response?.data?.error],
      });
      setOpen(true);
      return false;
    } finally {
      if (getMyCart) {
        getCart();
      }
      setOpenLoading(false);
      setLoading((old) => ({ ...old, addToCartLoading: false }));
    }
  };

  const updateCart = async (data: ProductCart) => {
    setLoading((old) => ({ ...old, updateCart: true }));
    setOpenLoading(true);
    try {
      await updateMyCartSevice(data);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: "Successfully updated cart",
      });
      setOpen(true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message:
          "An error occurred while updating your cart, please try again.",
      });
      setOpen(true);
    } finally {
      setOpenLoading(false);
      setLoading((old) => ({ ...old, updateCart: true }));
      getCart();
    }
  };

  const removeFromMyCart = async (data: ProductCart) => {
    setLoading((old) => ({ ...old, removeFromCartLoading: true }));
    setOpenLoading(true);
    try {
      await removeFromMyCartSevice(data);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: "Successfully removed the item from your cart",
      });
      setOpen(true);
    } catch (err: any) {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message:
          "An error occurred while removeing item from your cart, please try again.",
      });
      setOpen(true);
    } finally {
      setOpenLoading(false);
      setLoading((old) => ({ ...old, removeFromCartLoading: true }));
      getCart();
    }
  };

  useEffect(() => {
    if (user?.user?.id) {
      getCart();
    }
    // eslint-disable-next-line
  }, [user?.user?.id]);

  return (
    <CartContext.Provider
      value={{
        cartDataMemo,
        addToMyCart,
        updateCart,
        removeFromMyCart,
        getCart,
        loading,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export { CartProvider, CartContext };

export default function useCart() {
  return useContext(CartContext);
}
