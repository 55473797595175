import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { FadeInContainer } from "../../../../components/AtomicDesign/Atoms";
import { colors } from "../../../../theme/colors";
import { grey } from "@mui/material/colors";
import { filterByStatus } from "../../../MyInfo/componentsPage/Shippments/components/labels";
import { useDateTime } from "../../../../hooks/useDateTime";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";

export function ShippmentCard({
  shippmentData,
  payStatus,
}: {
  shippmentData: ShippmentData;
  payStatus: string;
}) {
  const nav = useNavigate();
  const { formatedDate } = useDateTime();
  const { JPYmoney } = useJPYmoney();
  const [seconds, setSeconds] = useState(20);

  useEffect(() => {
    let interval = undefined;
    if (shippmentData) {
      interval = setInterval(
        () =>
          setSeconds((old) => {
            if (seconds <= 0) return 0;
            else return old - 1;
          }),
        1000
      );
      if (seconds === 0) {
        nav("/user/my-info");
        // setSeconds(20);
      }
    } else {
      setSeconds(20);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [shippmentData, seconds]);

  const UserAddress = () => {
    const addressfind = shippmentData?.users?.address?.find(
      (adr) => adr.id === shippmentData?.addressID
    );
    if (addressfind) {
      const {
        address,
        adressNumber,
        country,
        city,
        state,
        addressComplement,
        neighborhood,
        postalCode,
        receiver,
        ssn,
      } = addressfind;
      return (
        <Box display={"flex"} flexDirection={"column"} gap={1} width={245}>
          <Typography
            sx={{
              background: colors.blue[900],
              color: "#FFFFFF",
              borderRadius: 2,
              p: 1,
            }}
          >
            Address info
          </Typography>
          <Typography>
            Address: {address} - {adressNumber}
          </Typography>
          <Typography>Country: {country}</Typography>
          <Typography>
            City: {city} - {state}
          </Typography>
          <Typography>Complement: {addressComplement}</Typography>
          <Typography>Neighborhood: {neighborhood}</Typography>
          <Typography>Postal Code: {postalCode}</Typography>
          <Typography>Receiver: {receiver}</Typography>
          <Typography>SSN: {ssn}</Typography>
        </Box>
      );
    } else {
      return <></>;
    }
  };

  const formatShipType = (choice: ShipTypes) => {
    const types = {
      smallPacket: "Small Packet",
      EMS: "EMS",
      surface: "Sal Parcel",
      airParcel: "Air Parcel",
    };
    return types[choice];
  };

  return (
    <Box width={"100%"} p={1}>
      <FadeInContainer>
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
          alignItems={"center"}
          width={"100%"}
          sx={{
            border: "1px dotted #b5821b",
            background: "#ffab000f",
            borderRadius: 2,
            mb: 3,
            pt: 1,
            pb: 1,
          }}
        >
          <Typography
            variant="h1"
            textAlign={"center"}
            mb={3}
            color={"#b5821b"}
          >
            I'll redirect you to your profile in {seconds} seconds
          </Typography>
          <Typography variant="h1" textAlign={"center"} color={"#b5821b"}>
            Your shipment payment status is {payStatus}
          </Typography>
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-around"}
          flexWrap={"wrap"}
          gap={2}
        >
          <Box
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent={"space-evenly"}
            gap={3}
          >
            <Box display={"flex"} gap={1} flexDirection={"column"} width={245}>
              <Typography
                sx={{
                  background: colors.blue[900],
                  color: "#FFFFFF",
                  borderRadius: 2,
                  p: 1,
                }}
              >
                Shipment info
              </Typography>
              <Typography>Shipment: {shippmentData?.id}</Typography>
              <Typography>
                Created at: {formatedDate(shippmentData?.createdAt)}
              </Typography>
              <Box
                sx={{
                  borderRadius: 2,
                }}
              >
                <Typography>
                  Shipped: {formatedDate(shippmentData?.shippedDate)}
                </Typography>
              </Box>
              <Typography>
                Price: {JPYmoney(shippmentData?.shippingFee)}
              </Typography>
              <Typography>
                Method: {formatShipType(shippmentData?.shipType)}
              </Typography>
              <Typography>
                Status: {filterByStatus[shippmentData?.status]?.text}
              </Typography>
              <Box
                sx={{
                  borderRadius: 2,
                }}
              >
                <Typography>
                  Tracking code: {shippmentData?.trackingCode}
                </Typography>
              </Box>
            </Box>

            <UserAddress />
          </Box>

          <Typography
            textAlign={"center"}
            width={"90%"}
            p={2}
            sx={{
              background: filterByStatus[shippmentData?.status]?.color,
              color: "#FFFFFF",
              borderRadius: 5,
              mt: 1,
              mb: 2,
            }}
            variant="h1"
          >
            {filterByStatus[shippmentData?.status]?.text}
          </Typography>

          <Box display={"flex"} flexWrap={"wrap"} width={"90%"} p={1} gap={1}>
            {shippmentData?.shippmentXPersonalStorage?.map((prds) => (
              <Box sx={{ border: "1px dotted gray", borderRadius: 2 }}>
                <img
                  src={
                    prds?.PersonalStorageXProduct?.Products?.productsImages[0]
                      ?.code
                  }
                  style={{
                    objectFit: "contain",
                    width: 100,
                    height: 100,
                    borderRadius: 5,
                    background: grey[200],
                  }}
                  alt={prds?.PersonalStorageXProduct?.Products?.characterName}
                />
                <Typography
                  width={100}
                  textAlign={"center"}
                  height={148}
                  overflow={"hidden"}
                >
                  {prds?.PersonalStorageXProduct?.Products?.characterName}
                </Typography>
                {/* <Typography width={100} mt={1}>
              Product id: {prds?.PersonalStorageXProduct?.Products?.id}
            </Typography> */}
                <Typography width={100} mt={1}>
                  Quantity in ship: {prds?.quantity}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </FadeInContainer>
    </Box>
  );
}
