import { amber, blue, green, grey, red, teal } from "@mui/material/colors";

export const filterByStatus = {
  deliverieRequested: { text: "Shipment requested", color: blue[300] },
  waitingPayment: { text: "Waiting payment", color: amber[500] },
  paymentSent: { text: "Payment confirmed", color: teal[500] },
  editDeliveryAddress: { text: "Edit shipment address", color: grey[400] },
  editDeliveryProducts: { text: "Edit shipment products", color: grey[400] },
  canceled: { text: "Canceled", color: red[500] },
  cancelWithRefund: { text: "Canceled with refund", color: red[500] },
  shipped: { text: "Shipped", color: green[500] },
};
