import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { CustomModal } from "../Modal";

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import { grey, red, teal } from "@mui/material/colors";

import useAuth from "../../../../hooks/context/useAuth";
import { PaymentSuccess } from "./PaymentSuccess";
import {
  chargeByStripeSckecout,
  createPreOrders,
} from "../../../../services/cartService";
import useCart from "../../../../hooks/context/useCart";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

interface Props {
  open: boolean;
  setOpen: (val: boolean) => void;
  itensInfo: { totalValue: number; totalItens: number };
  selectedProducts: CartXProduct[];
  cleanStateCart: VoidFunction;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export function PaymentModal({
  open,
  setOpen,
  itensInfo,
  selectedProducts,
  cleanStateCart,
}: Props) {
  const nav = useNavigate();

  const { user } = useAuth();
  const { getCart } = useCart();

  const { isSmalldesktop, isMobile } = useBreakpoints();
  const [chargeID, setChargeID] = useState("");

  const [chargeLoading, setChargeLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [seconds, setSeconds] = useState(10);

  // const handleCreateChargeID = async () => {
  //   setLoading(true);
  //   try {
  //     const { error, paymentMethod } = await stripe.createPaymentMethod({
  //       type: "card",
  //       card: elements.getElement(CardElement),
  //     });

  //     if (!error) {
  //       const { id } = paymentMethod;
  //       setChargeID(id);
  //       setCardVerify(true);
  //     } else {
  //       setChargeID("");
  //       setCardVerify(false);
  //     }
  //   } catch {
  //     setError(true);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchClientSecret = async () => {
    setChargeLoading(true);

    const payload = {
      selectedProducts,
      info: {
        userID: user?.user?.id,
        totalValue: itensInfo.totalValue,
        chargeID,
      },
    };
    // await createCreditCardCharge(payload);
    const options = await chargeByStripeSckecout(payload);

    return options;
    // setPaymentSuccess(true);
    // await getCart();
    // cleanStateCart();
  };

  const makeOnlyPreOrder = async () => {
    try {
      const payload = {
        selectedProducts,
        info: {
          userID: user?.user?.id,
          totalValue: itensInfo.totalValue,
          chargeID,
        },
      };
      await createPreOrders(payload);
      setPaymentSuccess(true);
      cleanStateCart();
      await getCart();
    } catch {}
  };

  useEffect(() => {
    let interval = undefined;
    if (paymentSuccess) {
      interval = setInterval(
        () =>
          setSeconds((old) => {
            if (seconds <= 0) return 0;
            else return old - 1;
          }),
        1000
      );
      if (seconds === 0) {
        nav("/user/my-info");
      }
    } else {
      setSeconds(10);
    }

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [paymentSuccess, seconds]);

  // useEffect(() => {
  //   setCardVerify(false);

  //   // eslint-disable-next-line
  // }, [elements?.getElement(CardElement)]);

  return (
    <CustomModal
      openModal={open}
      setOpenModal={() => {
        setOpen(false);
        setChargeID("");
        setPaymentSuccess(false);
      }}
      width={isSmalldesktop ? "90%" : "40%"}
      disableBackdropClick
      title={
        paymentSuccess
          ? ""
          : itensInfo.totalValue > 0
          ? "Please, insert your credit card data"
          : "Confirm pre-order(s)"
      }
    >
      {/* <PaymentSuccess seconds={seconds} /> */}
      {itensInfo.totalValue === 0 ? (
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          {paymentSuccess ? (
            <PaymentSuccess seconds={seconds} />
          ) : (
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
              gap={1}
              mt={3}
            >
              <Typography mb={5} maxWidth={400} textAlign={"center"}>
                You'll about to make a pre-order, when the release date arrives,
                you'll need to pay the itens. For now, all products will stay
                waiting release date in your orders and personal storage
              </Typography>
              <Box
                display={"flex"}
                flexDirection={isMobile ? "column" : "row"}
                gap={1}
                width={"100%"}
              >
                <Button
                  sx={{
                    background: red[500],
                    color: "#FFFFFF",
                    "&:hover": { background: red[100] },
                  }}
                  fullWidth
                  onClick={() => {
                    setOpen(false);
                    setChargeID("");
                  }}
                  disabled={chargeLoading}
                >
                  Cancel
                </Button>
                <Button
                  sx={{
                    background: teal[500],
                    color: "#FFFFFF",
                    "&:hover": { background: teal[100] },
                    "&:disabled": { background: grey[400] },
                  }}
                  fullWidth
                  disabled={chargeLoading}
                  onClick={() => makeOnlyPreOrder()}
                >
                  Confirm
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{
            fetchClientSecret,
          }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </CustomModal>
  );
}
