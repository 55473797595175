import { Box, IconButton, Tab, Tabs, TextField } from "@mui/material";
import { amber, grey } from "@mui/material/colors";
import {
  Outlet,
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import { useEffect, useState } from "react";
import useHeader from "../../../hooks/context/useHeader";
import SearchIcon from "@mui/icons-material/Search";
import {
  CartPopover,
  CustomButton,
  LateralFilters,
} from "../../AtomicDesign/Molecules";
import { LoginPopover } from "../../AtomicDesign/Organisms";
import { GlobalContainer } from "../../AtomicDesign/Atoms";
import { MenuDrawer } from "../MenuDrawer";
import { Logo } from "../Logo";
import useProducts from "../../../hooks/context/useProducts";

// #2C3E50 blue 900
export function Header() {
  const { activeTab, setActiveTab } = useHeader();
  const { setProductsFilters } = useProducts();
  const nav = useNavigate();
  const [params, setSearchParams] = useSearchParams();
  const tabNow = params.get("tab");
  const manufacturerNow = params.get("manufacturerName");
  const versionNow = params.get("versionName");
  const originNow = params.get("originName");
  const nameNow = params.get("name");
  const inStockNow = params.get("inStock");
  const outOfStockNow = params.get("outOfStock");
  const productTypeNow = params.get("productTypeName");
  const preOrderNow = params.get("preOrder");
  const preOwnedNow = params.get("preOwned");

  const location = useLocation();
  const { isSmalldesktop } = useBreakpoints();
  const [selectedColor, setColor] = useState("");
  const [text, setText] = useState("");

  const showLeftMenu =
    location.pathname === "/products" || location.pathname === "/sale";

  const navigation = ({
    where,
    tab,
    isClick,
  }: {
    where?: string;
    tab?: number;
    isClick?: boolean;
  }) => {
    const tabs = tab !== undefined ? tab : showLeftMenu ? Number(tabNow) : 1;
    const loc = where ? where : showLeftMenu ? location.pathname : "/products";
    setActiveTab(tabs);

    const removeParams = !showLeftMenu
      ? createSearchParams({ tab: String(tabs) }).toString()
      : createSearchParams({
          ...(nameNow && {
            name: nameNow,
          }),
          ...(isClick &&
            text && {
              name: text.trimEnd(),
            }),
          ...(manufacturerNow && {
            manufacturerName: manufacturerNow,
          }),
          ...(originNow && {
            originName: originNow,
          }),
          ...(versionNow && {
            versionName: versionNow,
          }),
          ...(inStockNow && {
            inStock: inStockNow,
          }),
          ...(outOfStockNow && {
            outOfStock: outOfStockNow,
          }),
          ...(preOrderNow && {
            preOrder: preOrderNow,
          }),
          ...(productTypeNow && {
            productTypeName: productTypeNow,
          }),
          ...(preOwnedNow && {
            preOwned: preOwnedNow,
          }),
          tab: String(tabs),
        }).toString();

    if (text) {
      setProductsFilters((old) => ({ ...old, name: text }));
    }

    nav({
      pathname: loc,
      search: removeParams,
    });
  };

  const cleanFilter = () => {
    setText("");
    setSearchParams(
      createSearchParams({
        tab: String(tabNow),
      }).toString()
    );
  };

  useEffect(() => {
    setColor("#FFF");

    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    if (showLeftMenu) {
      setProductsFilters({
        offset: 0,
        itensPerPage: 18,
        name: nameNow !== "undefined" && nameNow !== "" ? nameNow : undefined,
        manufacturerName:
          manufacturerNow !== "undefined" ? manufacturerNow : undefined,
        originName: originNow !== "undefined" ? originNow : undefined,
        versionName: versionNow !== "undefined" ? versionNow : undefined,
        inStock: inStockNow !== "undefined" ? inStockNow : undefined,
        outOfStock: outOfStockNow !== "undefined" ? outOfStockNow : undefined,
        preOrder: preOrderNow !== "undefined" ? preOrderNow : undefined,
        productTypeName:
          productTypeNow !== "undefined" ? productTypeNow : undefined,
        isPreOwned: preOwnedNow !== "undefined" ? preOwnedNow : undefined,
        ...(location.pathname === "/sale" && { isSale: true }),
      });
      if (nameNow) {
        setText(nameNow);
      }
    }

    // quando a pagina recarrega, bota os valores de novo nos campos
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    if (showLeftMenu) {
      setSearchParams(
        createSearchParams({
          ...(nameNow && {
            name: nameNow,
          }),
          ...(manufacturerNow && {
            manufacturerName: manufacturerNow,
          }),
          ...(originNow && {
            originName: originNow,
          }),
          ...(versionNow && {
            versionName: versionNow,
          }),
          ...(inStockNow && {
            inStock: inStockNow,
          }),
          ...(outOfStockNow && {
            outOfStock: outOfStockNow,
          }),
          ...(preOrderNow && {
            preOrder: preOrderNow,
          }),
          ...(productTypeNow && {
            productTypeName: productTypeNow,
          }),
          ...(preOwnedNow && {
            preOwned: preOwnedNow,
          }),
        }).toString()
      );

      navigation({ isClick: true });
    } else {
      if (tabNow) {
        setSearchParams(createSearchParams({ tab: String(tabNow) }).toString());
      }
    }

    if (tabNow) {
      setActiveTab(Number(tabNow));
    } else {
      setActiveTab(0);
    }
    // eslint-disable-next-line
  }, [showLeftMenu]);

  useEffect(() => {
    const searchText = document?.getElementById("textfield-search");

    searchText?.addEventListener("keypress", (e) => {
      if (e.key === "Enter") {
        document?.getElementById("confirm-button")?.click();
      }
    });

    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Box
        width={"100%"}
        sx={{ background: isSmalldesktop ? "transparent" : grey["A100"] }}
      >
        <GlobalContainer sx={{ p: 0 }}>
          <Box
            p={isSmalldesktop ? "2px 15px" : 2}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box display={"flex"} alignItems={"center"}>
              {isSmalldesktop && (
                <MenuDrawer
                  navigation={(where, tab) => navigation({ where, tab })}
                  activeTab={activeTab}
                />
              )}
              <Logo onClick={() => navigation({ where: "/home", tab: 0 })} />
            </Box>
            {!isSmalldesktop && (
              <TextField
                id="textfield-search"
                sx={{ width: "60%", mr: 1, background: "#FFF" }}
                label="Search something"
                value={text}
                onChange={(e) => setText(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <>
                      {text && (
                        <IconButton
                          onClick={cleanFilter}
                          style={{ marginRight: 5, fontSize: 13, width: 31 }}
                        >
                          X
                        </IconButton>
                      )}
                      <CustomButton
                        id="confirm-button"
                        btnType="search"
                        onClick={(e) => {
                          navigation({ isClick: true });
                        }}
                      >
                        <SearchIcon />
                      </CustomButton>
                    </>
                  ),
                }}
              />
            )}
            <Box>
              {!isSmalldesktop && (
                <LoginPopover setTab={() => setActiveTab(3)} />
              )}
              <CartPopover />
            </Box>
          </Box>
          {!isSmalldesktop && location.pathname !== "/details" && (
            <Tabs
              value={activeTab}
              onChange={(_e, value) => setActiveTab(value)}
              variant={"fullWidth"}
              TabIndicatorProps={{
                style: { background: amber[500], color: "red" },
              }}
              sx={{
                "& .Mui-selected": {
                  background: "#ed2623",
                  borderRadius: "5px 5px 0 0",
                  color: selectedColor,
                },
                "& .MuiTab-root": {
                  fontSize: 16,

                  "&: hover": {
                    background: grey[200],
                    borderRadius: "5px 5px 0 0",
                  },
                },
              }}
            >
              <Tab
                label="HOME"
                value={0}
                onClick={() => navigation({ where: "/home", tab: 0 })}
              />
              <Tab
                label="PRODUCTS"
                value={1}
                onClick={() => navigation({ where: "/products", tab: 1 })}
              />
              <Tab
                label="SALE"
                value={2}
                onClick={() => navigation({ where: "/sale", tab: 2 })}
              />
              <Tab
                label="Contact us"
                value={3}
                onClick={() => navigation({ where: "/contact", tab: 3 })}
              />
              <Tab
                label="FAQ"
                value={4}
                onClick={() => navigation({ where: "/faq", tab: 4 })}
              />
              <Tab
                label="PROXY"
                value={5}
                onClick={() => navigation({ where: "/proxy", tab: 5 })}
              />
            </Tabs>
          )}
        </GlobalContainer>
      </Box>

      <GlobalContainer sx={{ p: 0 }}>
        {isSmalldesktop && (
          <Box width={"100%"} display={"flex"} justifyContent={"center"}>
            <TextField
              sx={{ width: "97%", mb: 1 }}
              label="Search something"
              value={text}
              onChange={(e) => setText(e.target.value)}
              InputProps={{
                endAdornment: (
                  <>
                    {text && (
                      <IconButton
                        onClick={cleanFilter}
                        style={{ marginRight: 5, fontSize: 13, width: 31 }}
                      >
                        X
                      </IconButton>
                    )}
                    <CustomButton
                      btnType="search"
                      onClick={() => {
                        navigation({ isClick: true });
                      }}
                      style={{ maxWidth: 36.5, minWidth: 36.5 }}
                    >
                      <SearchIcon />
                    </CustomButton>
                  </>
                ),
              }}
            />
          </Box>
        )}

        <Box display={"flex"} flexDirection={isSmalldesktop ? "column" : "row"}>
          {showLeftMenu && <LateralFilters />}
          <Outlet />
          {/* <WarningMessage /> */}
        </Box>
      </GlobalContainer>
    </Box>
  );
}
