import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Typography,
} from "@mui/material";
import { amber } from "@mui/material/colors";
import { DropImageZone } from "../../../components/AtomicDesign/Organisms";
import {
  deleteBannerPhoto,
  uploadBannerPhotosService,
} from "../../../services/configService";
import { useState } from "react";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  list: Banner[];
  setUpdate: VoidFunction;
}

export function BannerUpload({ list, setUpdate }: Props) {
  const [files, setFiles] = useState([] as any);

  const uploadPhotosToBanner = async () => {
    try {
      await uploadBannerPhotosService(files);
    } catch {
    } finally {
      setUpdate();
    }
  };

  const deletePhotoBanner = async (photoID: number) => {
    try {
      await deleteBannerPhoto(photoID);
    } catch {
    } finally {
      setUpdate();
    }
  };
  return (
    <>
      <FormHelperText
        sx={{
          bgcolor: amber[100],
          borderRadius: "12px",
          p: 1,
          mt: 1,

          textAlign: "center",
        }}
      >
        <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
        Please, upload images with 750x250 resolution
      </FormHelperText>

      <DropImageZone
        oldFiles={[]}
        removeFileAdmin={() => {}}
        handleChangeFiles={(images) => setFiles([...images])}
        cleanFileState={false}
        title={"Banner photos"}
        isBanner
      />

      <Button
        variant="contained"
        disabled={files?.length === 0}
        onClick={() => uploadPhotosToBanner()}
      >
        Send photos
      </Button>

      <Typography mt={3} mb={2}>
        Actual banners
      </Typography>
      <Box display={"flex"} flexWrap={"wrap"} gap={3}>
        {list?.length > 0 &&
          list?.map((item) => (
            <Box>
              <img src={item?.photo} alt={"banner"} width={350} height={100} />

              <IconButton
                aria-label="delete"
                onClick={() => deletePhotoBanner(item?.id)}
                sx={{
                  position: "absolute",
                  bgcolor: "red",
                  marginLeft: "-30px",
                  marginTop: "-10px",
                  zIndex: 12651651651561561,
                  "&:hover": { bgcolor: "pink" },
                }}
              >
                <DeleteIcon sx={{ fill: "white" }} />
              </IconButton>
            </Box>
          ))}
      </Box>
    </>
  );
}
