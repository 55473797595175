import { createContext, useContext, useEffect, useMemo, useState } from "react";
// import useToast from "./useToast";

import useGlobalLoading from "./useGlobalLoading";
import useAuth from "./useAuth";
import {
  createShippmentService,
  getPersonalStorageToShip,
  getShippmentsAdminService,
  getShippmentsService,
  updateShippmentAdminService,
  updateShippmentProductsService,
  updateShippmentService,
  verifyPaymentShippmentService,
} from "../../services/shippmentsService";
import useToast from "./useToast";

const ShippmentsProviderContext = createContext({} as IShippmentsContext);

function ShippmentsProvider({ children }: { children: React.ReactNode }) {
  const { setOpenLoading } = useGlobalLoading();
  const { setOpen, setToastData } = useToast();
  const { user } = useAuth();
  const [shippmentsData, setShippmentsData] = useState([] as ShippmentData[]);
  const [shippmentsRecords, setShippmentsRecords] = useState(0);
  const [personalStorageData, setPersonalStorageData] = useState(
    [] as PersonalStorageToShip[]
  );

  const [filters, setFilters] = useState<FilterShippment>({
    offset: 0,
    itensPerPage: 16,
    shippmentID: null,
    status: null,
  });
  const [loading, setLoading] = useState({
    createShippment: false,
    personalStorage: false,
  });

  const shippmentsDataMemo = useMemo(() => shippmentsData, [shippmentsData]);
  const personalStorageToShipDataMemo = useMemo(
    () => personalStorageData,
    [personalStorageData]
  );

  const getShippments = async () => {
    setOpenLoading(true);

    try {
      const isAdmin = user?.user?.role === "admin";

      const response = isAdmin
        ? await getShippmentsAdminService({
            ...filters,
            userID: user?.user?.id,
          })
        : await getShippmentsService({
            ...filters,
            userID: user?.user?.id,
          });

      setShippmentsData(response.data);
      setShippmentsRecords(Math.ceil(response.count / filters.itensPerPage));
    } catch {
    } finally {
      setOpenLoading(false);
      // setTimeout(() => setOpenLoading(false), 5000);
    }
  };

  const getPersonalStorageForShip = async () => {
    setLoading((old) => ({ ...old, personalStorage: true }));
    try {
      const response = await getPersonalStorageToShip(user?.personalStorageID);
      const map = response.PersonalStorageXProduct.map((item) => ({
        ...item,
        quantityToShip: 0,
      }));
      setPersonalStorageData(map);
    } catch {
    } finally {
      setLoading((old) => ({ ...old, personalStorage: false }));
    }
  };

  const createShippment = async (info: CreateShippment) => {
    setLoading((old) => ({ ...old, createShippment: true }));
    try {
      await createShippmentService(info);
    } catch {
    } finally {
      getShippments();
      setLoading((old) => ({ ...old, createShippment: false }));
    }
  };

  const updateShippment = async (data: UpdateShippmentAddress) => {
    try {
      await updateShippmentService(data);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: "Success when editing shipment",
      });
      setOpen(true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: "Error when editing shipment, try again",
      });
      setOpen(true);
    } finally {
      getShippments();
    }
  };

  const updateShippmentProducts = async (data: UpdateShippmentProducts) => {
    try {
      await updateShippmentProductsService(data);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: "Success when editing shipment",
      });
      setOpen(true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: "Error when editing shipment, try again",
      });
      setOpen(true);
    } finally {
      getShippments();
    }
  };

  const updateShippmentAdmin = async (data: UpdateShippmentAdmin) => {
    try {
      await updateShippmentAdminService(data);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: "Success when editing shipment",
      });
      setOpen(true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: "Error when editing shipment, try again",
      });
      setOpen(true);
    } finally {
      getShippments();
    }
  };

  const verifyPayment = async (data: VerifyPaymentShippment) => {
    try {
      await verifyPaymentShippmentService(data);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: "Payment success for shipment",
      });
      setOpen(true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: "This shipment is not paid",
      });
      setOpen(true);
    } finally {
      getShippments();
    }
  };

  useEffect(() => {
    if (user?.user?.id) {
      getShippments();
    }
    // eslint-disable-next-line
  }, [filters, user]);

  return (
    <ShippmentsProviderContext.Provider
      value={{
        shippmentsDataMemo,
        personalStorageToShipDataMemo,
        shippmentsRecords,
        filters,
        loading,
        setFilters,
        createShippment,
        getShippments,
        getPersonalStorageForShip,
        updateShippment,
        updateShippmentProducts,
        updateShippmentAdmin,
        verifyPayment,
      }}
    >
      {children}
    </ShippmentsProviderContext.Provider>
  );
}

export { ShippmentsProvider, ShippmentsProviderContext };

export default function useShippments() {
  return useContext(ShippmentsProviderContext);
}
