import { isValid, format } from "date-fns";

export function useDateTime() {
  const formatedDate = (date: Date) => {
    if (date === null) {
      return "-";
    }

    if (isValid(new Date(date))) {
      return format(new Date(date), "yyyy/MM/dd");
    } else {
      return "Invalid date";
    }
  };

  return { formatedDate };
}
