import { api } from "./api";

export const getMyCartSevice = async (userID: number) => {
  const response2 = await api.get("/get-my-cart", { params: { userID } });
  return response2.data;
};

export const addToMyCardSevice = async (data: ProductCart) => {
  await api.post("/add-to-my-cart", { ...data });
};

export const updateMyCartSevice = async (data: ProductCart) => {
  await api.post("/update-my-cart", { ...data });
};

export const removeFromMyCartSevice = async (data: ProductCart) => {
  await api.delete("/remove-from-my-cart", {
    params: { cartID: data.cartID, productID: data.productID },
  });
};

export const createCreditCardCharge = async (data: ChargeCard) => {
  await api.post("/charge-order", { ...data });
};

export const chargeByStripeSckecout = async (data: ChargeCard) => {
  const response = await api.post("/charge-payment", { ...data });
  return response.data;
};

export const updatePaymentStatus = async (data: PaymentStatus) => {
  const response = await api.post("/verify-payment", { ...data });
  return response.data;
};

export const createPreOrders = async (data: ChargeCard) => {
  const response = await api.post("/create-pre-orders", { ...data });
  return response.data;
};
