import { Box, BoxProps, Typography, TypographyProps } from "@mui/material";

interface Props {
  title: string | number;
  text: string | number;
  titleProps?: TypographyProps;
  textProps?: TypographyProps;
  html?: string;
  containerProps?: BoxProps;
}

export function DataText({
  title,
  text,
  titleProps,
  textProps,
  html,
  containerProps,
}: Props) {
  return (
    <Box display={"flex"} {...containerProps}>
      <Typography
        variant="h2"
        fontWeight={500}
        minWidth={"180px"}
        {...titleProps}
      >
        {title}
      </Typography>

      {html ? (
        <div dangerouslySetInnerHTML={{ __html: html }} />
      ) : (
        <Typography
          variant="h2"
          fontWeight={400}
          width={"200px"}
          maxHeight={20}
          overflow={"hidden"}
          {...textProps}
        >
          {text}
        </Typography>
      )}
    </Box>
  );
}
