import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface Props {
  onChange: (item: ProductsType) => void;
  onRemove: VoidFunction;
  checked: string;
  list: ProductsType[];
  active: boolean;
  menuName: string;
}

export function FormControlMenu({
  onChange,
  onRemove,
  checked,
  list,
  active,
  menuName,
}: Props) {
  const [showAll, setShowAll] = useState(false);
  return (
    <FormControl>
      <FormLabel
        id={`${menuName}-labels`}
        sx={{ height: 36, display: "flex", alignItems: "center" }}
      >
        {menuName}
        {active && (
          <Button variant="text" onClick={onRemove}>
            X
          </Button>
        )}
      </FormLabel>
      <RadioGroup
        sx={{
          maxHeight: showAll ? "auto" : 420,
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          overflow: showAll ? "visible" : "hidden",
        }}
      >
        {list?.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item?.name}
            control={<Radio />}
            label={
              <Typography height={24} sx={{ overflow: "hidden" }}>
                {item?.name}
              </Typography>
            }
            onChange={() => onChange(item)}
            checked={checked === item?.name}
          />
        ))}
      </RadioGroup>
      {list?.length > 10 && (
        <Box width={"100%"}>
          <Button
            fullWidth
            variant="text"
            sx={{
              fontSize: 12,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => setShowAll((old) => !old)}
          >
            {showAll && <KeyboardArrowUpIcon sx={{ mb: -0.5 }} />}
            Show more
            {!showAll && <KeyboardArrowDownIcon sx={{ mt: -0.5 }} />}
          </Button>
        </Box>
      )}
    </FormControl>
  );
}
