import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useProducts from "../../../hooks/context/useProducts";
import { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ReplyAllIcon from "@mui/icons-material/ReplyAll";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import { amber, grey } from "@mui/material/colors";
import { useJPYmoney } from "../../../hooks/useJPYmoney";
import { CustomButton } from "../../../components/AtomicDesign/Molecules";
import useToast from "../../../hooks/context/useToast";
import {
  DataText,
  FadeInContainer,
  NoData,
} from "../../../components/AtomicDesign/Atoms";
// import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { useDateTime } from "../../../hooks/useDateTime";
import { addFavorite, removeFavorite } from "../../../services/userServices";
import useAuth from "../../../hooks/context/useAuth";
import useGlobalLoading from "../../../hooks/context/useGlobalLoading";
import { colors } from "../../../theme/colors";
import useCart from "../../../hooks/context/useCart";

export function Details() {
  const { setOpenLoading } = useGlobalLoading();
  const nav = useNavigate();
  const { user } = useAuth();
  const [params] = useSearchParams();
  const productID = params.get("product");
  const { isSmalldesktop, isMobile } = useBreakpoints();
  const { setOpen, setToastData } = useToast();
  const { formatedDate } = useDateTime();
  const { addToMyCart } = useCart();
  const { setHandleIDProduct, product, getOneProduct } = useProducts();
  const { JPYmoney } = useJPYmoney();
  const [imageIndex, setImageIndex] = useState(0);
  const [selectedQTD, setSelectedQTD] = useState(1);

  const copyContent = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: "Link copied ",
      });
      setOpen(true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: "error",
      });
      setOpen(true);
    }
  };

  useEffect(() => {
    if (productID) {
      setHandleIDProduct(Number(productID));
    }
    // eslint-disable-next-line
  }, [productID]);

  const maxWidth = isSmalldesktop ? 360 : 500;

  const removeFav = async () => {
    setOpenLoading(true);
    try {
      await removeFavorite({ productID: product?.id, userID: user?.user?.id });
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: "Favorite removed",
      });
      getOneProduct(false);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: "Error while removing the favorite, try again",
      });
    } finally {
      // setTimeout(() => setOpenLoading(false), 5000);
      setOpenLoading(false);
    }
  };

  const addFav = async () => {
    setOpenLoading(true);
    try {
      await addFavorite({ productID: product?.id, userID: user?.user?.id });
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: "Favorite add to your list",
      });
      getOneProduct(false);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: "Error while add the favorite to your list, try again",
      });
    } finally {
      // setTimeout(() => setOpenLoading(false), 5000);
      setOpenLoading(false);
    }
  };

  const addToCart = async () => {
    if (!user) {
      nav("/user/login");
    } else {
      await addToMyCart(
        {
          cartID: user?.cartID,
          productID: product?.id,
          quantity: selectedQTD,
        },
        true
      );
    }
  };

  // function updateMetaTagImage() {
  //   const metaTag = document.querySelector('meta[property="og:image"]');
  //   if (metaTag) {
  //     metaTag.setAttribute("content", product?.productsImages[0]?.code);
  //   }
  // }

  // useEffect(() => {
  //   if (product?.productsImages?.length > 0) {
  //     updateMetaTagImage();
  //   }
  //   // eslint-disable-next-line
  // }, [product]);

  return (
    <Box width={isMobile ? 370 : isSmalldesktop ? "100%" : "100%"}>
      <FadeInContainer>
        {product?.id ? (
          <Box>
            <Box
              display={"flex"}
              flexWrap={"wrap"}
              justifyContent={isSmalldesktop ? "center" : "space-between"}
            >
              <Box>
                <img
                  src={`${product?.productsImages[imageIndex]?.code}`}
                  width={maxWidth}
                  height={maxWidth}
                  alt="figure"
                  style={{
                    objectFit: "contain",
                    borderRadius: 5,
                    background: grey[200],
                    marginBottom: 8,
                  }}
                />

                <Box
                  display={"flex"}
                  maxWidth={maxWidth}
                  sx={{ overflowX: "auto" }}
                  gap={1}
                >
                  {product?.productsImages?.map((image, index) => (
                    <img
                      key={index}
                      style={{
                        width: 100,
                        height: 100,
                        objectFit: "contain",
                        cursor: "pointer",
                        borderRadius: 5,
                        background: grey[200],
                        marginBottom: 6,
                      }}
                      src={image?.code}
                      alt={image?.code}
                      onMouseOver={() => {
                        setImageIndex(index);
                      }}
                      onClick={() => setImageIndex(index)}
                    />
                  ))}
                </Box>
              </Box>

              <Paper
                elevation={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: 3,
                  height: "fit-content",
                  margin: isSmalldesktop ? "20px 0" : "0",
                  width: isSmalldesktop ? "80%" : "58%",
                }}
              >
                <Box>
                  {product?.isPreOrder ? (
                    <Box>
                      <Chip
                        label={"Pre-Order"}
                        color={"error"}
                        sx={{ mt: 1 }}
                      />
                      <Typography sx={{ mt: 1, mb: 2 }}>
                        Expected release date:{" "}
                        {formatedDate(product?.preOrderDeadline)}
                      </Typography>
                    </Box>
                  ) : (
                    <Box display={"flex"} gap={1}>
                      <Chip
                        label={
                          product?.stock <= 0 ? "Out of stock" : "In stock"
                        }
                        color={"default"}
                        sx={{ mt: 1 }}
                      />
                      {product?.isSale && (
                        <Chip label={"Sale"} color={"error"} sx={{ mt: 1 }} />
                      )}
                      {product?.isPreOwned && (
                        <Chip
                          label={"Pre-owned"}
                          style={{
                            backgroundColor: colors.yellow[400],
                            color: "#FFFFFF",
                          }}
                          sx={{ mt: 1 }}
                        />
                      )}
                    </Box>
                  )}
                  <Typography
                    fontSize={isSmalldesktop ? 18 : 28}
                    color={grey[600]}
                    style={{ wordBreak: "break-word" }}
                  >
                    {product?.characterName}
                  </Typography>
                  <Typography sx={{ mt: 2 }} color={grey[600]}>
                    {product?.manufacturerName}
                  </Typography>

                  <Typography
                    style={{
                      wordBreak: "break-word",
                      fontSize: isSmalldesktop ? 30 : 40,
                    }}
                    mt={1}
                    color={amber[900]}
                  >
                    {JPYmoney(product?.price)}
                  </Typography>
                  {/* {!product?.canGoSmallPacket && (
                    <FormHelperText
                      sx={{
                        bgcolor: amber[100],
                        borderRadius: "12px",
                        p: 1,
                        mt: 1,
                        mb: 2,
                        textAlign: "center",
                      }}
                    >
                      <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
                      This item can't go by "Small Packet" service, shipping
                      costs for this item may be very high due to package size
                      or/and weight.
                    </FormHelperText>
                  )} */}
                  <Typography>Stock: {product?.stock}</Typography>
                  {product?.stock > 0 ? (
                    <Box display={"flex"} gap={2} mt={3}>
                      <FormControl sx={{ width: 80 }}>
                        <InputLabel id="demo-simple-select-label">
                          Quantity
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedQTD}
                          label="Quantity"
                          defaultValue={1}
                          defaultChecked
                          onChange={(e) =>
                            setSelectedQTD(Number(e.target.value))
                          }
                        >
                          {Array.from(
                            {
                              length: product?.stock,
                            },
                            (_v, i) => i
                          ).map((qtd, index) => {
                            const removeZero = qtd + 1;
                            return (
                              <MenuItem value={removeZero} key={index}>
                                {removeZero}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <CustomButton
                        btnType="login"
                        fullWidth={false}
                        style={{
                          width: 300,
                          fontSize: isSmalldesktop ? 18 : 20,
                        }}
                        onClick={addToCart}
                      >
                        <AddShoppingCartIcon sx={{ mr: 1 }} /> Add to cart
                      </CustomButton>
                    </Box>
                  ) : (
                    <Typography fontSize={20} mt={1} color={grey[500]}>
                      Sorry, all of our stock was sold, let us know you want it,
                      click in the heart bellow to favorite!{" "}
                    </Typography>
                  )}

                  <Typography fontSize={18} mt={3}>
                    {product?.favorited
                      ? "Already in your list"
                      : "Add to your favorites"}
                    <IconButton
                      onClick={() => {
                        if (product?.favorited) {
                          removeFav();
                        } else {
                          addFav();
                        }
                      }}
                    >
                      {product?.favorited ? (
                        <FavoriteIcon style={{ fill: "red" }} />
                      ) : (
                        <FavoriteBorderIcon />
                      )}
                    </IconButton>
                  </Typography>

                  <CustomButton
                    btnType="share"
                    fullWidth={false}
                    style={{ width: 200 }}
                    onClick={() => copyContent()}
                  >
                    <ReplyAllIcon sx={{ mr: 1, ml: -2 }} /> share
                  </CustomButton>
                </Box>
              </Paper>
            </Box>

            <Box display={"flex"} justifyContent={"center"}>
              <Paper
                elevation={3}
                sx={{
                  p: isSmalldesktop ? 3 : 5,
                  mt: isSmalldesktop ? 0 : 5,
                  mb: 5,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  gap: 3,
                  width: isSmalldesktop ? "80%" : "100%",
                }}
              >
                <DataText
                  title={"Origin:"}
                  text={product?.originName}
                  textProps={{
                    width: "100%",
                    sx: { wordBreak: "break-all" },
                  }}
                />
                <Divider sx={{ mt: -2 }} />
                <DataText
                  title={"Version:"}
                  text={product?.versionName}
                  textProps={{
                    width: "100%",
                    sx: { wordBreak: "break-all" },
                  }}
                />
                <Divider sx={{ mt: -2 }} />
                {product?.isPreOrder && (
                  <>
                    <DataText
                      title={"Expected release date:"}
                      text={formatedDate(product?.preOrderDeadline)}
                      textProps={{
                        width: "100%",
                        sx: { wordBreak: "break-all" },
                      }}
                    />
                    <Divider sx={{ mt: -2 }} />
                  </>
                )}
                <DataText
                  html={product?.description}
                  title={"Description:"}
                  text={product?.description}
                  textProps={{
                    width: "100%",
                    sx: { wordBreak: "break-all" },
                    overflow: "inherit",
                    maxHeight: "100%",
                  }}
                />
                <Divider sx={{ mt: -2 }} />

                <Box
                  display={"flex"}
                  gap={1}
                  flexWrap={"wrap"}
                  alignItems={"flex-start"}
                >
                  <Box height={"fit-content"}>
                    <Link
                      to={`/products?manufacturerName=${product?.Manufacturers?.name}&tab=1`}
                    >
                      <Chip
                        label={product?.Manufacturers?.name}
                        sx={{ "&: hover": { background: "lightgray" } }}
                      />
                    </Link>
                  </Box>
                  <Box height={"fit-content"}>
                    <Link
                      to={`/products?versionName=${product?.Versions?.name}&tab=1`}
                    >
                      <Chip
                        label={product?.Versions?.name}
                        sx={{ "&: hover": { background: "lightgray" } }}
                      />
                    </Link>
                  </Box>
                  <Box height={"fit-content"}>
                    <Link
                      to={`/products?originName=${product?.Origin?.name}&tab=1`}
                    >
                      <Chip
                        label={product?.Origin?.name}
                        sx={{ "&: hover": { background: "lightgray" } }}
                      />
                    </Link>
                  </Box>
                  <Box height={"fit-content"}>
                    <Link
                      to={`/products?productTypeName=${product?.ProductType?.name}&tab=1`}
                    >
                      <Chip
                        label={product?.ProductType?.name}
                        sx={{ "&: hover": { background: "lightgray" } }}
                      />
                    </Link>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
        ) : (
          <NoData text="No product found" />
        )}
      </FadeInContainer>
    </Box>
  );
}
