import { Box, Typography } from "@mui/material";
import { Outlet, createSearchParams, useNavigate } from "react-router-dom";
import { ContactInfo } from "../../AtomicDesign/Molecules";
import { grey } from "@mui/material/colors";

import { LoginPopover } from "../../AtomicDesign/Organisms";

import { GlobalContainer } from "../../AtomicDesign/Atoms";
import { Logo } from "../Logo";
import { useBreakpoints } from "../../../hooks/useBreakpoins";

export function LoginLayout() {
  const { isSmalldesktop } = useBreakpoints();

  const navigate = useNavigate();

  // if (user && token) {
  //   if (user?.user?.role === "admin") {
  //     navigate("/treasurebox-app/admin");
  //   } else {
  //     navigate("/user/my-info");
  //   }
  // }

  return (
    <Box
      minWidth={"100%"}
      height={"100vh"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
    >
      <Box>
        <Box sx={{ width: "100%" }}>
          <GlobalContainer
            sx={{
              p: 0,
              pr: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Logo
              onClick={() =>
                navigate({
                  pathname: "/home",
                  search: createSearchParams({ tab: String(0) }).toString(),
                })
              }
            />

            {!isSmalldesktop && <LoginPopover setTab={() => {}} />}
          </GlobalContainer>
        </Box>
        <GlobalContainer
          sx={{
            mt: 2,
            mb: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Outlet />
          {/* <WarningMessage /> */}
        </GlobalContainer>
      </Box>
      <Box sx={{ background: grey[900], width: "100%" }}>
        <GlobalContainer sx={{ mt: 2, mb: 2 }}>
          <ContactInfo />
          <Typography textAlign={"center"} mt={10} color={"#FFF"}>
            {String(new Date().getFullYear())} © Treasure Box
          </Typography>
        </GlobalContainer>
      </Box>
    </Box>
  );
}
