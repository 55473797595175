import { Box, Button } from "@mui/material";
import { green, red } from "@mui/material/colors";

interface Props {
  confirmClick: VoidFunction;
  cancelClick: VoidFunction;
  disabled?: boolean;
}

export function ConfirmButtons({
  confirmClick,
  cancelClick,
  disabled = false,
}: Props) {
  return (
    <Box display={"flex"} justifyContent={"center"} gap={5}>
      <Button
        sx={{ bgcolor: green[500], color: "white" }}
        style={{ width: 100 }}
        onClick={confirmClick}
        disabled={disabled}
      >
        Confirm
      </Button>
      <Button
        sx={{ bgcolor: red[500], color: "white" }}
        style={{ width: 100 }}
        onClick={cancelClick}
      >
        Cancel
      </Button>
    </Box>
  );
}
