import * as yup from "yup";

export const validationSchema = yup.object({
  characterName: yup.string().required(),
  description: yup.string().required(),
  price: yup.number().min(1).required(),
  weight: yup.number().min(1).required(),
  stock: yup.number().min(1).required(),
  isSale: yup.boolean().required(),

  isPreOrder: yup.boolean().required(),
  preOrderDeadline: yup.date().when("isPreOrder", (isPreOrder, schema) => {
    if (isPreOrder)
      return schema.required("You need to set a dead line for pre-orders");
    return schema;
  }),

  originName: yup.string().required(),
  manufacturerName: yup.string().required(),
  versionName: yup.string().required(),
  productTypeName: yup.string().required(),
  originId: yup.number().required(),
  versionsId: yup.number().required(),
  manufacturersId: yup.number().required(),
  productTypeId: yup.number().required(),
});
