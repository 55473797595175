import { Box, Divider, Typography } from "@mui/material";

import { useBreakpoints } from "../../../../hooks/useBreakpoins";

// import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { grey } from "@mui/material/colors";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";

// type FreteTypes = {
//   tax: number;
//   shippmentFee: number;
// };

// interface Frete {
//   smallPacket: FreteTypes;
//   ems: FreteTypes;
//   surface: FreteTypes;
//   airParcel: FreteTypes;
//   domestic: FreteTypes;
// }

interface Props {
  address: IAddress;
  productsWeight: number;
  onClickShipOption: (val: SelectedShipType) => void;
  selectedShipType: SelectedShipType;
  clickDisabled?: boolean;
  canGoSmallPacket: boolean;
}

export function ShippingFeeSimulator({
  onClickShipOption,
  selectedShipType,
  clickDisabled = false,
}: Props) {
  const { JPYmoney } = useJPYmoney();
  const { isMobile } = useBreakpoints();
  // const [frete, setFrete] = useState({} as Frete);
  // const [loading, setLoading] = useState(false);

  // const calcFrete = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const getFrete = await calcShipPrice({
  //       weight: productsWeight + 300,
  //       globalZoneId: address?.globalZoneId,
  //     });
  //     setFrete(getFrete);
  //   } catch {
  //   } finally {
  //     setLoading(false);
  //   }
  //   // eslint-disable-next-line
  // }, [address]);

  // //   useEffect(() => {
  // //     setCountrySelected("Brazil");
  // //     setWeight(2000);
  // //   }, [])

  // useEffect(() => {
  //   if (address.globalZoneId && productsWeight > 0) {
  //     calcFrete();
  //   }
  //   // eslint-disable-next-line
  // }, [address]);

  const shippmentStripeTax = (shippmentFee: number) => {
    if (!shippmentFee) return 0;
    const calcTax = (100 - 3.8) / 100;
    const shippmentTax = Math.ceil(shippmentFee / calcTax);
    return shippmentTax;
  };

  // const canGoSmall = () => {
  //   if (!canGoSmallPacket) return false;
  //   if (productsWeight > 2000) return false;
  //   if (productsWeight <= 2000 && !clickDisabled) return true;
  //   return true;
  // };

  return (
    <Box sx={{ background: "#FFF", borderRadius: 5, p: 2 }}>
      {/* {!canGoSmallPacket && (
        <FormHelperText
          sx={{
            bgcolor: amber[100],
            borderRadius: "12px",
            p: 1,
            mt: 1,
            mb: 2,
            textAlign: "center",
          }}
        >
          <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
          Some of products in this shiping can't go by "Small Packet" service,
          please choose another method or remove the item
        </FormHelperText>
      )} */}
      <Box
        display={"flex"}
        justifyContent={isMobile ? "center" : "space-between"}
        flexWrap={"wrap"}
        alignItems={"center"}
      >
        <Box
          sx={{
            p: 2,
            border:
              selectedShipType.selectedShipType === "domestic"
                ? "1px solid green"
                : "none",
            cursor: "pointer",
            "&:hover": {
              background: !clickDisabled ? grey["A100"] : "transparent",
            },
          }}
          onClick={() => {
            if (!clickDisabled) {
              onClickShipOption({
                selectedShipType: "domestic",
                shipPrice: shippmentStripeTax(900),
              });
            }
          }}
        >
          <Typography variant="h1" mb={2}>
            Domestic
          </Typography>

          <Box display="flex">
            <Typography width={125}>Shipment price:</Typography>
            <Typography>{JPYmoney(shippmentStripeTax(900))}</Typography>
          </Box>

          <Divider />
        </Box>

        {/* <Box
          sx={{
            p: 2,
            background: canGoSmall() ? "transparent" : grey[300],
            borderRadius: 2,
            border:
              selectedShipType.selectedShipType === "smallPacket"
                ? "1px solid green"
                : "none",
            cursor: "pointer",
            "&:hover": {
              background: !clickDisabled ? grey["A100"] : "transparent",
            },
          }}
          onClick={() => {
            if (canGoSmall()) {
              onClickShipOption({
                selectedShipType: "smallPacket",
                shipPrice: shippmentStripeTax(frete?.smallPacket?.shippmentFee),
              });
            }
          }}
        >
          <Typography
            variant="h1"
            mb={2}
            sx={{ color: productsWeight > 2000 ? grey[500] : "black" }}
          >
            Small Packet
          </Typography>

          <Box display="flex">
            <Typography
              width={125}
              sx={{ color: productsWeight > 2000 ? grey[500] : "black" }}
            >
              Shipment price:
            </Typography>
            <Typography
              sx={{ color: productsWeight > 2000 ? grey[500] : "black" }}
            >
              {loading ? (
                <CircularLoading mb={0} mt={1} size={10} />
              ) : canGoSmall() ? (
                JPYmoney(
                  shippmentStripeTax(frete?.smallPacket?.shippmentFee ?? 0)
                )
              ) : (
                "Very heavy"
              )}
            </Typography>
          </Box>

          <Divider />
        </Box>

        <Box
          sx={{
            p: 2,
            border:
              selectedShipType.selectedShipType === "EMS"
                ? "1px solid green"
                : "none",
            cursor: "pointer",
            "&:hover": {
              background: !clickDisabled ? grey["A100"] : "transparent",
            },
          }}
          onClick={() => {
            if (!clickDisabled) {
              onClickShipOption({
                selectedShipType: "EMS",
                shipPrice: shippmentStripeTax(frete?.ems?.shippmentFee),
              });
            }
          }}
        >
          <Typography variant="h1" mb={2}>
            EMS
          </Typography>

          <Box display="flex">
            <Typography width={125}>Shipment price:</Typography>
            <Typography>
              {loading ? (
                <CircularLoading mb={0} mt={1} size={10} />
              ) : (
                JPYmoney(shippmentStripeTax(frete?.ems?.shippmentFee))
              )}
            </Typography>
          </Box>

          <Divider />
        </Box>

        <Box
          sx={{
            p: 2,
            border:
              selectedShipType.selectedShipType === "surface"
                ? "1px solid green"
                : "none",
            cursor: "pointer",
            "&:hover": {
              background: !clickDisabled ? grey["A100"] : "transparent",
            },
          }}
          onClick={() => {
            if (!clickDisabled) {
              onClickShipOption({
                selectedShipType: "surface",
                shipPrice: shippmentStripeTax(frete?.surface?.shippmentFee),
              });
            }
          }}
        >
          <Typography variant="h1" mb={2}>
            Sal Ship Parcel
          </Typography>

          <Box display="flex">
            <Typography width={125}>Shipment price:</Typography>
            <Typography>
              {loading ? (
                <CircularLoading mb={0} mt={1} size={10} />
              ) : (
                JPYmoney(shippmentStripeTax(frete?.surface?.shippmentFee))
              )}
            </Typography>
          </Box>
          <Divider />
        </Box>

        <Box
          sx={{
            p: 2,
            border:
              selectedShipType.selectedShipType === "airParcel"
                ? "1px solid green"
                : "none",
            cursor: "pointer",
            "&:hover": {
              background: !clickDisabled ? grey["A100"] : "transparent",
            },
          }}
          onClick={() => {
            if (!clickDisabled) {
              onClickShipOption({
                selectedShipType: "airParcel",
                shipPrice: shippmentStripeTax(frete?.airParcel?.shippmentFee),
              });
            }
          }}
        >
          <Typography variant="h1" mb={2}>
            Sal Air Parcel
          </Typography>

          <Box display="flex">
            <Typography width={125}>Shipment price:</Typography>
            <Typography>
              {loading ? (
                <CircularLoading mb={0} mt={1} size={10} />
              ) : (
                JPYmoney(shippmentStripeTax(frete?.airParcel?.shippmentFee))
              )}
            </Typography>
          </Box>
          <Divider />
        </Box> */}
      </Box>
    </Box>
  );
}
