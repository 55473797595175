import axios from "axios";
import { api } from "./api";

export const updateUserService = async (data: IUpdateUserDTO) => {
  const response2 = await api.post("/update-user", { data });
  return response2.data;
};

export const deleteUserService = async (userID: number) => {
  const response2 = await api.delete("/delete-user", { params: { userID } });
  return response2.data;
};

export const apiShipping = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_SHIPPINGFEE,
});

export const calcShipPrice = async (data: CalcServiceTax) => {
  const response2 = await apiShipping.post("/calc-service-tax", { data });
  return response2.data;
};
