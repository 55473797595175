import { Box, Fade } from "@mui/material";
import {
  LoginBox,
  NewUserBox,
} from "../../../components/AtomicDesign/Organisms";
import { BreakpointBox } from "../../../components/AtomicDesign/Atoms";
import useAuth from "../../../hooks/context/useAuth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function Login() {
  const { user, token } = useAuth();
  const nav = useNavigate();

  useEffect(() => {
    if (user && token) {
      nav("/home");
    }
    // eslint-disable-next-line
  }, [user, token]);

  return (
    <Fade
      in={true}
      style={{
        transformOrigin: "0 0 0",
      }}
      timeout={1000}
    >
      <Box p={1}>
        <BreakpointBox>
          <LoginBox />
          <NewUserBox />
        </BreakpointBox>
      </Box>
    </Fade>
  );
}
