import { createContext, useEffect, useState, useContext } from "react";
import {
  changePasswordApi,
  forgotPasswordApi,
  getUserApi,
  loginApi,
  verifyCodeForgotPasswordApi,
} from "../../services/authService";

const AuthContext = createContext({} as IUseAuthContext);

export function setUserLocalStorage(loginData: LoginProps) {
  localStorage.setItem(
    "@treasurebox_user",
    JSON.stringify({
      ...loginData.data,
      cartID: loginData.cartID,
      personalStorageID: loginData.personalStorageID,
    })
  );
  localStorage.setItem("@treasurebox_token", JSON.stringify(loginData.token));
}

export function getUserLocalStorage() {
  const jsonUser = localStorage.getItem("@treasurebox_user");
  const jsonToken = localStorage.getItem("@treasurebox_token");
  const jsonLanguage = localStorage.getItem("@treasurebox_language");

  return {
    user: !jsonUser ? null : JSON.parse(jsonUser),
    token: !jsonToken ? null : JSON.parse(jsonToken),
    language: !jsonLanguage ? "english" : JSON.parse(jsonLanguage),
  };
}

type AuthProviderProps = {
  children: React.ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState({} as User);
  const [token, setToken] = useState("" as string);
  const [loginError, setLoginError] = useState(false);
  const [language, setLanguage] = useState("english" as ILanguageOption);

  useEffect(() => {
    const data = getUserLocalStorage();

    if (data) {
      setUser(data.user);
      setToken(data.token);
      setLanguage(data.language);
    }
  }, []);

  const authenticate = async (email: string, password: string) => {
    try {
      const login = await loginApi(email, password);
      const data = {
        ...login.data,
        cartID: login.data.user.cartID[0].id,
        personalStorageID: login.data.user.personalStorageID[0].id,
      };

      setUser(data);
      setToken(login.token);
      setUserLocalStorage({
        ...login,
        cartID: login.data.user.cartID[0].id,
        personalStorageID: login.data.user.personalStorageID[0].id,
      });
      setLoginError(false);
    } catch (err: any) {
      setLoginError(true);
      throw new Error(err?.response?.data?.message);
    }
  };

  const getUser = async (userID: number) => {
    try {
      const getUser = await getUserApi(userID);

      setLoginError(false);
      return getUser.data;
    } catch (err: any) {
      setLoginError(true);
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      await forgotPasswordApi(email);
      return "success";
    } catch (err: any) {
      return "failed";
    }
  };

  const forgotPasswordVerifyCode = async (email: string, code: string) => {
    try {
      await verifyCodeForgotPasswordApi(email, code);
      return "success";
    } catch (err: any) {
      return "failed";
    }
  };

  const changePassword = async (
    email: string,
    code: string,
    newPassword: string
  ) => {
    try {
      await changePasswordApi(email, code, newPassword);
      return "success";
    } catch (err: any) {
      return "failed";
    }
  };

  const logout = async () => {
    setUser({} as User);
    setToken("");
    localStorage.removeItem("@treasurebox_user");
    localStorage.removeItem("@treasurebox_token");
    localStorage.removeItem("@treasureBox-menuOption");
  };

  const changeLanguage = (option: "english" | "portuguese") => {
    localStorage.setItem("@treasurebox_language", JSON.stringify(option));
    setLanguage(option);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        loginError,
        language,
        getUser,
        changeLanguage,
        authenticate,
        logout,
        forgotPassword,
        changePassword,
        forgotPasswordVerifyCode,
        getUserLocalStorage,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };

export default function useAuth() {
  return useContext(AuthContext);
}
