import { createContext, useContext, useEffect, useMemo, useState } from "react";
import useToast from "./useToast";
import {
  createProductService,
  delImageFromProduct,
  getOneProductService,
  getProductsListService,
  updateProductService,
} from "../../services/productsService";

import useGlobalLoading from "./useGlobalLoading";
import useAuth from "./useAuth";

const ProductsProviderContext = createContext({} as IProductsContext);

type ProductsProps = {
  children: React.ReactNode;
};

function ProductsProvider({ children }: ProductsProps) {
  const { setOpenLoading } = useGlobalLoading();
  const { setOpen, setToastData } = useToast();
  const { user } = useAuth();
  const [productsData, setProductsData] = useState({} as Products);
  const [product, setProduct] = useState({} as ProductData);
  const [handleIDProduct, setHandleIDProduct] = useState<number | null>();

  const [records, setRecords] = useState(0);
  const [productsfilters, setProductsFilters] = useState<ProductsFilters>({
    name: undefined,
    offset: 0,
    itensPerPage: 18,
    manufacturerName: undefined,
    originName: undefined,
    versionName: undefined,
    inStock: undefined,
    outOfStock: undefined,
    preOrder: undefined,
    isPreOwned: undefined,
    productTypeName: undefined,
    isSale: false,
  });

  const [loading, setLoading] = useState({
    uploadLoading: false,
    productsLoading: false,
    submitProduct: false,
    getOneProduct: false,
    submitOneProduct: false,
  });

  const productsDataMemo = useMemo(() => productsData, [productsData]);

  const getProducts = async () => {
    setOpenLoading(true);

    try {
      const response = await getProductsListService(productsfilters);
      setProductsData(response);
      setRecords(Math.ceil(response.count / productsfilters.itensPerPage));
    } catch {
    } finally {
      setOpenLoading(false);
      // setTimeout(() => setOpenLoading(false), 5000);
    }
  };

  const getOneProduct = async (loading?: boolean) => {
    if (loading) {
      setLoading((old) => ({ ...old, getOneProduct: true }));
      setOpenLoading(true);
    }

    try {
      const response = await getOneProductService(
        handleIDProduct,
        user?.user?.id
      );
      setProduct(response.data);
    } catch {
    } finally {
      setLoading((old) => ({ ...old, oneOrder: false }));
      if (loading) {
        setOpenLoading(false);
        setLoading((old) => ({ ...old, getOneProduct: false }));
      }
      // setTimeout(() => setOpenLoading(false), 5000);
    }
  };

  const submitProduct = async (data: SubmitProduct) => {
    setLoading((old) => ({ ...old, submitProduct: true }));
    try {
      await createProductService(data);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: "Successfully created product",
      });
      setOpen(true);
      return true;
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message:
          "An error occurred while creating the product, please try again.",
      });
      setOpen(true);
      return false;
    } finally {
      getProducts();
      setLoading((old) => ({ ...old, submitProduct: false }));
    }
  };

  const updateProduct = async (data: UpdateProduct) => {
    try {
      await updateProductService(data);
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: "Successfully updated product",
      });
      setOpen(true);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message:
          "An error occurred while updating the product, please try again.",
      });
      setOpen(true);
    } finally {
      getProducts();
      getOneProduct();
    }
  };

  const productRemoveImage = async (imageID: number) => {
    setLoading((old) => ({ ...old, getOneProduct: true }));
    try {
      await delImageFromProduct(imageID);
    } catch {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message:
          "An error occurred while removing the image of the product, please try again.",
      });
      setOpen(true);
    } finally {
      getOneProduct(true);
    }
  };

  const deleteProduct = async (id: number) => {
    try {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
        message: "Successfully deleted the product",
      });
      setOpen(true);
    } catch (err: any) {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message:
          "An error occurred while deleting the product, please try again.",
      });
      setOpen(true);
    } finally {
      getProducts();
    }
  };

  useEffect(() => {
    if (
      window.location.pathname === "/products" ||
      window.location.pathname === "/sale" ||
      window.location.pathname === "/admin/products"
    ) {
      getProducts();
    }
    // eslint-disable-next-line
  }, [productsfilters]);

  useEffect(() => {
    if (handleIDProduct > 0) {
      getOneProduct(true);
    }
    // eslint-disable-next-line
  }, [handleIDProduct]);

  return (
    <ProductsProviderContext.Provider
      value={{
        productsDataMemo,
        records,
        product,
        loading,
        productsfilters,
        handleIDProduct,
        getOneProduct,
        deleteProduct,
        productRemoveImage,
        updateProduct,
        submitProduct,
        setHandleIDProduct,
        setProductsFilters,
        setProduct,
      }}
    >
      {children}
    </ProductsProviderContext.Provider>
  );
}

export { ProductsProvider, ProductsProviderContext };

export default function useProducts() {
  return useContext(ProductsProviderContext);
}
