import { Box, Button, Typography } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";

import treasureBoxLogo from "../../../../../assets/images/NEWTREASUREBOXLOGO2.png";
import { useNavigate } from "react-router-dom";

import { FormikProps } from "formik";
import useAuth from "../../../../../hooks/context/useAuth";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { BoxContent } from "../../../Atoms";

interface Props {
  error: boolean;
  formik: FormikProps<IForgotPasswordFormValues>;
}

export function VerifyCodeForgotPasswordBox({ error, formik }: Props) {
  const { language } = useAuth();
  const { strings } = useTranslate();
  const navigate = useNavigate();

  const handle = () => {
    formik.handleSubmit();
  };

  return (
    <Box>
      <BoxContent>
        <img
          style={{ marginTop: -15, marginBottom: 20 }}
          src={treasureBoxLogo}
          width={150}
          alt="treasurebox logo"
        />
        <Typography mb={2}>
          {strings.forgotPassword.codeStep.code[language]}
        </Typography>
        <MuiOtpInput
          value={formik.values.code}
          onChange={(val) => formik.setFieldValue("code", val)}
          length={6}
          maxWidth={400}
          gap={1}
        />

        <Box mt={3} width={"100%"}>
          <Button variant="contained" fullWidth onClick={handle}>
            {strings.buttons.verifyBtn[language]}
          </Button>

          <Button
            variant="text"
            fullWidth
            onClick={() => navigate("/")}
            sx={{ bgcolor: "transparent" }}
            style={{ marginTop: 12 }}
          >
            {strings.buttons.cancelBtn[language]}
          </Button>
        </Box>
      </BoxContent>
    </Box>
  );
}
