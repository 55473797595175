import { Box, Button, Typography } from "@mui/material";
import { CustomModal } from "../../../../../components/AtomicDesign/Organisms";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";
import { colors } from "../../../../../theme/colors";
import { useDateTime } from "../../../../../hooks/useDateTime";
import { useJPYmoney } from "../../../../../hooks/useJPYmoney";
import { amber, green, grey, red } from "@mui/material/colors";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { chargeShippmentService } from "../../../../../services/shippmentsService";
import { filterByStatus } from "./labels";
import { SelectAddress } from "../Steps/Address";
import useShippments from "../../../../../hooks/context/useShippments";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { NewProductsToShippment } from "./NewProductsToShippment";
import useAuth from "../../../../../hooks/context/useAuth";
import { ConfirmButtons } from "../../../../../components/AtomicDesign/Atoms";
import {
  GenericInput,
  SimpleConfirmModal,
} from "../../../../../components/AtomicDesign/Molecules";
import CustomDatePicker from "../../../../../components/AtomicDesign/Organisms/DatePicker";

interface Props {
  open: boolean;
  onClose: VoidFunction;
  selectedShip: ShippmentData | null;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export function ShippmentDetails({ open, onClose, selectedShip }: Props) {
  const { updateShippment, getPersonalStorageForShip, updateShippmentAdmin } =
    useShippments();
  const { isSmalldesktop } = useBreakpoints();
  const { formatedDate } = useDateTime();
  const { JPYmoney } = useJPYmoney();
  const { user } = useAuth();
  const [openPaymentModal, setOpenPaymentModal] = useState(false);

  // edit address
  const [selectedShipType, setSelectedShipType] = useState({
    shipPrice: 0,
    selectedShipType: "",
  } as SelectedShipType);
  const [selectedAddress, setSelectedAddress] = useState({} as IAddress);
  const [editAddressModal, setEditAddressModal] = useState(false);
  // edit address

  //edit produtcs
  const [editModalProducts, setEditModalProducts] = useState(false);
  // edit products

  // cancel modal
  const [openCancelModal, setOpenCancelModal] = useState({
    open: false,
    type: "",
  });
  // cancel modal

  // tracking modal
  const [opentrackingModal, setOpentrackingModal] = useState({
    open: false,
    trackingCode: "",
  });
  //tracking modal

  const [openFinishModal, setOpenFinishModal] = useState(false);

  const formatShipType = (choice: ShipTypes) => {
    const types = {
      smallPacket: "Small Packet",
      EMS: "EMS",
      surface: "Sal Parcel",
      airParcel: "Air Parcel",
      domestic: "Domestic",
    };
    return types[choice];
  };

  const UserAddress = () => {
    const addressfind = selectedShip?.users?.address?.find(
      (adr) => adr.id === selectedShip?.addressID
    );
    if (addressfind) {
      const {
        address,
        adressNumber,
        country,
        city,
        state,
        addressComplement,
        neighborhood,
        postalCode,
        receiver,
      } = addressfind;
      return (
        <Box display={"flex"} flexDirection={"column"} gap={1} width={245}>
          <Typography
            sx={{
              background: colors.blue[900],
              color: "#FFFFFF",
              borderRadius: 2,
              p: 1,
            }}
          >
            Address info
          </Typography>
          <Typography>
            Address: {address} - {adressNumber}
          </Typography>
          <Typography>Country: {country}</Typography>
          <Typography>
            City: {city} - {state}
          </Typography>
          <Typography>Complement: {addressComplement}</Typography>
          <Typography>Neighborhood: {neighborhood}</Typography>
          <Typography>Postal Code: {postalCode}</Typography>
          <Typography>Receiver: {receiver}</Typography>
          {/* <Typography>SSN: {ssn}</Typography> */}
          {selectedShip?.status === "editDeliveryAddress" && (
            <Button
              variant="contained"
              onClick={() => setEditAddressModal(true)}
            >
              Edit
              <ModeEditIcon sx={{ ml: 1 }} />
            </Button>
          )}
        </Box>
      );
    } else {
      return <></>;
    }
  };

  const fetchClientSecret = async () => {
    // await createCreditCardCharge(payload);
    const options = await chargeShippmentService({
      shippmentID: selectedShip?.id,
    });

    return options;
  };

  const updateAddressInShippment = async () => {
    try {
      await updateShippment({
        addressID: selectedAddress?.id,
        shippingCost: selectedShipType?.shipPrice,
        shippmentID: selectedShip?.id,
        shipType: selectedShipType?.selectedShipType,
      });
    } catch {
    } finally {
      cleanEdit();
    }
  };

  const cleanEdit = () => {
    setSelectedAddress({} as IAddress);
    setSelectedShipType({} as SelectedShipType);
    setEditAddressModal(false);
  };

  useEffect(() => {
    if (editModalProducts) {
      getPersonalStorageForShip();
    }
    // eslint-disable-next-line
  }, [editModalProducts]);

  const isAdmin = user?.user?.role === "admin";

  const changeShippmentStatus = async (status: ShippmentsStatus) => {
    await updateShippmentAdmin({ shippmentID: selectedShip?.id, status });
    setOpenCancelModal({ open: false, type: "" });
  };

  const changeTrackingCode = async (trackingCode: string) => {
    await updateShippmentAdmin({ shippmentID: selectedShip?.id, trackingCode });
  };

  const showAdminBtns = () => {
    const nowshow = ["shipped", "cancelWithRefund", "canceled"];
    if (nowshow.includes(selectedShip?.status)) return false;
    return true;
  };

  const canGoBySmallPacket = () => {
    const canGo = selectedShip?.shippmentXPersonalStorage?.some(
      (prd) => !prd?.PersonalStorageXProduct?.Products?.canGoSmallPacket
    );

    if (canGo) return false;
    return true;
  };

  if (!selectedShip) {
    onClose();
    return <></>;
  }

  return (
    <CustomModal
      openModal={open}
      setOpenModal={onClose}
      width={isSmalldesktop ? "90%" : "40%"}
    >
      <Box
        display={"flex"}
        justifyContent={"space-around"}
        flexWrap={"wrap"}
        gap={2}
      >
        <Box display={"flex"} gap={1} flexDirection={"column"} width={245}>
          <Typography
            sx={{
              background: colors.blue[900],
              color: "#FFFFFF",
              borderRadius: 2,
              p: 1,
            }}
          >
            Shipment info
          </Typography>
          <Typography>Shipment: {selectedShip?.id}</Typography>
          <Typography>
            Created at: {formatedDate(selectedShip?.createdAt)}
          </Typography>
          <Box
            sx={{
              border: isAdmin ? "1px solid gray" : "none",
              borderRadius: 2,
              p: isAdmin ? 1 : 0,
            }}
          >
            <Typography mb={isAdmin ? 1 : 0}>
              Shipped: {formatedDate(selectedShip?.shippedDate)}
            </Typography>
            {isAdmin && (
              <CustomDatePicker
                label="Change ship date"
                onChangeDate={async (date) =>
                  await updateShippmentAdmin({
                    shippmentID: selectedShip?.id,
                    shippedDate: date,
                  })
                }
                date={new Date(selectedShip?.shippedDate)}
              />
            )}
          </Box>
          <Typography>Price: {JPYmoney(selectedShip?.shippingFee)}</Typography>
          <Typography>
            Method: {formatShipType(selectedShip?.shipType)}
          </Typography>
          <Typography>
            Status: {filterByStatus[selectedShip?.status]?.text}
          </Typography>
          <Box
            sx={{
              border: isAdmin ? "1px solid gray" : "none",
              borderRadius: 2,
              p: isAdmin ? 1 : 0,
            }}
          >
            <Typography>Tracking code: {selectedShip?.trackingCode}</Typography>
            {isAdmin && (
              <Button
                variant="contained"
                onClick={() =>
                  setOpentrackingModal({ open: true, trackingCode: "" })
                }
              >
                Edit <ModeEditIcon sx={{ ml: 1 }} />
              </Button>
            )}
          </Box>
        </Box>

        <UserAddress />
      </Box>
      {isAdmin && (
        <Box
          sx={{ border: "1px solid grey", borderRadius: 2, mb: 2, mt: 2, p: 1 }}
        >
          <Typography textAlign={"center"}>User info</Typography>
          <Typography>E-mail: {selectedShip?.users?.email} </Typography>
          <Typography sx={{ wordBreak: "break-all" }}>
            Name: {selectedShip?.users?.name}
          </Typography>
          <Typography sx={{ wordBreak: "break-all" }}>
            ID: {selectedShip?.users?.id}
          </Typography>
        </Box>
      )}
      {isAdmin && selectedShip?.paymentIntent !== "" && (
        <Box
          sx={{ border: "1px solid grey", borderRadius: 2, mb: 2, mt: 2, p: 1 }}
        >
          <Typography textAlign={"center"}>Payment info</Typography>
          <Typography>
            Payment intent: {selectedShip?.paymentIntent}{" "}
          </Typography>
          <Typography sx={{ wordBreak: "break-all" }}>
            Charge: {selectedShip?.chargeID}
          </Typography>
        </Box>
      )}

      <Typography
        textAlign={"center"}
        p={2}
        sx={{
          background: filterByStatus[selectedShip?.status]?.color,
          color: "#FFFFFF",
          borderRadius: 5,
          mt: 1,
          mb: 2,
        }}
        variant="h1"
      >
        {filterByStatus[selectedShip?.status]?.text}
      </Typography>
      {selectedShip?.status === "editDeliveryProducts" && !isAdmin && (
        <Button
          fullWidth
          variant="contained"
          onClick={() => setEditModalProducts(true)}
        >
          Edit products
          <CardGiftcardIcon sx={{ ml: 1 }} />
        </Button>
      )}
      <Box display={"flex"} flexWrap={"wrap"} width={"100%"} p={1} gap={1}>
        {selectedShip?.shippmentXPersonalStorage?.map((prds, index) => (
          <Box sx={{ border: "1px dotted gray", borderRadius: 2 }} key={index}>
            <img
              src={
                prds?.PersonalStorageXProduct?.Products?.productsImages[0]?.code
              }
              style={{
                objectFit: "contain",
                width: 100,
                height: 100,
                borderRadius: 5,
                background: grey[200],
              }}
              alt={prds?.PersonalStorageXProduct?.Products?.characterName}
            />
            <Typography
              width={100}
              textAlign={"center"}
              height={148}
              overflow={"hidden"}
            >
              {prds?.PersonalStorageXProduct?.Products?.characterName}
            </Typography>
            {/* <Typography width={100} mt={1}>
              Product id: {prds?.PersonalStorageXProduct?.Products?.id}
            </Typography> */}
            <Typography width={100} mt={1}>
              Quantity in ship: {prds?.quantity}
            </Typography>
          </Box>
        ))}
      </Box>

      {selectedShip?.status === "waitingPayment" && !isAdmin && (
        <Button
          variant="contained"
          fullWidth
          onClick={() => setOpenPaymentModal(true)}
        >
          pay now
        </Button>
      )}

      {isAdmin && showAdminBtns() && (
        <Box display={"flex"} flexDirection={"column"} gap={1}>
          {selectedShip?.status !== "paymentSent" && (
            <>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  background: amber[500],
                  "&:hover": { background: amber[200] },
                }}
                onClick={() => changeShippmentStatus("waitingPayment")}
              >
                Waiting payment
              </Button>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  background: grey[500],
                  "&:hover": { background: grey[200] },
                }}
                onClick={() => changeShippmentStatus("editDeliveryAddress")}
              >
                Edit address
              </Button>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  background: grey[800],
                  "&:hover": { background: grey[300] },
                }}
                onClick={() => changeShippmentStatus("editDeliveryProducts")}
              >
                Edit Products
              </Button>{" "}
            </>
          )}
          <Button
            fullWidth
            variant="contained"
            sx={{
              background: green[500],
              "&:hover": { background: green[300] },
            }}
            onClick={() => {
              setOpenFinishModal(true);
            }}
          >
            Shipped
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{
              background: red[500],
              "&:hover": { background: red[200] },
            }}
            onClick={() =>
              setOpenCancelModal((old) => ({ ...old, open: true }))
            }
          >
            Cancel shipment
          </Button>
        </Box>
      )}

      <SimpleConfirmModal
        cancelClick={() => setOpenFinishModal(false)}
        confirmClick={() => {
          changeShippmentStatus("shipped");
          setOpenFinishModal(false);
        }}
        open={openFinishModal}
        text="Confirm this shipment to change to 'Shipped' status ?"
      />

      <NewProductsToShippment
        onClose={() => {
          setEditModalProducts(false);
          onClose();
        }}
        open={editModalProducts}
        selectedShip={selectedShip}
      />

      <CustomModal
        openModal={opentrackingModal.open}
        setOpenModal={() => {
          setOpentrackingModal({ trackingCode: "", open: false });
        }}
        width={isSmalldesktop ? "90%" : "60%"}
        disableBackdropClick
      >
        <GenericInput
          hasIcon={false}
          onChange={(value) => {
            setOpentrackingModal((old) => ({ ...old, trackingCode: value }));
          }}
          value={opentrackingModal.trackingCode}
          placeholder={"Tracking code"}
          inputName="trackingCode"
        />
        <ConfirmButtons
          disabled={opentrackingModal.trackingCode === ""}
          confirmClick={() => {
            changeTrackingCode(opentrackingModal.trackingCode);
          }}
          cancelClick={() =>
            setOpentrackingModal({ trackingCode: "", open: false })
          }
        />
      </CustomModal>

      <CustomModal
        openModal={openCancelModal.open}
        setOpenModal={() => {
          setOpenCancelModal({ type: "", open: false });
        }}
        width={isSmalldesktop ? "90%" : "60%"}
        disableBackdropClick
      >
        <Typography variant="h1" textAlign={"center"}>
          Click in cancel type
        </Typography>
        <Box display={"flex"} justifyContent={"center"} gap={2} mb={3} mt={3}>
          <Button
            variant="contained"
            sx={{
              background: red[500],
              "&:hover": { background: red[200] },
              border:
                openCancelModal.type === "canceled"
                  ? "2px solid green"
                  : "none",
            }}
            onClick={() =>
              setOpenCancelModal((old) => ({ ...old, type: "canceled" }))
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              background: amber[900],
              "&:hover": { background: amber[500] },
              border:
                openCancelModal.type === "cancelWithRefund"
                  ? "2px solid green"
                  : "none",
            }}
            onClick={() =>
              setOpenCancelModal((old) => ({
                ...old,
                type: "cancelWithRefund",
              }))
            }
          >
            Cancel and refund to credit card
          </Button>
        </Box>
        <Typography textAlign={"center"} mb={3}>
          {openCancelModal.type === "cancelWithRefund"
            ? `With "CANCEL AND REFUND TO CREDIT CARD", all ${JPYmoney(
                selectedShip?.shippingFee
              )} money in this shipment will back to credit card and products will return to personal storage`
            : "This shipment will be canceled and products will return to personal storage"}
        </Typography>
        <ConfirmButtons
          disabled={openCancelModal.type === ""}
          confirmClick={() => {
            changeShippmentStatus(openCancelModal.type as any);
          }}
          cancelClick={() => setOpenCancelModal({ type: "", open: true })}
        />
      </CustomModal>

      <CustomModal
        openModal={editAddressModal}
        setOpenModal={() => {
          setEditAddressModal(false);
        }}
        width={isSmalldesktop ? "90%" : "60%"}
        disableBackdropClick
        title={"Please, insert your new address"}
      >
        <SelectAddress
          setSelectedShipType={setSelectedShipType}
          selectedShipType={selectedShipType}
          selectedAddress={selectedAddress}
          totalProductsWeight={selectedShip?.shippmentXPersonalStorage?.reduce(
            (acc, next) =>
              acc +
              next?.PersonalStorageXProduct?.Products?.weight * next?.quantity,
            0
          )}
          onClickNext={() => updateAddressInShippment()}
          onClicBack={cleanEdit}
          setSelectedAddress={(address) => setSelectedAddress(address)}
          canGoSmallPacket={canGoBySmallPacket()}
        />
      </CustomModal>

      <CustomModal
        openModal={openPaymentModal}
        setOpenModal={() => {
          setOpenPaymentModal(false);
        }}
        width={isSmalldesktop ? "90%" : "60%"}
        disableBackdropClick
        title={"Please, insert your credit card data"}
      >
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{
            fetchClientSecret,
          }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </CustomModal>
    </CustomModal>
  );
}
