import { Box, Button, Typography } from "@mui/material";
import { ProductToShip } from "../components/ProductToShip";
import { ProductCard } from "../components/ProductCard";
import { FadeInContainer } from "../../../../../components/AtomicDesign/Atoms";
import { grey, teal } from "@mui/material/colors";

interface Props {
  personalStorageToShipDataMemo: PersonalStorageToShip[];
  selectedList: PersonalStorageToShip[];
  setSelectedList: (item: PersonalStorageToShip[]) => void;
  isLoading: boolean;
  onClickNext: VoidFunction;
  hasRemove?: boolean;
  onClickRemove?: (data: PersonalStorageToShip[]) => void;
  removedList?: PersonalStorageToShip[];
  shippmentData?: ShippmentData;
}

export function SelectProduct({
  selectedList,
  personalStorageToShipDataMemo,
  isLoading,
  setSelectedList,
  onClickNext,
  hasRemove = false,
  onClickRemove,
  removedList,
  shippmentData,
}: Props) {
  const selectPrdRemove = (item: PersonalStorageToShip) => {
    const find = removedList?.find((prd) => prd?.id === item?.id);

    if (find) {
      const filter = removedList?.filter((prd) => prd?.id !== item?.id);
      onClickRemove([...filter]);
    } else {
      onClickRemove([...removedList, item]);
    }
  };

  const findPrdRemove = (item: PersonalStorageToShip) => {
    const find = removedList?.find((prd) => prd?.id === item?.id);
    if (find) return true;
    return false;
  };

  return (
    <FadeInContainer>
      <ProductToShip
        isLoading={isLoading}
        list={personalStorageToShipDataMemo}
        selectedList={selectedList}
        setSelectedList={setSelectedList}
      />
      {selectedList?.length > 0 && (
        <>
          <Typography textAlign={"center"} mt={2} mb={2}>
            Products in shipment
          </Typography>
          <Box
            display={"flex"}
            justifyContent={"center"}
            sx={{ overflowX: "auto" }}
            width={"100%"}
            gap={1}
          >
            {selectedList?.length > 0 &&
              selectedList.map((prd, index) => (
                <ProductCard
                  index={index}
                  hasClick={false}
                  prd={prd}
                  inputDisabled
                />
              ))}
          </Box>
          {!removedList?.length && (
            <Box
              display={"flex"}
              width={"100%"}
              justifyContent={"flex-end"}
              mt={2}
            >
              <Button
                onClick={onClickNext}
                variant="contained"
                sx={{
                  background: teal[500],
                  "&:hover": { background: teal[100] },
                  height: 50,
                  width: 100,
                }}
              >
                Next
              </Button>
            </Box>
          )}
        </>
      )}

      {hasRemove && (
        <Typography textAlign={"center"}>
          Products already in shippment, click to mark to remove if you want
        </Typography>
      )}
      <Box display={"flex"} flexWrap={"wrap"} gap={1}>
        {hasRemove &&
          shippmentData?.shippmentXPersonalStorage?.map((prds) => (
            <Box
              onClick={() =>
                selectPrdRemove({
                  ...prds.PersonalStorageXProduct,
                  quantityToShip: prds.quantity,
                })
              }
              sx={{
                background: findPrdRemove({
                  ...prds.PersonalStorageXProduct,
                  quantityToShip: prds.quantity,
                })
                  ? "gray"
                  : "transparent",
                width: 100,
                borderRadius: 2,
                border: findPrdRemove({
                  ...prds.PersonalStorageXProduct,
                  quantityToShip: prds.quantity,
                })
                  ? "1px solid red"
                  : "none",
              }}
            >
              <img
                src={
                  prds?.PersonalStorageXProduct?.Products?.productsImages[0]
                    ?.code
                }
                style={{
                  objectFit: "contain",
                  width: 100,
                  height: 100,
                  borderRadius: 5,
                  background: grey[200],
                }}
                alt={prds?.PersonalStorageXProduct?.Products?.characterName}
              />
              <Typography
                width={100}
                textAlign={"center"}
                height={148}
                overflow={"hidden"}
              >
                {prds?.PersonalStorageXProduct?.Products?.characterName}
              </Typography>
              <Typography width={100} mt={1}>
                Product id: {prds?.PersonalStorageXProduct?.Products?.id}
              </Typography>
              <Typography width={100} mt={1}>
                Quantity in ship: {prds?.quantity}
              </Typography>
            </Box>
          ))}
      </Box>
      {hasRemove && removedList.length > 0 && (
        <Box display={"flex"} width={"100%"} justifyContent={"flex-end"} mt={2}>
          <Button
            onClick={onClickNext}
            variant="contained"
            sx={{
              background: teal[500],
              "&:hover": { background: teal[100] },
              height: 50,
              width: 100,
            }}
          >
            Next
          </Button>
        </Box>
      )}
    </FadeInContainer>
  );
}
