import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Popover,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { colors } from "../../../../theme/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NoData, SimpleTextBox } from "../../Atoms";
import { useDateTime } from "../../../../hooks/useDateTime";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { amber, green, grey, lightBlue, red } from "@mui/material/colors";
import { CustomModal } from "../../Organisms";
import { useState } from "react";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { ProductComponent } from "./ProductComponent";
import { ProductComponentCancel } from "./ProductComponentCancel";
import { SimpleConfirmModal } from "../SimpleConfirmModal";
import { cancelOrderService } from "../../../../services/ordersService";

interface Props {
  orders: OrderData[];
  loadingLine: { index: number | null; loading: boolean };
  getPayInfo: (orderID: number, chargeID: string, index: number) => void;
  verifyAndCharge: (orderID: number, chargeID: string) => void;
  isAdmin?: Boolean;
  getOrders?: VoidFunction;
}

export function OrderAcordion({
  isAdmin = false,
  orders,
  loadingLine,
  getPayInfo,
  verifyAndCharge,
  getOrders,
}: Props) {
  const { formatedDate } = useDateTime();
  const { JPYmoney } = useJPYmoney();
  const { isSmalldesktop } = useBreakpoints();

  const [openModal, setOpenModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({} as OrderData);
  const [selectedOptions, setSelectedOptions] = useState({
    cancelType: "",
    itens: [],
    refundValue: 0,
  } as CancelPayload);
  const [cancelOrderLoading, setCancelOrderLoading] = useState(false);
  const [errortoCancel, setErrorToCancel] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const cleanSelectedOrder = () => {
    setSelectedOrder({} as OrderData);
    setOpenModal(false);
    setSelectedOptions({ cancelType: "", itens: [], refundValue: 0 });
  };

  const renderbuttontopay = (
    order: OrderData,
    index: number,
    renderButton: boolean
  ) => {
    if (!renderButton) {
      return <></>;
    }
    const height = "42px";
    if (order.paymentStatus === "processing") {
      return (
        <Button
          variant="contained"
          onClick={() => getPayInfo(order.id, order.chargeID, index)}
          sx={{ height }}
        >
          Verify payment
        </Button>
      );
    }
    if (order.paymentStatus === "unpaid" && !isAdmin) {
      return (
        <Button
          variant="contained"
          onClick={() => {
            verifyAndCharge(order.id, order.chargeID);
          }}
          sx={{ height }}
        >
          Verify payment
        </Button>
      );
    }
  };

  const refundValue = (items: ItensToCancel[], isTotalWithRefund?: boolean) => {
    if (!items) return 0;
    if (
      !isTotalWithRefund &&
      ["partialWithoutRefund", "totalWithoutRefund"].includes(
        selectedOptions.cancelType
      )
    ) {
      return 0;
    }
    const preOrderPaid = items?.reduce((acc, next) => {
      if (next?.isPreOrder && next?.preOrderPaid) {
        return acc + next?.boughtPrice * next?.quantityToCancel;
      } else {
        return acc;
      }
    }, 0);

    const commumPRD = items?.reduce((acc, next) => {
      if (!next?.isPreOrder) {
        return acc + next?.boughtPrice * next?.quantityToCancel;
      } else {
        return acc;
      }
    }, 0);

    return preOrderPaid + commumPRD;
  };

  const cancelOrder = async () => {
    if (selectedOptions.itens.some((item) => item.quantityToCancel <= 0)) {
      setErrorToCancel(true);
    } else {
      setErrorToCancel(false);
    }
    setCancelOrderLoading(true);
    try {
      await cancelOrderService(selectedOptions);
    } catch {
    } finally {
      getOrders();
      setCancelOrderLoading(false);
      cleanSelectedOrder();
      setConfirmModal(false);
    }
  };

  const updateQuantityToCancel = (item: ItensToCancel[]) => {
    const map = item.map((prd) => ({ ...prd, quantityToCancel: prd.quantity }));
    return map;
  };

  const verifyCanceledString = (status: "canceled" | "partial-canceled") => {
    if (status === "canceled") return "Canceled";
    if (status === "partial-canceled") return "Partial canceled";
  };

  return (
    <>
      {orders?.length > 0 ? (
        orders?.map((order, index) => (
          <Accordion
            sx={{
              width: "100%",
              background: colors.blue[900],
            }}
            key={index}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ fill: "#FFFFFF" }} />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ border: "1px solid #FFFFFF" }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: [2, 4],
                }}
              >
                <SimpleTextBox title="Order number" description={order?.id} />
                <SimpleTextBox
                  title="Created at"
                  description={formatedDate(order?.createdAt)}
                />
                <SimpleTextBox
                  title="Total paid"
                  description={JPYmoney(
                    order?.ordersXproducts?.reduce((acc, next) => {
                      if (!next?.isPreOrder) {
                        return acc + next?.Products?.price * next?.quantity;
                      } else {
                        return acc;
                      }
                    }, 0)
                  )}
                />
                {order?.ordersXproducts?.some(
                  (prd) => prd?.Products?.isPreOrder
                ) && (
                  <SimpleTextBox
                    title="Pre-order (pay in release)"
                    description={JPYmoney(
                      order?.ordersXproducts?.reduce((acc, next) => {
                        if (next?.isPreOrder) {
                          return acc + next?.Products?.price * next?.quantity;
                        } else {
                          return acc;
                        }
                      }, 0)
                    )}
                  />
                )}
                {order?.ordersXproducts?.some(
                  (prd) => prd?.Products?.isPreOrder
                ) && (
                  <SimpleTextBox
                    title="Total pre-order paid"
                    description={JPYmoney(
                      order?.ordersXproducts?.reduce((acc, next) => {
                        if (next?.isPreOrder && next?.preOrderPaid) {
                          return acc + next?.Products?.price * next?.quantity;
                        } else {
                          return acc;
                        }
                      }, 0)
                    )}
                  />
                )}
                {order?.ordersXproducts?.reduce((acc, next) => {
                  if (next?.isPreOrder) {
                    return acc;
                  } else {
                    return acc + next?.Products?.price * next?.quantity;
                  }
                }, 0) > 0 &&
                  order.paymentStatus !== "paid" && (
                    <SimpleTextBox
                      title="Status(not for pre-orders)"
                      description={order?.paymentStatus}
                      descriptionColor="red"
                    />
                  )}

                {loadingLine.index === index && loadingLine.loading ? (
                  <CircularProgress color="warning" />
                ) : (
                  renderbuttontopay(
                    order,
                    index,
                    order?.ordersXproducts?.reduce((acc, next) => {
                      if (next?.isPreOrder) {
                        return acc;
                      } else {
                        return acc + next?.Products?.price * next?.quantity;
                      }
                    }, 0) > 0
                  )
                )}
                {order?.orderStatus !== "ok" && (
                  <Typography
                    color={"#FFFFFF"}
                    sx={{ background: red[300], borderRadius: 1, p: 1 }}
                  >
                    {verifyCanceledString(order?.orderStatus)}
                  </Typography>
                )}
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ background: grey[200] }}>
              <ProductComponent
                selectedOrdersXproducts={order?.ordersXproducts}
                changeQuantityToCancel={() => {}}
              />
              {isAdmin && order?.users && (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    minWidth: "100%",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    overflowX: "auto",
                    gap: 1,
                    mt: 2,
                  }}
                >
                  <Typography color={grey[500]}>
                    Name:{" "}
                    <span style={{ color: "black" }}>{order?.users?.name}</span>
                  </Typography>
                  <Typography color={grey[500]}>
                    ID:{" "}
                    <span style={{ color: "black" }}>{order?.users?.id}</span>
                  </Typography>
                  <Typography color={grey[500]}>
                    Email:{" "}
                    <span style={{ color: "black" }}>
                      {order?.users?.email}
                    </span>
                  </Typography>
                  <Typography color={grey[500]}>
                    Phone:{" "}
                    <span style={{ color: "black" }}>
                      {order?.users?.phone}
                    </span>
                  </Typography>
                  <Typography color={grey[500]}>
                    Personal storage:{" "}
                    <span style={{ color: "black" }}>
                      {order?.users?.personalStorage[0]?.id}
                    </span>
                  </Typography>
                  <Button
                    sx={{
                      background: red[500],
                      color: "white",
                      "&:hover": { background: red[200] },
                    }}
                    onClick={() => {
                      setSelectedOrder(order);
                      setOpenModal(true);
                    }}
                  >
                    Cancel order
                  </Button>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <NoData text="No orders found" />
      )}

      <CustomModal
        width={isSmalldesktop ? "90%" : "60%"}
        title="Cancel order"
        openModal={openModal}
        setOpenModal={cleanSelectedOrder}
        disableBackdropClick
      >
        <Box p={2}>
          <Typography>
            Here you can partially cancel the order or all items in it, please
            select the desired option
          </Typography>

          {selectedOrder?.ordersXproducts?.some((prd) => prd?.shipped) && (
            <FormHelperText
              sx={{
                bgcolor: amber[100],
                borderRadius: "12px",
                p: 1,
                textAlign: "center",
              }}
            >
              <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
              Some products of this order already been shipped, you can't select
              "total cancel options"
            </FormHelperText>
          )}

          <RadioGroup sx={{ display: "flex", flexDirection: "row" }}>
            <FormControlLabel
              value={"totalWithRefund"}
              control={<Radio />}
              label={"Total with refund"}
              disabled={selectedOrder?.ordersXproducts?.some(
                (prd) => prd?.shipped
              )}
              onChange={() =>
                setSelectedOptions({
                  itens: updateQuantityToCancel(selectedOrder.ordersXproducts),
                  cancelType: "totalWithRefund",
                  refundValue: refundValue(
                    updateQuantityToCancel(selectedOrder.ordersXproducts),
                    true
                  ),
                })
              }
              checked={selectedOptions.cancelType === "totalWithRefund"}
            />

            <FormControlLabel
              value={"totalWithoutRefund"}
              control={<Radio />}
              label={"Total without refund"}
              disabled={selectedOrder?.ordersXproducts?.some(
                (prd) => prd?.shipped
              )}
              onChange={() =>
                setSelectedOptions((old) => ({
                  refundValue: 0,
                  itens: updateQuantityToCancel(selectedOrder.ordersXproducts),
                  cancelType: "totalWithoutRefund",
                }))
              }
              checked={selectedOptions.cancelType === "totalWithoutRefund"}
            />

            <FormControlLabel
              value={"partialWithRefund"}
              control={<Radio />}
              label={"Partial with refund"}
              onChange={() =>
                setSelectedOptions((old) => ({
                  refundValue: 0,
                  itens: [],
                  cancelType: "partialWithRefund",
                }))
              }
              checked={selectedOptions.cancelType === "partialWithRefund"}
            />

            <FormControlLabel
              value={"partialWithoutRefund"}
              control={<Radio />}
              label={"Partial without refund"}
              onChange={() =>
                setSelectedOptions((old) => ({
                  refundValue: 0,
                  itens: [],
                  cancelType: "partialWithoutRefund",
                }))
              }
              checked={selectedOptions.cancelType === "partialWithoutRefund"}
            />
          </RadioGroup>
        </Box>

        {["partialWithoutRefund", "partialWithRefund"].includes(
          selectedOptions.cancelType
        ) && (
          <Box display={"flex"} width={"100%"} justifyContent={"center"}>
            <Button
              aria-describedby={id}
              onClick={handleClick}
              sx={{
                mt: 2,
                color: "white",
                bgcolor: lightBlue["A700"],
                "&: hover": { bgcolor: lightBlue[300] },
              }}
            >
              Click to select products to refund
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <Box p={2}>
                {isSmalldesktop && (
                  <Button onClick={handleClose} variant="outlined">
                    Close
                  </Button>
                )}
                <ProductComponentCancel
                  onClick={(prd) =>
                    setSelectedOptions((old) => ({
                      ...old,
                      itens: updateQuantityToCancel(prd),
                      refundValue: refundValue(updateQuantityToCancel(prd)),
                    }))
                  }
                  ordersXproducts={selectedOrder.ordersXproducts}
                  selectedPRDS={selectedOptions.itens}
                />
              </Box>
            </Popover>
          </Box>
        )}

        <ProductComponent
          selectedOrdersXproducts={selectedOptions?.itens}
          isPartialCancelation={[
            "partialWithoutRefund",
            "partialWithRefund",
          ].includes(selectedOptions.cancelType)}
          changeQuantityToCancel={(selectedPrds) =>
            setSelectedOptions((old) => ({
              ...old,
              itens: selectedPrds,
              refundValue: refundValue(selectedPrds),
            }))
          }
        />

        <Typography textAlign={"center"}>
          Total refund: {JPYmoney(selectedOptions.refundValue)}
        </Typography>
        {errortoCancel && (
          <FormHelperText error sx={{ textAlign: "center" }}>
            You can't cancel products with 0 parameter
          </FormHelperText>
        )}
        {selectedOptions?.itens?.length > 0 && (
          <FormHelperText
            sx={{
              bgcolor: amber[100],
              borderRadius: "12px",
              p: 1,
              textAlign: "center",
            }}
          >
            <WarningAmberRoundedIcon sx={{ marginBottom: -0.8 }} />
            Please be assured that these products must be cancelled, upon
            completion of the process, the selected products will be returned to
            stock and removed from the customer's personal storage.
          </FormHelperText>
        )}

        <Box display={"flex"} justifyContent={"center"} gap={5} mt={1}>
          <Button
            sx={{ bgcolor: green[500], color: "white" }}
            onClick={() => setConfirmModal(true)}
            fullWidth
          >
            Confirm
          </Button>
          <Button
            sx={{ bgcolor: red[500], color: "white" }}
            onClick={() => setConfirmModal(false)}
            fullWidth
          >
            Cancel
          </Button>
        </Box>
      </CustomModal>

      <SimpleConfirmModal
        isLoading={cancelOrderLoading}
        cancelClick={() => {
          setConfirmModal(false);
        }}
        confirmClick={cancelOrder}
        open={confirmModal}
        text="Confirm refund choice? Refunds take 5 to 10 days to appear on the customer's statement."
      />
    </>
  );
}
