import { Container, Tab, Tabs } from "@mui/material";
import useCart from "../../../hooks/context/useCart";
import { useEffect, useState } from "react";
import useAuth from "../../../hooks/context/useAuth";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { colors } from "../../../theme/colors";
import { CartStep } from "./steps/CartStep";
import { CheckoutStep } from "./steps/CheckoutStep";
import { FadeInContainer } from "../../../components/AtomicDesign/Atoms";

export function Cart() {
  const { updateCart, removeFromMyCart } = useCart();
  const { user } = useAuth();
  const [selectedProducts, setSelectedProducts] = useState<CartXProduct[]>([]);
  const [tabStep, setTabStep] = useState(1);
  const [colorTab, setColor] = useState("#FFFFFF");

  useEffect(() => {
    setColor("#FFF");
  }, []);

  const selectOrRemove = (cart: CartXProduct) => {
    const verify = selectedProducts?.find(
      (selectedPRD) => selectedPRD?.product?.id === cart?.product?.id
    );
    if (verify) {
      const remove = selectedProducts?.filter(
        (selectedPRD) => selectedPRD?.product?.id !== cart?.product?.id
      );
      setSelectedProducts(remove);
    } else {
      setSelectedProducts((old) => [...old, cart]);
    }
  };

  const addToCart = async (productID: number, quantity: number) => {
    await updateCart({
      cartID: user?.cartID,
      productID: productID,
      quantity: quantity,
    });
    if (selectedProducts.some((item) => item?.product?.id === productID)) {
      const remove = selectedProducts?.filter(
        (selectedPRD) => selectedPRD?.product?.id !== productID
      );
      setSelectedProducts(remove);
    }
  };

  const removeFromCart = async (productID: number, quantity: number) => {
    await removeFromMyCart({
      cartID: user?.cartID,
      productID: productID,
      quantity: quantity,
    });
    if (selectedProducts.some((item) => item?.product?.id === productID)) {
      const remove = selectedProducts?.filter(
        (selectedPRD) => selectedPRD?.product?.id !== productID
      );
      setSelectedProducts(remove);
    }
  };

  const tabButtons = { minHeight: "50px", height: "50px" };

  return (
    <Container maxWidth={"xl"}>
      <Tabs
        value={tabStep}
        variant="fullWidth"
        sx={{
          "& .Mui-selected": {
            borderRadius: "5px 5px 0 0",
            background: colors.blue[900],
            color: colorTab,
          },
          "& .MuiTab-root": {
            fontSize: 16,

            "&: hover": {
              borderRadius: "5px 5px 0 0",
            },
            pointerEvents: "none",
          },
        }}
      >
        <Tab
          icon={<ShoppingCartOutlinedIcon />}
          iconPosition="start"
          value={1}
          label="Cart"
          sx={tabButtons}
        />
        <Tab
          sx={tabButtons}
          value={2}
          label="Checkout"
          icon={<ShoppingCartCheckoutIcon />}
          iconPosition="start"
        />
      </Tabs>

      {tabStep === 1 && (
        <FadeInContainer>
          <CartStep
            addToCart={addToCart}
            selectOrRemove={selectOrRemove}
            selectedProducts={selectedProducts}
            setStep={() => setTabStep(2)}
            removeFromCart={removeFromCart}
          />
        </FadeInContainer>
      )}
      {tabStep === 2 && (
        <FadeInContainer>
          <CheckoutStep
            cleanStateCart={() => setSelectedProducts([])}
            selectedProducts={selectedProducts}
            setStep={() => setTabStep(1)}
          />
        </FadeInContainer>
      )}
    </Container>
  );
}
