import useOrders from "../../../../hooks/context/useOrders";
import { Box, Pagination } from "@mui/material";

import { useEffect, useState } from "react";
import useAuth from "../../../../hooks/context/useAuth";
import { updatePaymentStatus } from "../../../../services/cartService";
import { PaymentModalInList } from "../../../../components/AtomicDesign/Organisms";
import useToast from "../../../../hooks/context/useToast";
import { OrderAcordion } from "../../../../components/AtomicDesign/Molecules";

export function MyOrders() {
  const { ordersDataMemo, orderRecords, setFilters, getOrders } = useOrders();

  const { setOpen, setToastData } = useToast();
  const { user } = useAuth();
  const [loadingLine, setLoadingLine] = useState({
    index: null,
    loading: false,
  });

  const [page, setPage] = useState(1);
  const [open, seModaltOpen] = useState(false);
  const [myOrderID, setMyOrderID] = useState(0);
  const [attData, setAttData] = useState(false);

  useEffect(() => {
    if (user?.personalStorageID) {
      getOrders();
    }
    // eslint-disable-next-line
  }, [page, user, attData]);

  const getPayInfo = async (orderID, payID, index) => {
    setLoadingLine({ index, loading: true });
    try {
      await updatePaymentStatus({
        orderID: Number(orderID),
        sessionid: payID,
      });
      getOrders();
    } catch {
    } finally {
      setLoadingLine({ index: null, loading: true });
    }
  };

  const verifyAndCharge = async (orderID, payID) => {
    const response = await updatePaymentStatus({
      orderID: Number(orderID),
      sessionid: payID,
    });
    if (response.payStatus === "paid") {
      setToastData({
        message: "Your order has already been paid",
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "success",
      });
      setOpen(true);
      getOrders();
    }
    if (response.payStatus === "unpaid") {
      setMyOrderID(orderID);
      seModaltOpen(true);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      width={"100%"}
      mt={2}
    >
      <OrderAcordion
        getPayInfo={getPayInfo}
        loadingLine={loadingLine}
        verifyAndCharge={verifyAndCharge}
        orders={ordersDataMemo}
        getOrders={() => {}}
      />

      <Box display={"flex"} justifyContent={"center"} width={"100%"} mt={2}>
        <Pagination
          count={orderRecords}
          page={page}
          defaultPage={1}
          onChange={(_e, page) => {
            setFilters((old) => ({ ...old, offset: page - 1 }));
            setPage(page);
          }}
        />
      </Box>

      <PaymentModalInList
        myOrderID={myOrderID}
        open={open}
        setOpen={(val) => seModaltOpen(val)}
        setAttData={() => setAttData((old) => !old)}
      />
    </Box>
  );
}
