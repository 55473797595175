import { Box, Tab, Tabs } from "@mui/material";
import { amber, grey } from "@mui/material/colors";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import { useEffect, useState } from "react";
import { LoginPopover } from "../../AtomicDesign/Organisms";
import { GlobalContainer } from "../../AtomicDesign/Atoms";
import { Logo } from "../Logo";

// #2C3E50 blue 900
export function HeaderAdmin() {
  const [activeTab, setActiveTab] = useState(0);
  const nav = useNavigate();

  const location = useLocation();
  const { isSmalldesktop } = useBreakpoints();
  const [selectedColor, setColor] = useState("");

  const navigation = (where?: string, tab?: number) => {
    const tabs = tab !== undefined ? tab : activeTab;
    const loc = where ? where : location.pathname;
    setActiveTab(tabs);
    localStorage.setItem("@treasure_box_admintab", String(tabs));
    nav({
      pathname: loc,
    });
  };

  useEffect(() => {
    setColor("#FFF");

    const getLocalTab = localStorage.getItem("@treasure_box_admintab");
    if (getLocalTab) {
      setActiveTab(Number(JSON.parse(getLocalTab)));
    }
  }, [location.pathname]);

  return (
    <Box>
      <GlobalContainer sx={{ p: 0 }}>
        <Box
          pt={3}
          pb={3}
          p={2}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Logo />

          <LoginPopover />
        </Box>

        <Tabs
          value={activeTab}
          onChange={(_e, value) => setActiveTab(value)}
          aria-label="disabled tabs example"
          variant={isSmalldesktop ? "scrollable" : "fullWidth"}
          TabIndicatorProps={{
            style: { background: amber[500], color: "red" },
          }}
          scrollButtons
          allowScrollButtonsMobile
          sx={{
            "& .Mui-selected": {
              background: "#ed2623",
              borderRadius: "5px 5px 0 0",
              color: selectedColor,
            },
            "& .MuiTab-root": {
              fontSize: 16,
              "&: hover": {
                background: grey[200],
                borderRadius: "5px 5px 0 0",
              },
            },
          }}
        >
          <Tab
            label="USERS"
            value={0}
            onClick={() => navigation("/admin/users", 0)}
          />
          <Tab
            label="PRODUCTS"
            value={1}
            onClick={() => navigation("/admin/products", 1)}
          />
          <Tab
            label="ORDERS"
            value={2}
            onClick={() => navigation("/admin/orders", 2)}
          />
          <Tab
            label="DELIVERIES"
            value={3}
            onClick={() => navigation("/admin/deliveries", 3)}
          />
          <Tab
            label="MESSAGES"
            value={4}
            onClick={() => navigation("/admin/messages", 4)}
          />
          <Tab
            label="CONFIG"
            value={5}
            onClick={() => navigation("/admin/config", 5)}
          />
        </Tabs>

        <Box display={"flex"}>
          <Outlet />
          {/* <WarningMessage /> */}
        </Box>
      </GlobalContainer>
    </Box>
  );
}
