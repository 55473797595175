import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DrawerCheckout } from "../../../../components/AtomicDesign/Molecules";
import { colors } from "../../../../theme/colors";
import { amber, grey, teal } from "@mui/material/colors";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import useCart from "../../../../hooks/context/useCart";
import DeleteIcon from "@mui/icons-material/Delete";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";

interface Props {
  selectedProducts: CartXProduct[];
  selectOrRemove: (cart: CartXProduct) => void;
  addToCart: (productID: number, quantity: number) => void;
  removeFromCart: (productID: number, quantity: number) => void;
  setStep: VoidFunction;
}

export function CartStep({
  selectedProducts,
  selectOrRemove,
  addToCart,
  setStep,
  removeFromCart,
}: Props) {
  const { isMobile } = useBreakpoints();
  const { cartDataMemo } = useCart();
  const data = cartDataMemo[0];
  const { JPYmoney } = useJPYmoney();
  return (
    <>
      <Box display={"flex"} width={"100%"} gap={1} mt={2}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          minWidth={"100%"}
          justifyContent={"flex-start"}
        >
          {data?.cartXProduct?.map((item, index) => (
            <Box
              key={index}
              display={"flex"}
              flex={1}
              width={"100%"}
              alignItems={"flex-start"}
              flexDirection={"column"}
              sx={
                selectedProducts?.find(
                  (selectedPRD) =>
                    selectedPRD?.product?.id === item?.product?.id
                )
                  ? {
                      border: `1px solid ${colors.blue[400]}`,
                      borderRadius: 3,
                      background: teal[50],
                    }
                  : { border: "1px solid white" }
              }
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                width={"100%"}
              >
                <Checkbox
                  onChange={() => selectOrRemove(item)}
                  disabled={item?.quantity > item?.product?.stock}
                  checked={
                    selectedProducts?.find(
                      (selectedPRD) =>
                        selectedPRD?.product?.id === item?.product?.id
                    )
                      ? true
                      : false
                  }
                />
                <Box
                  width={isMobile ? 50 : 80}
                  minHeight={isMobile ? 65 : 80}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <img
                    src={item?.product?.productsImages[0]?.code}
                    style={{
                      objectFit: "contain",
                      width: isMobile ? 50 : 80,
                      height: isMobile ? 50 : 80,
                      borderRadius: 5,
                      background: grey[200],
                    }}
                    alt={item?.product?.characterName}
                  />
                </Box>
                <Box display={"flex"} width={"100%"} alignItems={"center"}>
                  <Typography
                    overflow={"hidden"}
                    ml={1}
                    width={"50%"}
                    textOverflow={"ellipsis"}
                    textAlign={"center"}
                    maxHeight={isMobile ? 50 : 80}
                  >
                    {item?.product?.characterName}
                  </Typography>

                  <Box
                    display={"flex"}
                    gap={2}
                    width={"50%"}
                    justifyContent={"center"}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <FormControl sx={{ width: 80 }}>
                        <InputLabel id="demo-simple-select-label">
                          Quantity
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          disabled={
                            selectedProducts?.find(
                              (selectedPRD) =>
                                selectedPRD?.product?.id === item?.product?.id
                            )
                              ? true
                              : false
                          }
                          label="Quantity"
                          defaultValue={item?.quantity}
                          defaultChecked
                          onChange={(e) =>
                            addToCart(item?.product?.id, Number(e.target.value))
                          }
                          sx={{ width: 70, height: 40 }}
                        >
                          {Array.from(
                            {
                              length: item?.product?.stock,
                            },
                            (_v, i) => i
                          ).map((qtd, index) => {
                            const removeZero = qtd + 1;
                            return (
                              <MenuItem value={removeZero} key={index}>
                                {removeZero}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      {item?.quantity > item?.product?.stock && (
                        <FormHelperText error sx={{ textAlign: "center" }}>
                          We don't have your previous quantity ({item?.quantity}
                          ) anymore, please select another one
                        </FormHelperText>
                      )}
                    </Box>
                  </Box>
                  <IconButton
                    sx={{
                      bgcolor: grey[300],
                      "&:hover": { bgcolor: grey[500] },
                      color: "white",
                      mr: 0.5,
                    }}
                    onClick={() =>
                      removeFromCart(item?.product?.id, item?.quantity)
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>

              <Typography
                textAlign={"center"}
                width={"100%"}
                sx={{
                  background: item?.product?.isPreOrder
                    ? amber[700]
                    : colors.blue[400],
                  borderRadius: "0 0 10px 10px",
                  color: "#FFFFFF",
                }}
              >
                {`${JPYmoney(item?.product?.price * item?.quantity)}${
                  item?.product?.isPreOrder ? " - Pre-Order" : ""
                }`}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <DrawerCheckout
        fullPrice={selectedProducts.reduce((acc, next) => {
          if (!next.product.isPreOrder) {
            return acc + next.product.price * next.quantity;
          } else {
            return acc;
          }
        }, 0)}
        preOrderPrice={selectedProducts.reduce((acc, next) => {
          if (next.product.isPreOrder) {
            return acc + next.product.price * next.quantity;
          } else {
            return acc;
          }
        }, 0)}
        totalItens={selectedProducts.length}
        nextStep={setStep}
        btnText="Go to checkout"
      />
    </>
  );
}
