import { Box, Tab, Tabs, Typography } from "@mui/material";
import { Carrousel } from "../../../components/AtomicDesign/Molecules";
import { useEffect, useState } from "react";

import { amber } from "@mui/material/colors";

import { getBannerService } from "../../../services/configService";
import { BannerUpload } from "./BannerUpload";
import { BannerLinks } from "./BannerLinks";

export function Config() {
  const [bannerNow, setBannerNow] = useState([] as Banner[]);
  const [activeTab, setActiveTab] = useState(0);
  const [update, setUpdate] = useState(false);

  // send

  // send
  const getBannerConfig = async () => {
    try {
      const response = await getBannerService();
      setBannerNow(response);
    } catch {}
  };

  useEffect(() => {
    getBannerConfig();
  }, [update]);

  return (
    <Box display={"flex"} flexDirection={"column"} width={"100%"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        mt={3}
      >
        <Typography variant="h1" mb={2}>
          Banner
        </Typography>
        <Carrousel list={bannerNow} />

        <Tabs
          value={activeTab}
          onChange={(_e, value) => setActiveTab(value)}
          aria-label="disabled tabs example"
          variant={"fullWidth"}
          sx={{ width: "100%", mt: 2 }}
          TabIndicatorProps={{
            style: { background: amber[500], color: "red" },
          }}
          scrollButtons
          allowScrollButtonsMobile
        >
          <Tab label="Upload photos" value={0} />
          <Tab label="Banner's links" value={1} />
        </Tabs>

        {activeTab === 0 && (
          <BannerUpload
            list={bannerNow}
            setUpdate={() => setUpdate((old) => !old)}
          />
        )}
        {activeTab === 1 && <BannerLinks />}
      </Box>
    </Box>
  );
}
