import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import MagicUrl from "quill-magic-url";
import { Box, FormHelperText, Typography } from "@mui/material";

Quill.register("modules/magicUrl", MagicUrl);

interface Props {
  onChange(val: string): void;
  value: string;
  title?: string;
  error?: boolean;
  errorMessage?: string;
}

export function HtmlTextEditor({
  onChange,
  value,
  title,
  error,
  errorMessage,
}: Props) {
  return (
    <Box width={"100%"}>
      <Typography>{title}</Typography>
      <ReactQuill
        style={{ background: "white", width: "100%", height: 200 }}
        value={value}
        onChange={onChange}
        modules={{ magicUrl: true }}
      />
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </Box>
  );
}
