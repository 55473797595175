import {
  CardActions,
  CardContent,
  Card,
  Box,
  Chip,
  Typography,
  Pagination,
} from "@mui/material";
import {
  DataText,
  NoData,
  CircularLoading,
  GlobalContainer,
} from "../../../Atoms";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";
import { CustomButton, Filter } from "../../../Molecules";
import { CustomModal } from "../../Modal";
import { useEffect, useState } from "react";
import { useLocalStorage } from "../../../../../hooks/useLocalStorage";
import { UpdateUserAdmin } from "../UpdateUserAdmin";
import { getUserServiceAdmin } from "../../../../../services/getUsersAdmin";
import { green, red } from "@mui/material/colors";

export function UsersCards() {
  const { isSmalldesktop, isMobile, mediumDesktop, semiSmalldesktop } =
    useBreakpoints();

  const { getFilterStorage, setFilters } = useLocalStorage();
  const [modalOpen, setModalOpen] = useState(false);
  const [handleUser, setHandleUser] = useState<UsersAdmin | null>();
  const [usersData, setUsersData] = useState<UsersAdmin[]>();
  const [records, setRecords] = useState(0);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const getUsersAdmin = async () => {
    setLoading(true);
    const filter = getFilterStorage();
    try {
      const response = await getUserServiceAdmin({
        searchKey: "name",
        searchTerm: filter.searchTerm,
        itensPerPage: filter.itensPerPage,
        offset: filter.offset,
      });
      setUsersData(response.data);
      setRecords(response.count);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUsersAdmin();
    // eslint-disable-next-line
  }, []);

  const titleWidth = 100;

  const breakpoints = () => {
    if (isMobile) return "100%";
    if (isSmalldesktop) return "48.5%";
    if (semiSmalldesktop) return "32%";
    if (mediumDesktop) return "23.9%";
    return "23%";
  };

  const closeModal = () => {
    setModalOpen(false);
    setHandleUser(null);
  };

  const cleanFilters = () => {
    getFilterStorage(true);
  };

  const changePage = (page: number) => {
    setFilters("offset", page - 1);
  };

  useEffect(() => {
    changePage(1);
    setPage(1);
    // eslint-disable-next-line
  }, [getFilterStorage().searchTerm, records]);

  return (
    <GlobalContainer sx={{ mt: 1 }}>
      <Box
        width={isSmalldesktop ? "95%" : "100%"}
        display={"flex"}
        flexDirection={"column"}
        px={1}
      >
        <Filter
          onChangeTextField={(val) => {
            setFilters("searchTerm", val);
            getUsersAdmin();
          }}
          onSelectField={(val) => setFilters("key", val)}
          selectedField={getFilterStorage().key}
          searchTerm={getFilterStorage().searchTerm}
          onClickCleanFilter={cleanFilters}
          handleItensperPage={(val) => {
            setFilters("itensPerPage", val);
            getUsersAdmin();
          }}
          itensPerPage={20}
          filterType={"user"}
        />

        <Box
          width={"auto"}
          display="flex"
          flexWrap={"wrap"}
          rowGap={1.5}
          columnGap={1.5}
          justifyContent={"flex-start"}
        >
          {loading ? (
            <CircularLoading />
          ) : usersData && usersData?.length > 0 ? (
            usersData.map((item, index) => (
              <Card
                elevation={3}
                sx={{
                  width: breakpoints(),
                  minWidth: "245px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  // bgcolor: cardColor(item.orderType),
                }}
                key={index}
              >
                <Box>
                  <Typography p={1} variant="h2" fontWeight={500}>
                    {item?.user?.name}
                  </Typography>
                </Box>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      justifyContent: "center",
                      rowGap: 0.5,
                    }}
                  >
                    <DataText
                      title={"ID:"}
                      text={item?.user?.id}
                      titleProps={{ width: titleWidth }}
                    />

                    <DataText
                      title={"E-mail:"}
                      text={`${item?.user?.email}`}
                      titleProps={{ width: titleWidth }}
                    />

                    <DataText
                      title={"Phone:"}
                      text={`${item?.user?.phone}`}
                      titleProps={{ width: titleWidth }}
                    />
                    <DataText
                      title={"SSN:"}
                      text={`${item?.user?.ssn}`}
                      titleProps={{ width: titleWidth }}
                    />
                    <DataText
                      title={"Role:"}
                      text={`${item?.user?.role}`}
                      titleProps={{ width: titleWidth }}
                    />
                  </Box>
                </CardContent>
                <CardActions
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <CustomButton
                    btnType="login"
                    onClick={() => {
                      setHandleUser(item);
                      setModalOpen(true);
                    }}
                    style={{
                      color: "white",
                      minWidth: 50,
                      maxWidth: 50,
                      minHeight: 40,
                    }}
                    text="Edit"
                  />

                  <Chip
                    label={item?.user?.access ? "Permitted" : "Blocked"}
                    style={{
                      background: item?.user?.access ? green[500] : red[900],
                      color: "white",
                      fontWeight: 500,
                    }}
                  />
                </CardActions>
              </Card>
            ))
          ) : (
            <NoData text="No data" />
          )}

          <Box display={"flex"} justifyContent={"center"} width={"100%"} mt={2}>
            <Pagination
              count={Math.ceil(records / getFilterStorage().itensPerPage)}
              page={page}
              defaultPage={1}
              onChange={(_e, page) => {
                changePage(page);
              }}
            />
          </Box>

          <CustomModal
            openModal={modalOpen}
            setOpenModal={closeModal}
            width={isSmalldesktop ? "90%" : "40%"}
            title={"Edit user"}
          >
            <UpdateUserAdmin
              data={handleUser}
              setAttData={() => getUsersAdmin()}
              closeModal={closeModal}
            />
          </CustomModal>
        </Box>
      </Box>
    </GlobalContainer>
  );
}
