import { useEffect, useState } from "react";
import {
  getBannerService,
  updateBannerLink,
} from "../../../services/configService";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { GenericInput } from "../../../components/AtomicDesign/Molecules";
import { CustomModal } from "../../../components/AtomicDesign/Organisms";
import EditIcon from "@mui/icons-material/Edit";

export function BannerLinks() {
  const [bannerNow, setBannerNow] = useState([] as Banner[]);
  const [openModal, setOpenModal] = useState(false);
  const [changePhotoLink, setChangePhotoLink] = useState({
    photoID: null,
    link: "",
    newLink: "",
  } as UpdateBannerLink);

  const getBannerConfig = async () => {
    try {
      const response = await getBannerService();
      setBannerNow(response);
    } catch {}
  };

  useEffect(() => {
    getBannerConfig();
  }, []);

  const updateLink = async () => {
    try {
      await updateBannerLink({
        link: changePhotoLink?.newLink,
        photoID: changePhotoLink?.photoID,
      });
    } catch {
    } finally {
      setChangePhotoLink({ link: "", photoID: null, newLink: "" });
      setOpenModal(false);
    }
  };

  return (
    <Box width={"100%"}>
      <Typography textAlign={"center"} variant="h1" mt={2} mb={2}>
        Banner's links
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        gap={1}
      >
        {bannerNow?.length > 0 &&
          bannerNow?.map((banner) => (
            <Box display={"flex"} flexWrap={"wrap"} alignItems={"center"}>
              <img
                src={banner?.photo}
                width={350}
                height={100}
                alt={"banner"}
              />

              <Typography ml={2}>
                {banner?.link
                  ? banner?.link
                  : "No link yet, click in pencil to create one"}
              </Typography>
              <IconButton
                onClick={() => {
                  setChangePhotoLink({
                    link: banner?.link,
                    photoID: banner?.id,
                    newLink: "",
                  });
                  setOpenModal(true);
                }}
                sx={{ width: 60, height: 60 }}
              >
                <EditIcon style={{ fill: "purple", fontSize: 35 }} />
              </IconButton>
            </Box>
          ))}
      </Box>
      <CustomModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        width={"50%"}
      >
        <Typography>
          {changePhotoLink?.link
            ? `Actual: ${changePhotoLink?.link}`
            : "No link yet"}
        </Typography>
        <GenericInput
          placeholder="Change photo link"
          inputName="changeLink"
          onChange={(value) =>
            setChangePhotoLink((old) => ({ ...old, newLink: value }))
          }
          value={changePhotoLink?.newLink}
          hasIcon={false}
        />
        <Button variant="contained" onClick={() => updateLink()}>
          Change
        </Button>
      </CustomModal>
    </Box>
  );
}
